<template>
    <!--VIP重置密码-->
    <div>
        <ZhaiHeadPC class="zhai-head"></ZhaiHeadPC>
        <div class="container-fluid shengse px-0 deng">
            <div class="dixian px-4 py-2 d-flex justify-content-between">
                <div><i class="bi bi-person-fill-add lf"></i>&nbsp;VIP专用重置密码</div>
            </div>
            <div class="px-5 zhai-s">
                <div class="mb-1">
                    <input
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': errors.username }"
                            id="username"
                            v-model="myform.username"
                            placeholder="请输入用户名"
                            @keyup.enter="resetPassword"
                    >
                </div>
                <div class="mb-4">
                    <p class="qing">*请输入注册时使用的用户名</p>
                </div>
                <div class="mb-1">
                    <input
                            type="email"
                            class="form-control"
                            :class="{ 'is-invalid': errors.email }"
                            id="exampleFormControlInput4"
                            v-model="myform.email"
                            placeholder="邮箱"
                            @keyup.enter="resetPassword"
                    >
                </div>
                <div class="mb-4">
                    <p class="qing">*请输入注册时使用的邮箱</p>
                </div>
                <div class="mb-3">
                    <input
                            type="password"
                            class="form-control"
                            :class="{ 'is-invalid': errors.password }"
                            id="password"
                            v-model="myform.password"
                            placeholder="请输入密码"
                            @keyup.enter="resetPassword"
                    >
                </div>
                <div class="mb-3">
                    <input
                            type="password"
                            class="form-control"
                            :class="{ 'is-invalid': errors.password2 }"
                            id="password2"
                            v-model="myform.password2"
                            placeholder="请再次确认密码"
                            @keyup.enter="resetPassword"
                    >
                </div>
                <div class="col-12 zhucce text-center">
                    <button type="button" @click="resetPassword" class="btn btn-success w-100 anniu">确认重置</button>
                </div>

                <div class="zhan"></div>
            </div>
        </div>
        <ZhaiFootPC></ZhaiFootPC>
        <div class="modal fade" id="errorModal" tabindex="-1" aria-labelledby="errorModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-shake" style="max-width: 400px;">
                <div class="modal-content shake">
                    <div class="modal-body d-flex align-items-center justify-content-center">
                        <i class="bi bi-emoji-frown" style="font-size: 2rem; color: #dc3545;"></i>
                        <p class="mb-0 ms-2" style="font-size: 1.2rem; color: #dc3545;">{{ errorMessage }}</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- 成功模态框 -->
        <div class="modal fade" id="successModal" tabindex="-1" aria-labelledby="successModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" style="max-width: 400px;">
                <div class="modal-content">
                    <div class="modal-body d-flex align-items-center justify-content-center">
                        <i class="bi bi-emoji-smile" style="font-size: 2rem; color: #00c26f;"></i>
                        <p class="mb-0 ms-2" style="font-size: 1.2rem; color: #00c26f;">重置密码成功</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import http from "../utils/http.js";
    import ZhaiHeadPC from "../componentspc/ZhaiHeadPC";
    import ZhaiFootPC from "../componentspc/ZhaiFootPC";
    import { Modal } from 'bootstrap';

    export default {
        name: "PResetPasswords",
        components: { ZhaiHeadPC, ZhaiFootPC },
        data() {
            return {
                myform: {
                    username: "",
                    password: "",
                    password2: "",
                    email: ""
                },
                errors: {},
                errorMessage: ""
            };
        },
        methods: {
            resetPassword() {
                this.errors = {};
                if (!this.myform.username.trim()) {
                    this.showErrorModal("用户名不能为空", 'username');
                    return;
                }

                if (!this.myform.email.trim()) {
                    this.showErrorModal("邮箱不能为空", 'email');
                    return;
                }
                if (!this.validateEmail(this.myform.email)) {
                    this.showErrorModal("邮箱格式有误", 'email');
                    return;
                }
                if (!this.myform.password.trim()) {
                    this.showErrorModal("密码不能为空", 'password');
                    return;
                }
                if (!/^[^\s]{6,15}$/.test(this.myform.password)) {
                    this.showErrorModal("密码应为6-15位字符且不能包含空格", 'password');
                    return;
                }
                if (this.myform.password !== this.myform.password2) {
                    this.showErrorModal("两次密码不一致", 'password2');
                    return;
                }

                let url = "/platform/frontEnd/changePassword";
                let fm = new FormData();
                fm.append("username", this.myform.username);
                fm.append("password", this.myform.password);
                fm.append("password2", this.myform.password2);
                fm.append("email", this.myform.email);

                http.post(url, fm, true).then(data => {
                    if (data.data.status === 1) {
                        this.showSuccessModal(); // 显示成功模态框
                    } else {
                        this.handleFormErrors(data.data);
                    }
                }).catch(error => {
                    console.error("重置请求失败:", error);
                    this.showErrorModal("重置请求失败，请稍后重试。");
                });
            },
            validateEmail(email) {
                const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return re.test(email);
            },
            handleFormErrors(data) {
                if (data.field && data.message) {
                    this.showErrorModal(data.message, data.field);
                } else {
                    this.showErrorModal(data.msg || "重置失败！");
                }
            },
            showErrorModal(message, field) {
                this.errorMessage = message;
                if (field) {
                    this.errors[field] = true;
                }
                const modalElement = document.getElementById('errorModal');
                const modal = new Modal(modalElement);
                modal.show();

                setTimeout(() => {
                    modalElement.querySelector('.modal-content').classList.add('shake');
                    setTimeout(() => {
                        modalElement.querySelector('.modal-content').classList.remove('shake');
                    }, 1000);
                }, 0);

                setTimeout(() => {
                    modal.hide();
                }, 3000);
            },
            showSuccessModal() {
                const modalElement = document.getElementById('successModal');
                const modal = new Modal(modalElement);
                modal.show();

                setTimeout(() => {
                    modal.hide();
                    this.$router.push('/login'); // 跳转到登录页面
                }, 3000);
            }
        }
    };
</script>

<style scoped>
    .zhai-s {
        padding-top: 20px;
    }
    .br5 {
        border-radius: 1px;
    }
    .zhan {
        height: 30px;
    }
    .btn-success {
        background-color: #00c26f !important;
        font-size: 0.9rem;
        border: none !important;
    }
    .deng {
        max-width: 35%;
        margin: 0 auto;
    }
    .form-control {
        font-size: 0.85rem;
        padding: 0.5rem 0.6rem; /* 增加输入框的行高 */
        border-radius: 4px; /* 调整四个圆角的大小 */
    }
    .zhucce {
        margin-top: 15px;
    }
    @media (min-width: 768px) {
        .zhai-head {
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 1000;
        }
        .container-fluid {
            margin-top: 100px !important;
        }
    }
    @keyframes shake {
        0%, 100% { transform: translateX(0); }
        10%, 30%, 50%, 70%, 90% { transform: translateX(-2px); }
        20%, 40%, 60%, 80% { transform: translateX(2px); }
    }
    .modal-content.shake {
        animation: shake 0.5s;
    }
    .qing {
        font-size: 0.85rem;
        color: red;
    }
    .is-invalid {
        border-color: #dc3545 !important;
        background-image: none !important; /* 移除叹号图标 */
    }
</style>
