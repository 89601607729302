import axios from 'axios';  // 引入 axios 库，用于发送 HTTP 请求

// // 创建一个 axios 实例
// const service = axios.create({
//     baseURL: 'http://127.0.0.1:8899',  // 设置基础 URL，所有请求都会在此 URL 之上拼接路径
//     timeout: 30000,  // 设置请求超时时间为 30 秒
// });


// 创建一个 axios 实例
const service = axios.create({
    baseURL: '/api',  // 修改为相对路径，通过 Nginx 代理隐藏后端 IP 和端口
    timeout: 30000,  // 设置请求超时时间为 30 秒
});
// 请求拦截器
service.interceptors.request.use(config => {
    const token = localStorage.getItem('token');  // 从 localStorage 中获取 token
    if (token) {  // 如果 token 存在
        config.headers.token = token;  // 在请求头中添加 token 字段，用于身份验证
    }
    return config;  // 返回修改后的请求配置
}, error => {
    // 请求错误时的处理
    return Promise.reject(error);  // 返回一个被拒绝的 Promise，传入错误信息
});

// 响应拦截器
service.interceptors.response.use(response => {
    return response;  // 请求成功，直接返回响应结果
}, error => {
    // 请求失败的处理
    if (error.response && error.response.status === 401) {  // 如果响应状态码为 401，表示未授权或登录已过期
        localStorage.removeItem('token');  // 从 localStorage 中移除 token
        localStorage.removeItem('user');   // 从 localStorage 中移除用户信息

        // 如果当前页面不是登录页，则跳转到登录页
        // 注释掉的代码用于在非登录页面时提示登录过期，并跳转到登录页
        // if (window.location.href !== '/login' && window.location.href !== '/') {
        //     alert('登录已过期，请重新登录');
        //     window.location.href = '/login';
        // }
    }
    return Promise.reject(error);  // 返回一个被拒绝的 Promise，传入错误信息
});

export default service;  // 导出自定义的 axios 实例
