<template>
    <!--登录-->
    <div>
        <!-- 导航栏 -->
        <ZhaiHeadPC class="zhai-head"></ZhaiHeadPC>

        <!-- 展示文字 -->
        <div class="facai">恭喜发财</div>

        <!-- 主体内容 -->
        <div class="container-fluid shengse px-0 deng">
            <div class="dixian px-4 py-2 d-flex justify-content-between">
                <div><i class="bi bi-person-fill lf"></i>&nbsp;用户登录</div>
            </div>
            <div class="px-5 zhai-s mt-2">
                <div class="mb-4">
                    <input
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': errors.username }"
                            id="username"
                            v-model="myform.username"
                            placeholder="请输入用户名"
                    >
                </div>
                <div class="mb-4">
                    <input
                            type="password"
                            class="form-control"
                            :class="{ 'is-invalid': errors.password }"
                            id="password"
                            v-model="myform.password"
                            placeholder="请输入密码"
                    >
                </div>
                <CaptchaComponent
                        @validate-captcha="validateCaptcha"
                        ref="captchaComponent"
                ></CaptchaComponent>
                <!-- 登录 -->
                <div class="col-12 denglu text-center">
                    <button type="button" @click="reg" class="btn btn-success w-100 anniu">登录</button>
                </div>
                <!-- 三个按钮 -->
                <div class="san text-center mt-3">
                    <span><router-link class="changlv no-underline" to="/register">注册</router-link></span>
                    <span class="lian"><router-link class="changlv lian no-underline" to="/retrieveUserName">找回用户名</router-link></span>
                    <span class="lian"><router-link class="changlv lian no-underline" to="/resetPasswords">重置密码（VIP）</router-link></span>
                </div>
                <div class="zhan"></div>
            </div>
        </div>
        <div class="zhan"></div>
        <!-- 公共底部 -->
        <ZhaiFootPC></ZhaiFootPC>

        <!-- 错误信息模态框 -->
        <div class="modal fade" id="errorModal" tabindex="-1" aria-labelledby="errorModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" style="max-width: 300px;">
                <div class="modal-content">
                    <div class="modal-body d-flex align-items-center justify-content-center">
                        <i class="bi bi-emoji-frown" style="font-size: 2rem; color: #dc3545;"></i>
                        <p class="mb-0 ms-2" style="font-size: 1rem; color: #dc3545;">{{ errorMessage }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import http from "../utils/http.js";
    import ZhaiHeadPC from "../componentspc/ZhaiHeadPC";
    import ZhaiFootPC from "../componentspc/ZhaiFootPC";
    import { Modal } from 'bootstrap';
    import CaptchaComponent from "../componentspc/CaptchaComponent.vue"; // 引入验证码组件

    export default {
        name: "PLogin",
        components: { ZhaiHeadPC, ZhaiFootPC, CaptchaComponent },
        data() {
            return {
                myform: {
                    username: "",
                    password: ""
                },
                errorMessage: "",
                errors: {
                    username: false,
                    password: false
                },
                captchaValid: false
            };
        },
        methods: {
            validateCaptcha(isValid) {
                this.captchaValid = isValid;
                this.$refs.captchaComponent.captchaError = !isValid;
            },
            reg() {
                this.errors.username = !this.myform.username.trim();
                this.errors.password = !this.myform.password.trim();

                if (this.errors.username) {
                    this.showErrorModal("用户名不可为空");
                    return;
                }
                if (this.errors.password) {
                    this.showErrorModal("密码不可为空");
                    return;
                }

                if (!this.$refs.captchaComponent.enteredCaptcha.trim()) {
                    this.showErrorModal("验证码不能为空");
                    this.$refs.captchaComponent.captchaError = true;
                    return;
                }
                this.validateCaptcha(this.$refs.captchaComponent.enteredCaptcha.trim() === this.$refs.captchaComponent.captchaText);

                if (!this.captchaValid) {
                    this.showErrorModal("图形验证码输入不正确！");
                    return;
                }

                let url = "/platform/frontEnd/login";
                let fm = new FormData();
                fm.append("username", this.myform.username);
                fm.append("password", this.myform.password);

                http.post(url, fm, { untoken: true }).then(response => {
                    if (response.data.status === 1 && response.data.data) {
                        const data = response.data.data;
                        const userData = {
                            user: {
                                id: data.id,
                                username: data.username,
                                email: data.email,
                                level: data.level,
                                level_expire_date: data.level_expire_date
                            },
                            token: data.token
                        };

                        if (!userData.user || !userData.token) {
                            this.showErrorModal("登录失败，用户或令牌无效");
                            return;
                        }

                        this.$store.dispatch('login', userData);
                        localStorage.setItem('authToken', userData.token);
                        localStorage.setItem('user', JSON.stringify(userData.user));
                        this.$router.push('/memberCenter');
                    } else {
                        this.showErrorModal(response.data.msg || "登录失败！");
                    }
                }).catch(error => {
                    this.showErrorModal("请求失败，请稍后重试。");
                });
            },
            showErrorModal(message) {
                this.errorMessage = message;
                const modalElement = document.getElementById('errorModal');
                const modal = new Modal(modalElement);
                modal.show();

                setTimeout(() => {
                    modalElement.querySelector('.modal-content').classList.add('shake');
                    setTimeout(() => {
                        modalElement.querySelector('.modal-content').classList.remove('shake');
                    }, 1000);
                }, 0);

                setTimeout(() => {
                    modal.hide();
                }, 3000);
            },
            handleEnterKey(event) {
                if (event.key === 'Enter') {
                    this.reg();
                }
            }
        },
        mounted() {
            window.addEventListener('keyup', this.handleEnterKey);
        },
        beforeUnmount() {
            window.removeEventListener('keyup', this.handleEnterKey);
        }
    }
</script>

<style scoped>
    .zhai-s {
        padding-top: 10px;
    }
    .facai {
        font-size: 1rem;
        color: lightslategrey;
        text-align: center;
    }
    .br5 {
        border-radius: 1px;
    }
    .gongxi2 {
        height: 20px;
    }
    .zhan {
        height: 30px;
    }
    .lian {
        margin-left: 5px;
    }
    .btn-success {
        background-color: #00c26f !important;
        font-size: 0.9rem;
        border: none !important;
    }
    .deng {
        max-width: 35%;
        margin: 0 auto;
    }
    .form-control {
        font-size: 0.85rem;
        padding: 0.5rem 0.6rem; /* 增加输入框的行高 */
        border-radius: 4px; /* 调整四个圆角的大小 */
    }
    .denglu {
        margin-top: 20px;
    }
    .is-invalid {
        border-color: #dc3545 !important;
        background-image: none !important; /* 移除叹号图标 */
    }
    @media (min-width: 768px) {
        .zhai-head {
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 1000;
        }
        .facai {
            margin-top: 85px;
        }
        .container-fluid {
            margin-top: 20px;
        }
    }
    /* 添加模态框抖动效果 */
    @keyframes shake {
        0%, 100% { transform: translateX(0); }
        10%, 30%, 50%, 70%, 90% { transform: translateX(-2px); }
        20%, 40%, 60%, 80% { transform: translateX(2px); }
    }
    .modal-content.shake {
        animation: shake 0.5s;
    }
    .modal-body {
        padding: 0px !important; /* 将内边距设置为0px，并使用 !important 覆盖原有样式 */
    }
</style>
