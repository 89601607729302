<template>
    <!--手机端修改资料-->
    <!--导航栏-->
    <ZhaiHead></ZhaiHead>
    <!--导航栏-->
    <!--九宫格-->
    <div class="container-fluid zhu mb-2">
        <ZhaiJiuGong menu="6"></ZhaiJiuGong>
    </div>
    <!--九宫格-->
    <!--主体内容-->
    <div class="container-fluid shengse">
        <div class="row shengse">
            <div class="dixian px-3 py-2 d-flex justify-content-between">
                <div>修改资料</div>
            </div>
            <div class="px-4 zhai-s">
                <div>
                    <label class="text-right col-form-label">用户名：</label>
                    <div class="">
                        <input type="text" class="form-control" id="name" v-model="form.username" disabled>
                    </div>
                </div>
                <div class="form-group">
                    <label class="text-right col-form-label">邮箱：</label>
                    <div class="">
                        <input type="email" class="form-control" id="mail" v-model="form.email" required placeholder="请输入邮箱">
                    </div>
                </div>
                <div class="form-group">
                    <label for="password" class="text-right col-form-label">旧密码：</label>
                    <div class="">
                        <input type="password" class="form-control" id="password" v-model="form.password" placeholder="请输入密码">
                    </div>
                </div>
                <div class="form-group">
                    <label for="newPassword" class="text-right col-form-label">新密码：</label>
                    <div class="">
                        <input type="password" class="form-control" id="newPassword" v-model="form.newPassword" placeholder="请输入新密码">
                    </div>
                </div>
                <text class="tishi">如不需要修改密码，则无需填写新密码</text>
                <div class="col-12 mt-4 text-center">
                    <button type="button" @click="showModal" class="btn btn-success w-30 z-f-sm">确认修改</button>
                </div>
                <div class="zhan"></div>
            </div>
        </div>
    </div>

    <!--公共底部-->
    <ZhaiFoot></ZhaiFoot>

    <!-- 确认修改模态框 -->
    <div class="modal fade" id="confirmModal" tabindex="-1" aria-labelledby="confirmModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body d-flex align-items-center" >
                    <i class="bi bi-exclamation-circle mb-3 " style="color: #FFA500; font-size: 1.5rem; margin-right:3px; margin-left: 5px"></i>
                    <p class="mb-0 mt-2 " style="line-height: 1.5;">确定修改？修改后无法撤销，修改后请保管好密码！！</p>
                </div>
                <div class="modal-footer mt-2">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
                    <button type="button" class="btn btn-primary" @click="confirmModification">确定</button>
                </div>
            </div>
        </div>
    </div>

    <!-- 成功模态框 -->
    <div class="modal fade" id="successModal" tabindex="-1" aria-labelledby="successModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style="max-width: 300px;">
            <div class="modal-content">
                <div class="modal-body d-flex align-items-center justify-content-center">
                    <i class="bi bi-emoji-smile" style="font-size: 2rem; color: #00c26f;"></i>
                    <p class="mb-0 ms-2" style="font-size: 1rem; color: #00c26f;">修改资料成功: {{ successMessage }}</p>
                </div>
            </div>
        </div>
    </div>

    <!-- 失败模态框 -->
    <div class="modal fade" id="errorModal" tabindex="-1" aria-labelledby="errorModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style="max-width: 300px;">
            <div class="modal-content">
                <div class="modal-body d-flex align-items-center justify-content-center">
                    <i class="bi bi-emoji-frown" style="font-size: 2rem; color: #dc3545;"></i>
                    <p class="mb-0 ms-2" style="font-size: 1rem; color: #dc3545;">{{ errorMessage }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // 导入所需的组件和库
    import ZhaiHead from "../components/ZhaiHead"; // 导入头部组件
    import ZhaiFoot from "../components/ZhaiFoot"; // 导入底部组件
    import ZhaiJiuGong from "../components/ZhaiJiuGong"; // 导入九宫格导航组件
    import { mapGetters } from 'vuex'; // 从Vuex中导入mapGetters，用于获取Vuex中的状态
    import { Modal } from 'bootstrap'; // 引入Bootstrap的Modal类，用于操作模态框
    import http from "../utils/http.js"; // 导入自定义的HTTP请求工具

    export default {
        name: "MModifiedData", // 组件名称
        components: { ZhaiHead, ZhaiFoot, ZhaiJiuGong }, // 注册导入的组件
        data() {
            // 定义组件的响应式数据
            return {
                form: {
                    username: '', // 用户名，初始化为空
                    email: '', // 邮箱，初始化为空
                    password: '', // 旧密码，初始化为空
                    newPassword: '' // 新密码，初始化为空
                },
                successMessage: '', // 用于存储成功消息的字符串
                errorMessage: '' // 用于存储错误消息的字符串
            };
        },
        computed: {
            ...mapGetters(['getUser']), // 使用Vuex的getters从状态管理中获取当前用户信息
        },
        mounted() {
            // 组件挂载时执行的操作
            if (this.getUser) {
                // 如果getUser存在，初始化表单数据为用户当前的信息
                this.form.username = this.getUser.username;
                this.form.email = this.getUser.email;
            }
        },
        methods: {
            // 显示确认修改的模态框
            showModal() {
                const modalElement = document.getElementById('confirmModal'); // 获取确认模态框的DOM元素
                const modal = new Modal(modalElement); // 创建一个Bootstrap Modal实例
                modal.show(); // 显示模态框
            },
            // 确认修改后的操作
            confirmModification() {
                const modalElement = document.getElementById('confirmModal'); // 获取确认模态框的DOM元素
                const modal = Modal.getInstance(modalElement); // 获取已经创建的模态框实例
                modal.hide(); // 隐藏模态框
                this.submitForm(); // 提交表单数据
            },
            // 显示成功提示的模态框
            showSuccessModal(message) {
                this.successMessage = message; // 设置成功消息的内容
                const modalElement = document.getElementById('successModal'); // 获取成功模态框的DOM元素
                const modal = new Modal(modalElement); // 创建一个Bootstrap Modal实例
                modal.show(); // 显示模态框

                // 3秒后自动隐藏模态框
                setTimeout(() => {
                    modal.hide();
                }, 3000); // 3秒后隐藏模态框
            },
            // 显示错误提示的模态框
            showErrorModal(message) {
                this.errorMessage = message; // 设置错误消息的内容
                const modalElement = document.getElementById('errorModal'); // 获取错误模态框的DOM元素
                const modal = new Modal(modalElement); // 创建一个Bootstrap Modal实例
                modal.show(); // 显示模态框

                // 添加抖动效果
                const modalDialog = modalElement.querySelector('.modal-dialog'); // 获取模态框的对话框部分
                modalDialog.classList.add('shake'); // 添加抖动动画类

                // 在0.5秒后移除抖动效果并在3秒后隐藏模态框
                setTimeout(() => {
                    modalDialog.classList.remove('shake'); // 移除抖动动画类
                    modal.hide(); // 隐藏模态框
                }, 3000); // 3秒后隐藏模态框
            },
            // 提交表单数据
            submitForm() {
                // 提交前重置输入框的样式
                document.getElementById('name').classList.remove('is-invalid'); // 移除用户名输入框的错误状态
                document.getElementById('mail').classList.remove('is-invalid'); // 移除邮箱输入框的错误状态
                document.getElementById('password').classList.remove('is-invalid'); // 移除密码输入框的错误状态

                // 前端验证非空字段
                if (!this.form.email) { // 如果邮箱字段为空
                    this.showErrorModal("邮箱不能为空"); // 显示错误模态框
                    document.getElementById('mail').classList.add('is-invalid'); // 标记邮箱输入框为错误状态
                    return; // 立即返回，停止后续验证
                }

                if (!this.form.password) { // 如果旧密码字段为空
                    this.showErrorModal("旧密码不能为空"); // 显示错误模态框
                    document.getElementById('password').classList.add('is-invalid'); // 标记密码输入框为错误状态
                    return; // 立即返回，停止后续验证
                }

                // 创建 FormData 对象并添加表单数据
                const formData = new FormData();
                formData.append('username', this.form.username); // 添加用户名
                formData.append('password', this.form.password); // 添加旧密码
                formData.append('email', this.form.email); // 添加邮箱
                formData.append('newPassword', this.form.newPassword); // 添加新密码
                formData.append('newEmail', this.form.email !== this.getUser.email ? this.form.email : ''); // 如果新邮箱与旧邮箱不同，则添加新邮箱

                // 发送POST请求提交表单数据
                http.post('/platform/frontEnd/updateUserInfo', formData)
                    .then(response => {
                        if (response.data && response.data.status === 1) { // 如果请求成功且返回状态为1
                            this.showSuccessModal(response.data.msg); // 显示成功模态框
                        } else { // 如果请求成功但返回状态不为1
                            this.showErrorModal(response.data.msg); // 显示错误模态框
                        }
                    })
                    .catch(error => {
                        this.showErrorModal("请求失败: " + error); // 如果请求失败，显示错误模态框
                    });
            }
        }
    };
</script>

<style scoped>
    .is-invalid {
        border-color: #dc3545 !important;
        background-image: none !important;
    }

    @keyframes shake {
        0%, 100% { transform: translateX(0); }
        25% { transform: translateX(-5px); }
        50% { transform: translateX(5px); }
        75% { transform: translateX(-5px); }
    }

    .shake {
        animation: shake 0.5s ease-in-out;
    }

    .modal.fade .modal-dialog {
        transition: transform 0.3s ease-out;
    }

    .modal-dialog {
        margin: auto !important; /* 确保模态框居中 */
        max-width: 300px; /* 设置最大宽度 */
    }

    .modal-content {
        text-align: center; /* 确保模态框内的内容居中 */
    }

    /*主体与顶端的内边距*/
    .zhai-s {
        padding-top: 10px;
    }

    /*提示文字红色*/
    .tishi {
        color: lightslategrey;
        font-size:0.8rem;
    }

    .zhan {
        height: 30px;
    }

    .zhu {
        margin-top: 80px;
    }

    .btn {
        font-size: 0.9rem;
        background-color: #00c26f !important;
        border: none !important;
    }

    .modal-body {
        padding: 0px !important; /* 将内边距设置为8px，并使用 !important 覆盖原有样式 */
    }
    .modal-footer {
        border-top: 0;
        padding: 0;
        justify-content: center !important; /* 使按钮在模态框底部居中 */
    }

</style>

