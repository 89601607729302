<template>
    <!--VIP升级-->
    <!--导航栏-->
    <ZhaiHead></ZhaiHead>
    <!--导航栏-->
    <!--九宫格-->
    <div class="container-fluid zhu mb-2">
        <ZhaiJiuGong menu="5"></ZhaiJiuGong>
    </div>
    <!--九宫格-->
    <!--主体-->
    <div class="container-fluid py-2">
        <!--vip明细表-->
        <div class="row shengse">
            <div class="dixian px-3 py-2 d-flex justify-content-between">
                <div>升级VIP</div>
            </div>
            <div class="p-3">
                <div class="jiacu mb-2">
                    会员类型和价格
                </div>
                <div class="huiziti pt-2 mb-2">
                    <table class="table table-bordered">
                        <thead>
                        <tr>
                            <th scope="col">类型</th>
                            <th scope="col">价格</th>
                            <th scope="col">查看内容</th>
                            <th scope="col">有效期</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in membershipOptions" :key="index">
                            <td>{{ item.type }}</td>
                            <td>{{ item.price }}元</td>
                            <td>{{ item.content }}</td>
                            <td>{{ item.duration }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <!--vip明细表-->

                <!--按钮-->
                <div class="G-Btns my-2">
                    <button class="btn btn-success membership-btn" @click="selectMembership(membershipOptions[0])">
                        <span class="btn-text">月度会员</span><span class="btn-price">90元</span>
                    </button>
                    <button class="btn btn-success membership-btn" @click="selectMembership(membershipOptions[1])">
                        <span class="btn-text">季度会员</span><span class="btn-price">130元</span>
                    </button>
                    <button class="btn btn-success membership-btn" @click="selectMembership(membershipOptions[2])">
                        <span class="btn-text">年度会员</span><span class="btn-price">300元</span>
                    </button>
                    <button class="btn btn-success membership-btn" @click="selectMembership(membershipOptions[3])">
                        <span class="btn-text">永久会员</span><span class="btn-price">500元</span>
                    </button>
                </div>
                <!--按钮-->
                <!--提醒-->
                <div class="text-primary mt-3 buyinfo">会员升级是通过第三方代收款，商品名称可能不一致，请核对支付金额后放心支付。</div>
                <div class="text-primary mt-3 buyinfo" style="background: #000000;color: #f9ff00!important;">
                    支付成功后如果VIP没有生效，请<strong>退出重新登录</strong>，正常情况下支付成功后，30秒内即可完成升级！
                    如果重新登录后依然是普通会员，请联系客服邮箱：<strong>404489561@qq.com</strong>，提供你的<strong>用户名和支付订单截图</strong>，我们将在24小时内为你处理。请不要急于投诉！给我们一点时候处理！不等我们处理就投诉的一律不处理！
                </div>
                <!--提醒-->
            </div>
            <!--升级帮助-->
            <div class="row shengse">
                <div class="dixian px-3 py-2 d-flex justify-content-between">
                    <div>升级帮助</div>
                </div>
                <div class="p-3 uphelp" style="font-size:1rem">
                    <h1 class="jiacu text-dark">
                        充值成功了，为什么还是普通会员?
                    </h1>
                    <p>
                        若付款后，仍是普通会员，请<strong>退出并重新登录</strong>即可刷新会员状态。
                    </p>
                    <br>
                    <h1 class="jiacu text-dark">
                        系统显示正在生成订单，无法显示支付？
                    </h1>
                    <p>
                        由于系统支付订单较多，可能出现无法生成支付订单的情况，请多试几遍不同的支付方式，或过一会再一下。通常这只是暂时性的。
                    </p>
                    <br>
                    <h1 class="jiacu text-dark">
                        遇到问题，怎样联系客服?
                    </h1>
                    <p>
                        提供你的用户名和支付订单截图，我们将在24小时内为你处理。请不要急于投诉！给我们一点时候处理！不等我们处理就投诉的一律不处理！客服邮箱：404489561@qq.com
                    </p>
                </div>
            </div>
            <!--升级帮助-->
        </div>
        <!--主体-->
    </div>
    <!--公共底部-->
    <ZhaiFoot></ZhaiFoot>
    <!--公共底部-->
    <!--升级支付 模态框 -->
    <div class="modal fade" id="upgradeModal" tabindex="-1" aria-labelledby="upgradeModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="upgradeModalLabel">升级支付</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body" style="line-height:30px;">
                    <div id="step1">
                        <div>
                            升级VIP目前支持支付宝、微信支付，请确认升级的VIP类型及支付金额是否正确，点击“支付”进行支付。
                        </div>
                        <div><strong>升级类型：</strong><span id="uplevel">{{ modalData.type }}</span></div>
                        <div><strong>支付金额：</strong><span id="payamount">{{ modalData.amount }}元</span></div>
                        <div><strong>支付方式：</strong>
                            <input type="radio" name="paytype" value="8006" v-model="payType">支付宝
                            <!--<input type="radio" name="paytype" class="pay_wechat" value="8001" v-model="payType"> <span @click="payType='8001'" style="cursor:pointer;">微信（备用）</span>-->
                        </div>
                        <div style="color:red;">
                            如果支付加载不出来，多试几次就成功了。
                        </div>
                        <br>
                        <div class="text-center">
                            <button id="btnpay" class="btn btn-success mr-5" @click="startPayment">支付</button>
                            <button class="btn btn-success mr-5" data-bs-dismiss="modal">关闭</button>
                        </div>
                    </div>
                    <div id="step2" style="display:none;">
                        <div id="paymsg" style="color: #00c26f;text-align: center;font-size: 1rem;font-weight: bold;"></div>
                        <p id="code_msg" style="text-align: center;background: #040404;color: #ffeb00;font-size: 1rem;">请使用手机相机扫描二维码，不支持微信/支付宝扫一扫</p>
                        <p style="text-align: center;font-size: 1rem;color: #000;">请确认支付完成后，再点击“支付完成”</p>
                        <div class="text-center">
                            <button id="btnpaysucess" class="btn btn-primary mr-5" @click="onManualCheckPayment" >支付完成</button>
                            <button class="btn btn-primary mr-5" data-bs-dismiss="modal">关闭</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- 成功模态框 -->
    <div class="modal fade" id="successModal" tabindex="-1" aria-labelledby="successModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style="max-width: 200px;">
            <div class="modal-content">
                <div class="modal-body d-flex align-items-center justify-content-center">
                    <i class="bi bi-emoji-smile" style="font-size: 1.5rem; color: #00c26f;"></i>
                    <p class="mb-0 ms-2" id="successMessage" style="font-size: 0.9rem; color: #00c26f;"></p>
                </div>
            </div>
        </div>
    </div>

    <!-- 失败模态框 -->
    <div class="modal fade" id="errorModal" tabindex="-1" aria-labelledby="errorModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-shake" style="max-width: 200px;">
            <div class="modal-content shake">
                <div class="modal-body d-flex align-items-center justify-content-center">
                    <i class="bi bi-emoji-frown" style="font-size: 1.5rem; color: #dc3545;"></i>
                    <p class="mb-0 ms-2" id="errorMessage" style="font-size: 0.9rem; color: #dc3545;"></p>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import ZhaiHead from "../components/ZhaiHead";
    import ZhaiFoot from "../components/ZhaiFoot";
    import ZhaiJiuGong from "../components/ZhaiJiuGong";
    import { Modal } from 'bootstrap';
    import QRCode from 'qrcodejs2'; // 引入二维码库
    import { mapGetters } from 'vuex';

    export default {
        name: "MUpgradeVIP",
        components: { ZhaiHead, ZhaiFoot, ZhaiJiuGong },
        data() {
            return {
                modalData: { type: '', amount: 0 },
                payType: '8006',
                membershipOptions: [
                    { type: 'VIP月度会员', price: 10, content: '所有', duration: '+1月' },
                    { type: 'VIP季度会员', price: 130, content: '所有', duration: '+1季度' },
                    { type: 'VIP年度会员', price: 300, content: '所有', duration: '+1年' },
                    { type: 'VIP永久会员', price: 500, content: '所有', duration: '永久' }
                ],
                orderId: null,         // 保存生成的订单号，用于手动轮询支付状态
                manualPolling: false    // 标识是否为手动轮询
            };
        },
        computed: {
            ...mapGetters(['getUser'])
        },
        methods: {
            selectMembership(item) {
                this.modalData.type = item.type;
                this.modalData.amount = item.price;
                this.openModal();
            },
            openModal() {
                document.getElementById('step1').style.display = 'block';
                document.getElementById('step2').style.display = 'none';
                const modalElement = document.getElementById('upgradeModal');
                const modalInstance = new Modal(modalElement);
                modalInstance.show();
            },

            // 发起支付请求并生成二维码
            startPayment() {
                const payButton = document.querySelector('#step1 .btn-success');
                payButton.textContent = '生成中';
                payButton.disabled = true;

                console.log('用户信息:', this.getUser);

                const { id: userid, username, email } = this.getUser || {};
                if (!userid || !username || !email) {
                    alert("用户信息不完整，请重新登录！");
                    return;
                }

                // 构建后端请求 URL
                const requestUrl = `/platform/frontEndPayment/request?name=${this.modalData.type}&money=${this.modalData.amount}&userid=${userid}&username=${username}&email=${email}`;

                fetch(requestUrl, {
                    method: 'GET'
                })
                    .then(response => response.json())
                    .then(data => {
                        if (data.status === 1) { // 假设后端返回 status 为 1 表示支付请求成功
                            this.orderId = data.data.mchOrderNo; // 保存订单号
                            this.generateQRCode(data.data.payUrl); // 生成二维码
                        } else {
                            const errorMessage = data.msg || '未知错误'; // 获取错误信息
                            alert(`支付请求失败: ${errorMessage}`);
                            payButton.textContent = '支付';
                            payButton.disabled = false;
                        }
                    })
                    .catch(error => {
                        console.error('支付请求异常:', error);
                        alert(`请求支付失败，请重试。错误详情: ${error.message}`);
                        payButton.textContent = '支付';
                        payButton.disabled = false;
                    });
            },

            // 生成二维码并展示在页面上
            generateQRCode(payUrl) {
                console.log("生成二维码的链接:", payUrl);
                document.getElementById('paymsg').innerHTML = '请用手机相机扫描下方二维码，从浏览器打开，将自动跳转支付。';

                // 创建二维码容器
                const qrCodeContainer = document.createElement('div');
                qrCodeContainer.id = 'qrcode';
                qrCodeContainer.style.width = '200px';
                qrCodeContainer.style.height = '200px';
                qrCodeContainer.style.marginTop = '20px';

                document.getElementById('paymsg').appendChild(qrCodeContainer);

                // 生成二维码
                new QRCode(qrCodeContainer, {
                    text: payUrl,
                    width: 200,
                    height: 200,
                    colorDark: "#000000",
                    colorLight: "#ffffff",
                    correctLevel: QRCode.CorrectLevel.H
                });

                document.getElementById('step1').style.display = 'none';
                document.getElementById('step2').style.display = 'block';

                // 设置倒计时时间
                let remainingTime = 300; // 5分钟倒计时（300秒）

                // 添加倒计时显示
                const countdown = document.createElement('p');
                countdown.id = 'countdown';
                countdown.style.textAlign = 'center';
                countdown.style.marginTop = '10px';
                document.getElementById('paymsg').appendChild(countdown);
                countdown.style.fontWeight = "normal";
                countdown.style.color = "rgba(255, 127, 127)";
                countdown.style.fontSize = "0.9rem";

                // 开始倒计时
                const timer = setInterval(() => {
                    if (remainingTime <= 0) {
                        clearInterval(timer);
                        countdown.innerHTML = "支付已超时，请关闭重新选择支付！";
                        qrCodeContainer.style.filter = "blur(5px)";
                    } else {
                        const minutes = Math.floor(remainingTime / 60);
                        const seconds = remainingTime % 60;
                        countdown.innerHTML = `请在 ${minutes}:${seconds < 10 ? '0' + seconds : seconds} 内完成支付`;
                        remainingTime--;
                    }
                }, 1000);
            },

            // 用户点击支付完成按钮进行手动轮询
            onManualCheckPayment() {
                this.manualPolling = true; // 设置手动轮询标识
                this.checkPaymentStatus();  // 立即检查支付状态
            },

            // 检查支付状态
            checkPaymentStatus() {
                if (!this.orderId) return;

                const statusUrl = `/platform/frontEndPayment/checkPaymentStatus?mchOrderNo=${this.orderId}`;
                fetch(statusUrl)
                    .then(response => response.json())
                    .then(data => {
                        if (data.status === "success") { // 后端返回 success 表示支付成功
                            this.handlePaymentSuccessStatus();
                        } else if (this.manualPolling) {
                            // 手动轮询时的失败提示
                            this.showFailureModal("未检测到支付完成，请稍后重试");
                        }
                    })
                    .catch(error => {
                        console.error("轮询支付状态异常:", error);
                    });
            },

            // 处理支付成功的逻辑
            handlePaymentSuccessStatus() {
                this.showSuccessModal("支付已成功"); // 显示成功提示
            },

            // 显示支付成功模态框，并在3秒后自动关闭和跳转
            showSuccessModal(message) {
                document.getElementById('successMessage').innerText = message;
                const successModal = new Modal(document.getElementById('successModal'));
                successModal.show();

                // 设置3秒后自动关闭模态框并跳转到会员中心页面
                setTimeout(() => {
                    successModal.hide(); // 关闭模态框
                    this.$router.push('/memberCenter'); // 跳转到会员中心页面
                }, 3000); // 3秒 = 3000毫秒
            },

            // 显示支付失败模态框，并在3秒后自动关闭
            showFailureModal(message) {
                document.getElementById('errorMessage').innerText = message;
                const errorModal = new Modal(document.getElementById('errorModal'));
                errorModal.show();

                // 设置3秒后自动关闭模态框
                setTimeout(() => {
                    errorModal.hide(); // 关闭模态框
                }, 3000); // 3秒 = 3000毫秒
            }
        },
        watch: {
            isLoggedIn(val) {
                if (!val) {
                    this.$router.push('/login'); // 如果登录状态变为未登录，跳转到登录页面
                }
            }
        },
        beforeRouteEnter(to, from, next) {
            const token = localStorage.getItem('token');
            if (!token) {
                next('/login'); // 如果没有 token，跳转到登录页面
            } else {
                next(); // 否则，继续导航
            }
        },
        mounted() {
            setTimeout(() => {
                const loadingModal = document.getElementById('loading');
                if (loadingModal) {
                    const modal = new Modal(loadingModal, { keyboard: false });
                    modal.hide(); // 隐藏模态框
                }
            }, 8000); // 等待 8 秒后执行，模拟一些延迟操作
        }
    };
</script>

<style scoped>
    /*VIP明细表格表头背景颜色*/
    .table th {
        background-color: #d5d5d5;
        text-align: center; /* 水平居中 */
    }

    /*VIP明细表格内背景颜色*/
    .table td {
        text-align: center; /* 水平居中 */
    }

    /*四个绿色按钮的大框*/
    .G-Btns {
        display: flex;
        justify-content: flex-start; /* 改为flex-start来向左对齐 */
    }

    /*四个绿色按钮的颜色还包括模态框按钮*/
    .btn-success {
        background-color: #00c26f !important;
        border: none !important;
    }

    /* 四个绿色按钮 为按钮添加样式，使文字分成两行并居中 */
    .membership-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100px; /* 调整宽度 */
        height: 60px; /* 调整高度 */
        text-align: center;
        font-size: 1rem;
        line-height: 1.2; /* 缩小行高 */
        padding: 0; /* 去除内边距 */
        margin-right: 10px; /* 按钮之间的间距 */
    }
    /*四个绿色按钮*/
    .btn-text {
        margin-bottom: 2px; /* 减少第一行和第二行的间距 */
    }
    /*四个绿色按钮*/
    .btn-price {
        color: #ffffff;
    }
    /*支付完成和关闭按钮*/
    #btnpaysucess, .modal-body .btn-primary {
        background-color: #00c26f !important;
        border: none !important;
    }


    /* 设置按钮的右边距，以添加间隙 */
    .btn {
        margin-right: 10px;
    }

    /* 移除最后一个按钮的右边距，以防止不必要的空间 */
    .btn:last-child {
        margin-right: 0;
    }

    /*付款提醒*/
    .buyinfo {
        font-size: 1rem;
        background: #f1f1f1;
        padding: 20px;
        color: red!important;
    }

    /*升级帮助里面的问题*/
    .uphelp h1, .uphelp p {
        font-size: 1rem;
    }

    /*四个按钮的字体*/
    .btn {
        font-size: 1rem;
    }

    .modal-title {
        font-size: 1rem;
    }

    /* 增加模态框中两个按钮的间距 */
    .modal-body .btn {
        margin-right: 60px; /* 调整为合适的数值 */
    }

    .modal-body .btn:last-child {
        margin-right: 0; /* 确保最后一个按钮没有多余的右边距 */
    }
    /* 覆盖 Bootstrap 模态框的样式 */
    .modal-dialog {
        margin-top: 25%; /* 你可以根据需要调整这个值 */
    }
    .zhu{
        margin-top: 80px;
    }
    #paymsg {
        color: #00c26f;
        display: flex;
        flex-direction: column;
        align-items: center; /* 垂直居中 */
    }

    #qrcode {
        margin-top: 20px; /* 给二维码顶部增加一些间距 */
        display: flex;
        justify-content: center; /* 水平居中 */
        align-items: center; /* 垂直居中 */
        transition: filter 0.5s ease; /* 增加二维码模糊效果的过渡动画 */
    }
    #countdown {
        font-size: 0.7rem;
        color: red !important;
        text-align: center;
        margin-bottom: 0 !important;

    }
    /* 失败模态框 */
    .modal-dialog.modal-shake {
        margin: auto; /* 设置左右居中 */
        max-width: 200px; /* 保持宽度 */
    }
</style>
