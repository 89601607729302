<template>
    <!--PC我的收藏-->
    <div>
        <ZhaiHeadPC class="zhai-head"></ZhaiHeadPC>
        <div class="container errong">
            <ZhaiJiuGongPC menu="4"></ZhaiJiuGongPC>
            <div class="container-fluid mt-2">
                <div class="row shengse">
                    <div class="dixian px-3 py-2 d-flex justify-content-between">
                        <div class="lf">我的收藏</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container errong2 mt-3">
            <div v-if="getUser && posts.length">
                <div class="row card-container">
                    <div class="col-md-4 mb-1" v-for="item in posts" :key="item.id">
                        <ZhaiCardPC :data="item" class="custom-card"></ZhaiCardPC>
                    </div>
                </div>

                <div class="shengse my-2 p-3 G-Field">
                    <div id="page" class="text-center d-flex justify-content-center align-items-center">
                        <a class="page-link pagination-btn" href="#" aria-label="Previous" @click.prevent="prevPage" :disabled="pageNum === 1">
                            <span aria-hidden="true">«</span>
                        </a>
                        <span style="margin: 0 1rem;">第 {{ pageNum }} 页，共 {{ totalPages }} 页</span>
                        <a class="page-link pagination-btn" href="#" aria-label="Next" @click.prevent="nextPage" :disabled="pageNum === totalPages">
                            <span aria-hidden="true">»</span>
                        </a>
                    </div>
                </div>
            </div>
            <div v-else class="text-center">
                {{ getUser ? '暂无收藏内容' : '用户未登录' }}
            </div>
        </div>

        <div class="gongxi2"></div>

        <ZhaiFootPC></ZhaiFootPC>
    </div>
</template>

<script>
    import ZhaiHeadPC from "../componentspc/ZhaiHeadPC";
    import ZhaiFootPC from "../componentspc/ZhaiFootPC";
    import ZhaiJiuGongPC from "../componentspc/ZhaiJiuGongPC";
    import ZhaiCardPC from "../componentspc/ZhaiCardPC";
    import http from "../utils/http.js";
    import { mapGetters } from 'vuex';

    export default {
        name: "PMyFavorite",
        components: { ZhaiHeadPC, ZhaiFootPC, ZhaiJiuGongPC, ZhaiCardPC },
        data() {
            return {
                posts: [],
                pageNum: 1,
                pageSize: 24,
                totalPages: 1,
            };
        },
        computed: {
            ...mapGetters(['getUser']),
        },
        mounted() {
            this.fetchPosts();
        },
        methods: {
            fetchPosts() {
                if (!this.getUser || !this.getUser.id) {
                    console.error('用户未登录');
                    return;
                }

                const userId = this.getUser.id;

                const url = `/platform/frontEnd/collect?userid=${userId}&pageNum=${this.pageNum}&pageSize=${this.pageSize}`;
                console.log(`Fetching posts with URL: ${url}`);
                http.get(url)
                    .then(response => {
                        console.log("API 响应数据:", response.data);
                        if (response.data && response.data.status === 1) {
                            this.posts = response.data.data.list;
                            this.totalPages = response.data.data.pages;
                            console.log("Total Pages:", this.totalPages);
                            console.log("Posts:", this.posts);
                        } else {
                            console.error('获取收藏信息失败:', response.data.msg);
                        }
                    })
                    .catch(error => {
                        console.error('API 请求错误:', error);
                    });
            },
            prevPage() {
                if (this.pageNum > 1) {
                    this.pageNum--;
                    this.fetchPosts();
                }
            },
            nextPage() {
                if (this.pageNum < this.totalPages) {
                    this.pageNum++;
                    this.fetchPosts();
                }
            },
            goToPage(page) {
                if (page !== this.pageNum) {
                    this.pageNum = page;
                    this.fetchPosts();
                }
            }
        }
    };
</script>

<style scoped>
    /** {*/
        /*margin: 0;*/
        /*padding: 0;*/
        /*box-sizing: border-box;*/
    /*}*/

    body {
        font-family: Arial, sans-serif;
    }

    .gongxi2 {
        height: 20px;
    }

    .errong,
    .errong2 {
        padding-left: 30px;
        padding-right: 30px;
    }

    .card-container {
        display: flex;
        flex-wrap: wrap;
        margin-left: -1px;
        margin-right: -1px;
    }

    .col-md-4 {
        padding-left: 2px;
        padding-right: 2px;
        display: flex;
    }

    .custom-card {
        display: flex;
        flex-direction: column;
        border: 1px solid #ededed;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease;
        flex: 1;
    }

    .custom-card img {
        border-bottom: 1px solid #ededed;
        width: 100%;
        height: auto;
    }

    .custom-card .card-content {
        padding: 1em;
        flex: 1;
    }

    .custom-card:hover {
        transform: translateY(-5px);
    }

    .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .pagination-btn {
        background: none;
        border: 1px solid #dee2e6;
        border-radius: 2px;
        cursor: pointer;
        padding: 0.3rem 0.75rem;
        font-size: 1.2rem;
        color: black;
        text-decoration: none;
        display: flex;
        align-items: center;
        transition: background-color 0.3s ease;
    }

    .pagination-btn:hover {
        background-color: #f8f9fa;
        border-color: #dee2e6; /* 确保边框颜色不变 */
    }

    .pagination-btn:disabled {
        color: #dee2e6;
        cursor: not-allowed;
        border-color: #dee2e6;
    }

    @media (min-width: 768px) {
        .zhai-head {
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 1000;
        }
        .errong {
            margin-top: 90px; /* 根据导航栏高度调整 */
        }
    }
</style>
