<template>
    <!-- PC端修改资料 -->
    <!-- 导航栏 -->
    <ZhaiHeadPC class="zhai-head"></ZhaiHeadPC> <!-- 导入并展示PC端的导航栏组件 -->

    <!-- 主体内容容器 -->
    <div class="container errong">
        <ZhaiJiuGongPC menu="6"></ZhaiJiuGongPC> <!-- 导入并展示PC端的九宫格导航组件，menu="6"表示选择的菜单项 -->
        <div class="container-fluid mt-2"> <!-- 主体内容的外部容器，使用Bootstrap的container-fluid类 -->
            <div class="row shengse pb-2"> <!-- 定义行，包含资料修改表单 -->
                <div class="dixian px-3 py-2 d-flex justify-content-between"> <!-- 顶部标题行 -->
                    <div class="lf">修改资料</div> <!-- 左侧标题 "修改资料" -->
                </div>
                <div class="px-5 zhai-s pb-4 mt-2"> <!-- 表单内容容器 -->
                    <!-- 用户名输入框，自动生成且只读 -->
                    <div class="form-group">
                        <label for="username">用户名:</label>
                        <input type="text" class="form-control custom-input" id="username" v-model="form.username" placeholder="需自动生成用户名" readonly>
                    </div>
                    <!-- 邮箱输入框，允许用户输入新邮箱或保持当前邮箱 -->
                    <div class="form-group mt-3">
                        <label for="email">邮箱:</label>
                        <input type="email" class="form-control custom-input" id="email" v-model="form.email" placeholder="请输入新邮箱或保持当前邮箱">
                    </div>
                    <!-- 旧密码输入框 -->
                    <div class="form-group mt-3">
                        <label for="password">旧密码:</label>
                        <input type="password" class="form-control custom-input" id="password" v-model="form.password" placeholder="请输入旧密码" autocomplete="new-password">
                    </div>
                    <!-- 新密码输入框，用户可选择是否填写 -->
                    <div class="form-group mt-3">
                        <label for="new-password">新密码:</label>
                        <input type="password" class="form-control custom-input" id="new-password" v-model="form.newPassword" placeholder="请输入新密码">
                    </div>
                    <!-- 提示信息，如果不修改密码，可以不填写新密码 -->
                    <div class="form-group">
                        <text class="tishi ri">如不需要修改密码，则无需填写新密码</text>
                    </div>
                    <!-- 确认修改按钮，点击后触发模态框 -->
                    <div class="col-12 mt-4 text-center">
                        <button type="button" class="btn btn-success custom-button" @click="showModal">确认修改</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- 只是占据空间 -->
    <div class="gongxi2"></div> <!-- 占位元素，用于调整布局 -->

    <!-- 公共底部 -->
    <ZhaiFootPC></ZhaiFootPC> <!-- 导入并展示PC端的底部组件 -->

    <!-- 确认修改模态框 -->
    <div class="modal fade" id="confirmModal" tabindex="-1" aria-labelledby="confirmModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body d-flex align-items-center" >
                    <i class="bi bi-exclamation-circle" style="color: #FFA500; font-size: 1.5rem;margin-top: 8px; margin-right:3px; margin-left: 5px"></i>
                    <p class="mb-0 mt-2 " style="line-height: 1.5;">确定修改？修改后无法撤销，修改后请保管好密码！！</p>
                </div>
                <div class="modal-footer mt-2">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">取消</button>
                    <button type="button" class="btn btn-primary" @click="confirmModification">确定</button>
                </div>
            </div>
        </div>
    </div>

    <!-- 成功模态框 -->
    <div class="modal fade" id="successModal" tabindex="-1" aria-labelledby="successModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style="max-width: 300px;"> <!-- 设置最大宽度为400px，居中显示 -->
            <div class="modal-content"> <!-- 模态框的内容部分 -->
                <div class="modal-body d-flex align-items-center justify-content-center"> <!-- 成功信息的主体部分，居中对齐 -->
                    <i class="bi bi-emoji-smile" style="font-size: 2rem; color: #00c26f;"></i> <!-- 成功图标 -->
                    <p class="mb-0 ms-2" style="font-size: 1rem; color: #00c26f;">修改资料成功: {{ successMessage }}</p> <!-- 成功提示文本，显示自定义消息 -->
                </div>
            </div>
        </div>
    </div>

    <!-- 失败模态框 -->
    <div class="modal fade" id="errorModal" tabindex="-1" aria-labelledby="errorModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style="max-width: 300px;"> <!-- 设置最大宽度为400px，居中显示 -->
            <div class="modal-content"> <!-- 模态框的内容部分 -->
                <div class="modal-body d-flex align-items-center justify-content-center"> <!-- 失败信息的主体部分，居中对齐 -->
                    <i class="bi bi-emoji-frown" style="font-size: 2rem; color: #dc3545;"></i> <!-- 失败图标 -->
                    <p class="mb-0 ms-2" style="font-size: 1rem; color: #dc3545;">{{ errorMessage }}</p> <!-- 失败提示文本，显示自定义消息 -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // 导入需要的组件和工具
    import ZhaiHeadPC from "../componentspc/ZhaiHeadPC"; // 导入PC端导航栏组件
    import ZhaiFootPC from "../componentspc/ZhaiFootPC"; // 导入PC端底部组件
    import ZhaiJiuGongPC from "../componentspc/ZhaiJiuGongPC"; // 导入PC端九宫格导航组件
    import { mapGetters } from 'vuex'; // 从Vuex中导入mapGetters用于获取用户信息
    import http from "../utils/http.js"; // 导入自定义的HTTP请求工具
    import { Modal } from 'bootstrap'; // 引入Bootstrap的Modal功能

    export default {
        name: "PMyRelease", // 组件名称
        components: { ZhaiHeadPC, ZhaiFootPC, ZhaiJiuGongPC }, // 注册导入的组件
        data() {
            return {
                form: {
                    username: '', // 用户名，初始化为空
                    email: '', // 邮箱，初始化为空
                    password: '', // 旧密码，初始化为空
                    newPassword: '' // 新密码，初始化为空
                },
                successMessage: '', // 成功消息的内容
                errorMessage: '' // 失败消息的内容
            };
        },
        computed: {
            ...mapGetters(['getUser']), // 使用Vuex的getters获取当前用户信息
        },
        mounted() {
            if (this.getUser) {
                this.form.username = this.getUser.username; // 从Vuex中获取当前用户名并赋值给表单
                this.form.email = this.getUser.email; // 从Vuex中获取当前邮箱并赋值给表单
            }
        },
        methods: {
            // 显示确认修改的模态框
            showModal() {
                const modalElement = document.getElementById('confirmModal'); // 获取模态框的DOM元素
                const modal = new Modal(modalElement); // 使用Bootstrap的Modal初始化模态框
                modal.show(); // 显示模态框
            },
            // 确认修改后的操作
            confirmModification() {
                const modalElement = document.getElementById('confirmModal'); // 获取确认模态框的DOM元素
                const modal = Modal.getInstance(modalElement); // 获取当前的模态框实例
                modal.hide(); // 隐藏模态框
                this.submitForm(); // 提交表单
            },
            // 显示成功提示模态框
            showSuccessModal(message) {
                console.log("showSuccessModal called with message:", message); // 调试信息
                this.successMessage = message; // 设置成功消息
                const modalElement = document.getElementById('successModal'); // 获取成功模态框的DOM元素
                const modal = new Modal(modalElement); // 使用Bootstrap的Modal初始化模态框
                modal.show(); // 显示模态框

                // 3秒后自动隐藏模态框
                setTimeout(() => {
                    modal.hide();
                }, 3000); // 3秒后隐藏模态框
            },
            // 显示错误提示模态框
            showErrorModal(message) {
                console.log("showErrorModal called with message:", message); // 调试信息
                this.errorMessage = message; // 设置失败消息
                const modalElement = document.getElementById('errorModal'); // 获取失败模态框的DOM元素
                const modal = new Modal(modalElement); // 使用Bootstrap的Modal初始化模态框
                modal.show(); // 显示模态框

                // 添加抖动效果
                const modalDialog = modalElement.querySelector('.modal-dialog'); // 获取模态框的对话框部分
                modalDialog.classList.add('shake'); // 添加抖动动画类

                // 在0.5秒后移除抖动效果并在3秒后隐藏模态框
                setTimeout(() => {
                    modalDialog.classList.remove('shake'); // 移除抖动动画类
                    modal.hide();
                }, 3000); // 3秒后隐藏模态框
            },
            // 提交表单
            submitForm() {
                // 重置输入框样式
                document.getElementById('username').classList.remove('is-invalid');
                document.getElementById('email').classList.remove('is-invalid');
                document.getElementById('password').classList.remove('is-invalid');

                // 前端验证非空字段
                let hasError = false;

                if (!this.form.email) { // 邮箱为空时，显示错误
                    this.showErrorModal("邮箱不能为空");
                    document.getElementById('email').classList.add('is-invalid');
                    hasError = true;
                }
                if (!this.form.password) { // 旧密码为空时，显示错误
                    this.showErrorModal("旧密码不能为空");
                    document.getElementById('password').classList.add('is-invalid');
                    hasError = true;
                }

                // 如果有错误，停止表单提交
                if (hasError) {
                    return;
                }

                // 创建 FormData 并发送请求
                const formData = new FormData();
                formData.append('username', this.form.username);
                formData.append('password', this.form.password);
                formData.append('email', this.form.email);
                formData.append('newPassword', this.form.newPassword);
                formData.append('newEmail', this.form.email !== this.getUser.email ? this.form.email : '');

                console.log("Submitting form data:", {
                    username: this.form.username,
                    password: this.form.password,
                    email: this.form.email,
                    newPassword: this.form.newPassword,
                    newEmail: this.form.email !== this.getUser.email ? this.form.email : ''
                });

                http.post('/platform/frontEnd/updateUserInfo', formData) // 发送表单数据到后端
                    .then(response => {
                        if (response.data && response.data.status === 1) { // 如果请求成功，显示成功模态框
                            console.log("修改资料成功:", response.data.msg);
                            this.showSuccessModal(response.data.msg);
                        } else { // 如果请求失败，显示失败模态框
                            console.error("修改资料失败:", response.data.msg);
                            this.showErrorModal(response.data.msg); // 使用失败模态框
                        }
                    })
                    .catch(error => { // 如果请求发生错误，显示失败模态框
                        console.error("请求失败:", error);
                        this.showErrorModal("请求失败: " + error); // 使用失败模态框
                    });
            }
        }
    };
</script>

<style scoped>
    /* 使用CSS Reset来确保不同浏览器的一致性 */
    /** {*/
        /*margin: 0;*/
        /*padding: 0;*/
        /*box-sizing: border-box;*/
    /*}*/

    body {
        font-family: Arial, sans-serif;
    }

    .gongxi2 {
        height: 20px;
    }

    /* 最大容器的左右内边距 */
    .errong {
        padding-left: 30px;
        padding-right: 30px;
    }

    /* 自定义输入框和按钮样式 */
    .custom-input {
        width: 300px;
        margin: 0 auto;
    }

    .custom-button {
        width: 90px;
        margin: 0 auto;
    }

    .form-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 0 auto;
        width: 300px;
        font-size: 0.9rem;
    }

    .custom-input {
        font-size: 0.9rem;
    }

    .btn {
        font-size: 0.9rem;
        background-color: #00c26f !important;
        border: none !important;
    }

    .tishi {
        display: block;
        margin-top: 5px;
        text-align: left;
        font-size: 0.6rem;
    }

    @keyframes shake {
        0%, 100% { transform: translateX(0); }
        25% { transform: translateX(-5px); }
        50% { transform: translateX(5px); }
        75% { transform: translateX(-5px); }
    }

    .shake {
        animation: shake 0.5s ease-in-out;
    }

    .is-invalid {
        border-color: #dc3545 !important;
        background-image: none !important;
    }

    @media (min-width: 768px) {
        .zhai-head {
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 1000;
        }

        .errong {
            margin-top: 90px; /* 根据导航栏高度调整 */
        }
    }

    .modal-body {
        padding: 0px !important; /* 将内边距设置为0px，并使用 !important 覆盖原有样式 */
    }
    .modal-footer {
        border-top: 0;
        padding: 0;
        justify-content: center !important; /* 使按钮在模态框底部居中 */
    }
</style>
