<template>
    <!-- PC账号查询 -->
    <!-- 导航栏 -->
    <ZhaiHeadPC class="zhai-head"></ZhaiHeadPC>

    <!-- 主体内容容器 -->
    <div class="container errong">
        <!-- 三个按钮组件 -->
        <div class="col-12">
            <ZhaiThreeTuttonsPC></ZhaiThreeTuttonsPC>
        </div>
        <div class="container-fluid shang mt-2">
            <div class="row shengse pb-2">
                <div class="dixian px-3 py-2 d-flex justify-content-between">
                    <div class="lf">联系方式查询</div>
                </div>
                <div class="col-12 mt-2">
                    <div class="alert alert-success custom-alert">
                        通过联系方式查询，可以查到与该联系方式匹配的所有帖子，以及用户反馈。可以有效避坑、防骗。
                    </div>
                </div>
                <div class="col-12 mt-2 mb-3">
                    <form @submit.prevent="handleSubmit" style="text-align:center;">
                        <div style="display:inline-block;">
                            <label style="width:90px;">联系方式</label>
                            <input v-model="contactInfo" autocomplete="off" placeholder="QQ,微信,电话,Telegram" style="padding: 4px 8px; width: 200px; margin-right: 5px;">
                            <button type="submit" class="custom-btn">查询</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="container errong2 mt-3">
        <div v-if="posts.length">
            <div class="row card-container">
                <div class="col-md-4 mb-1" v-for="item in posts" :key="item.id">
                    <ZhaiCardPC :data="item" class="custom-card"></ZhaiCardPC>
                </div>
            </div>
            <div class="my-2 p-3 G-Field">
                <div id="page" class="text-center d-flex justify-content-center align-items-center">
                    <a class="page-link pagination-btn" href="#" aria-label="Previous" @click.prevent="prevPage" :disabled="pageNum === 1">
                        <span aria-hidden="true">«</span>
                    </a>
                    <span style="margin: 0 1rem;">第 {{ pageNum }} 页，共 {{ totalPages }} 页</span>
                    <a class="page-link pagination-btn" href="#" aria-label="Next" @click.prevent="nextPage" :disabled="pageNum === totalPages">
                        <span aria-hidden="true">»</span>
                    </a>
                </div>
            </div>
        </div>
        <div v-if="showNoData" class="container-fluid mt-2">
            <div class="row shengse pt-2 pb-3">
                <div class="col-12 mt-2 text-center" style="font-size: 0.9rem;">
                    未查询到相关数据
                </div>
            </div>
        </div>
        <!-- 只是占据空间 -->
        <div class="gongxi2 shengse mt-3"></div>
    </div>

    <!-- 公共底部 -->
    <ZhaiFootPC></ZhaiFootPC>

    <!-- 必填项不能为空模态框 -->
    <div class="modal fade" id="emptyFieldModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style="width: 200px;">
            <div class="modal-content">
                <div class="modal-body text-center d-flex align-items-center justify-content-center">
                    <i class="bi bi-emoji-frown" style="font-size: 2rem; color: #f16b6b;"></i>
                    <p class="mb-0 ms-2" style="font-size: 1rem; color: #f16b6b;">必填项不能为空</p>
                </div>
            </div>
        </div>
    </div>

    <!-- 格式错误模态框 -->
    <div class="modal fade" id="formatErrorModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style="width: 360px;">
            <div class="modal-content">
                <div class="modal-body text-center d-flex align-items-center justify-content-center">
                    <i class="bi bi-emoji-frown" style="font-size: 2rem; color: #f16b6b;"></i>
                    <p class="mb-0 ms-2" style="font-size: 1rem; color: #f16b6b;">请输入正确的QQ、微信、电话、Telegram</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ZhaiHeadPC from "../componentspc/ZhaiHeadPC";
    import ZhaiFootPC from "../componentspc/ZhaiFootPC";
    import ZhaiThreeTuttonsPC from "../componentspc/ZhaiThreeTuttonsPC";
    import ZhaiCardPC from "../componentspc/ZhaiCardPC";
    import http from "../utils/http.js";
    import { Modal } from 'bootstrap';

    export default {
        name: "PQueryAccount",
        components: { ZhaiHeadPC, ZhaiFootPC, ZhaiThreeTuttonsPC, ZhaiCardPC },
        data() {
            return {
                contactInfo: '',
                posts: [],
                pageNum: 1,
                pageSize: 24,
                totalPages: 1,
                showNoData: false,
            };
        },
        methods: {
            handleSubmit() {
                if (!this.contactInfo) {
                    this.showModal('emptyFieldModal');
                } else if (!this.isValidContactInfo(this.contactInfo)) {
                    this.showModal('formatErrorModal');
                } else {
                    // 调用查询接口
                    this.fetchPosts();
                }
            },
            showModal(modalId) {
                const modalElement = document.getElementById(modalId);
                const modal = new Modal(modalElement);
                modal.show();

                // 添加晃动动画
                modalElement.classList.add('shake');

                // 在动画结束后自动移除class并关闭模态框
                setTimeout(() => {
                    modalElement.classList.remove('shake');
                    modal.hide();
                }, 1500); // 1.5秒后自动关闭模态框
            },
            isValidContactInfo(contactInfo) {
                // 验证输入格式
                const regex = /^[a-zA-Z0-9_\-@.]+$/;
                return regex.test(contactInfo);
            },
            fetchPosts() {
                const url = `/platform/frontEnd/searchByContact?contactInformation=${this.contactInfo}&pageNum=${this.pageNum}&pageSize=${this.pageSize}&sortBy=collection`;
                http.get(url)
                    .then(response => {
                        if (response.data && response.data.status === 1) {
                            this.posts = response.data.data.list;
                            this.totalPages = response.data.data.pages;
                            this.showNoData = false;
                        } else if (response.data.status === -29) {
                            this.posts = [];
                            this.totalPages = 1;
                            this.showNoData = true;
                        } else {
                            console.error('获取信息失败:', response.data.msg);
                        }
                    })
                    .catch(error => {
                        console.error('API 请求错误:', error);
                    });
            },
            prevPage() {
                if (this.pageNum > 1) {
                    this.pageNum--;
                    this.fetchPosts();
                }
            },
            nextPage() {
                if (this.pageNum < this.totalPages) {
                    this.pageNum++;
                    this.fetchPosts();
                }
            }
        }
    };
</script>

<style scoped>
    /* 使用CSS Reset来确保不同浏览器的一致性 */
    /** {*/
        /*margin: 0;*/
        /*padding: 0;*/
        /*box-sizing: border-box;*/
    /*}*/

    body {
        font-family: Arial, sans-serif;
    }

    .gongxi2 {
        height: 20px;
    }

    .errong,
    .errong2 {
        padding-left: 30px;
        padding-right: 30px;
    }

    .card-container {
        display: flex;
        flex-wrap: wrap;
        margin-left: -1px;
        margin-right: -1px;
    }

    .col-md-4 {
        padding-left: 2px;
        padding-right: 2px;
        display: flex;
    }

    .custom-card {
        display: flex;
        flex-direction: column;
        border: 1px solid #ededed;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        transition: transform 0.3s ease;
        flex: 1;
    }

    .custom-card img {
        border-bottom: 1px solid #ededed;
        width: 100%;
        height: auto;
    }

    .custom-card .card-content {
        padding: 1em;
        flex: 1;
    }

    .custom-card:hover {
        transform: translateY(-5px);
    }

    .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .pagination-btn {
        background: none;
        border: 1px solid #dee2e6;
        border-radius: 2px;
        cursor: pointer;
        padding: 0.3rem 0.75rem;
        font-size: 1.2rem;
        color: black;
        text-decoration: none;
        display: flex;
        align-items: center;
        transition: background-color 0.3s ease;
    }

    .pagination-btn:hover {
        background-color: #f8f9fa;
        border-color: #dee2e6; /* 确保边框颜色不变 */
    }

    .pagination-btn:disabled {
        color: #dee2e6;
        cursor: not-allowed;
        border-color: #dee2e6;
    }

    /* 自定义按钮样式 */
    .custom-btn {
        display: inline-block;
        padding: 8px 12px; /* 调整内边距 */
        font-size: 0.9rem; /* 调整按钮字体大小 */
        font-weight: 400;
        line-height: 1.2; /* 调整行高 */
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        cursor: pointer;
        user-select: none;
        background-color: #00c26f;
        color: white;
        border: 1px solid #007bff;
        border-radius: 2px;
        border: none !important;
    }

    .custom-btn:hover {
        background-color: #008c4a; /* 修改为深绿色 */
        border-color: #008c4a; /* 修改为深绿色 */
    }

    @media (min-width: 768px) {
        .zhai-head {
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 1000;
        }
        .errong {
            margin-top: 70px; /* 根据导航栏高度调整 */
        }
    }
    .modal-body {
        padding: 0px !important; /* 将内边距设置为0px，并使用 !important 覆盖原有样式 */
    }
    @keyframes shake {
        0%, 100% {
            transform: translateX(0);
        }
        20%, 60% {
            transform: translateX(-10px);
        }
        40%, 80% {
            transform: translateX(10px);
        }
    }

    .shake {
        animation: shake 0.5s;
    }

</style>
