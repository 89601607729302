// 引入 Vue Router
import { createRouter, createWebHistory } from 'vue-router';

// 导入各个页面组件，区分移动端和PC端
import MLogin from './mobile/MLogin.vue';
import PLogin from './pc/PLogin.vue';
import MRegister from './mobile/MRegister.vue';
import PRegister from './pc/PRegister.vue';
import MHome from './mobile/MHome.vue';
import PHome from './pc/PHome.vue';
import MDetails from './mobile/MDetails.vue';
import PDetails from './pc/PDetails.vue';
import MMemberCenter from './mobile/MMemberCenter.vue';
import PMemberCenter from './pc/PMemberCenter.vue';
import MUpgradeVIP from './mobile/MUpgradeVIP.vue';
import PUpgradeVIP from './pc/PUpgradeVIP.vue';
import MModifiedData from './mobile/MModifiedData.vue';
import PModifiedData from './pc/PModifiedData.vue';
import MInfoPublish from './mobile/MInfoPublish.vue';
import PInfoPublish from './pc/PInfoPublish.vue';
import MMyRelease from './mobile/MMyRelease.vue';
import PMyRelease from './pc/PMyRelease.vue';
import MMyFavorite from './mobile/MMyFavorite.vue';
import PMyFavorite from './pc/PMyFavorite.vue';
import MQueryAccount from './mobile/MQueryAccount.vue';
import PQueryAccount from './pc/PQueryAccount.vue';
import MWayHome from './mobile/MWayHome.vue';
import PWayHome from './pc/PWayHome.vue';
import MAvoidFraud from './mobile/MAvoidFraud.vue';
import PAvoidFraud from './pc/PAvoidFraud.vue';
import MRetrieveUserName from './mobile/MRetrieveUserName.vue';
import PRetrieveUserName from './pc/PRetrieveUserName.vue';
import MResetPasswords from './mobile/MResetPasswords.vue';
import PResetPasswords from './pc/PResetPasswords.vue';
import MSearch from './mobile/MSeach.vue';
import PSearch from './pc/PSeach.vue';
import http from './utils/http.js'; // 确保引入你的HTTP请求工具
// 定义路由
const routes = [
    {
        path: '/',  // 首页
        component: window.innerWidth < 768 ? MHome : PHome,
        meta: { title: '' }
    },
    {
        path: '/login',  // 登录页面
        component: window.innerWidth < 768 ? MLogin : PLogin,
        meta: { title: '登录' }
    },
    {
        path: '/register',  // 注册页面
        component: window.innerWidth < 768 ? MRegister : PRegister,
        meta: { title: '注册' }
    },
    {
        path: '/details',  // 详情页
        component: window.innerWidth < 768 ? MDetails : PDetails,
        name: 'detailPage',
        props: (route) => ({ id: route.query.id }),
        meta: { title: '详情' }
    },
    {
        path: '/memberCenter',  // 会员中心
        component: window.innerWidth < 768 ? MMemberCenter : PMemberCenter,
        meta: { title: '会员中心' }
    },
    {
        path: '/upgradeVIP',  // 升级VIP
        component: window.innerWidth < 768 ? MUpgradeVIP : PUpgradeVIP,
        meta: { title: '升级VIP' }
    },
    {
        path: '/modifiedData',  // 修改个人数据
        component: window.innerWidth < 768 ? MModifiedData : PModifiedData,
        meta: { title: '修改数据' }
    },
    {
        path: '/infoPublish',  // 信息发布
        component: window.innerWidth < 768 ? MInfoPublish : PInfoPublish,
        meta: { title: '信息发布' }
    },
    {
        path: '/myRelease',  // 我的发布
        component: window.innerWidth < 768 ? MMyRelease : PMyRelease,
        meta: { title: '我的发布' }
    },
    {
        path: '/myFavorite',  // 我的收藏
        component: window.innerWidth < 768 ? MMyFavorite : PMyFavorite,
        meta: { title: '我的收藏' }
    },
    {
        path: '/queryAccount',  // 查询账户
        component: window.innerWidth < 768 ? MQueryAccount : PQueryAccount,
        meta: { title: '查询账户' }
    },
    {
        path: '/wayHome',  // 回家路线
        component: window.innerWidth < 768 ? MWayHome : PWayHome,
        meta: { title: '回家路线' }
    },
    {
        path: '/avoidFraud',  // 防诈骗页面
        component: window.innerWidth < 768 ? MAvoidFraud : PAvoidFraud,
        meta: { title: '防诈骗' }
    },
    {
        path: '/retrieveUserName',  // 找回用户名
        component: window.innerWidth < 768 ? MRetrieveUserName : PRetrieveUserName,
        meta: { title: '找回用户名' }
    },
    {
        path: '/resetPasswords',  // 重置密码
        component: window.innerWidth < 768 ? MResetPasswords : PResetPasswords,
        meta: { title: '重置密码' }
    },
    {
        path: '/search',  // 搜索页面
        component: window.innerWidth < 768 ? MSearch : PSearch,
        props: route => ({ keyWord: route.query.keyWord }),
        meta: { title: '搜索' }
    }
];

// 创建并导出 Vue Router 实例
const router = createRouter({
    history: createWebHistory(),  // 使用 HTML5 History 模式
    routes  // 路由表
});

// 动态设置标题和图标
router.beforeEach(async (to, from, next) => {
    if (to.path === '/') {
        document.title = '港湾';
    } else if (to.path === '/details' && to.query.id) {
        try {
            // 预获取详情数据
            const response = await http.get(`/platform/frontEnd/details?id=${to.query.id}`);
            if (response.data.status === 1) {
                const detailsName = response.data.data.name || '详情';
                document.title = `${detailsName} - 港湾`;
            } else {
                document.title = '详情 - 港湾';
            }
        } catch (error) {
            console.error('获取详情数据时出错:', error);
            document.title = '详情 - 港湾';
        }
    } else {
        document.title = to.meta.title ? `${to.meta.title} - 港湾` : '港湾';
    }

    // 设置 favicon
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = '../assets/logo.png';
    document.getElementsByTagName('head')[0].appendChild(link);

    next();
});
export default router;