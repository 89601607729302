<template>
    <div class="captcha-container">
        <canvas ref="captchaCanvas" class="br5" width="118" height="36" @click="generateCaptcha"></canvas>
        <input
                type="text"
                class="form-control captcha-input"
                :class="{ 'is-invalid': captchaError }"
                placeholder="请输入验证码"
                v-model="enteredCaptcha"
                @keyup.enter="validateCaptcha"
        >
    </div>
</template>

<script>
    export default {
        name: "CaptchaComponent",
        data() {
            return {
                captchaText: "",
                enteredCaptcha: "",
                captchaError: false
            };
        },
        methods: {
            generateCaptcha() {
                const yzm = this.$refs.captchaCanvas;
                const cxt = yzm.getContext("2d");
                const chars = "的一是不了人我在有他这为之大来以个中上们到说国和地也子时道年得就那要下以生会自着去之与会生而于他地时出法然还但下前因动以成电重而地";
                let cap = "";
                cxt.clearRect(0, 0, yzm.width, yzm.height);
                cxt.fillStyle = "#ffffff";
                cxt.fillRect(0, 0, yzm.width, yzm.height);

                // 绘制干扰线（减少数量）
                for (let i = 0; i < 2; i++) {
                    cxt.strokeStyle = this.randomLightColor(); // 使用浅色
                    cxt.beginPath();
                    cxt.moveTo(Math.random() * yzm.width, Math.random() * yzm.height);
                    cxt.lineTo(Math.random() * yzm.width, Math.random() * yzm.height);
                    cxt.stroke();
                }

                // 绘制噪点（浅色）
                for (let i = 0; i < 30; i++) {
                    cxt.fillStyle = this.randomLightColor(); // 使用浅色
                    cxt.beginPath();
                    cxt.arc(Math.random() * yzm.width, Math.random() * yzm.height, 1, 0, 2 * Math.PI);
                    cxt.fill();
                }

                // 绘制验证码字符
                for (let i = 0; i < 4; i++) {
                    const char = chars[Math.floor(Math.random() * chars.length)];
                    cap += char;
                    const x = 10 + (i * 25);
                    const y = 20 + Math.ceil(Math.random() * 10);
                    const fontSize = 20 + Math.ceil(Math.random() * 5);
                    cxt.font = `${fontSize}px KaiTi`; // 设置字体为小楷
                    cxt.fillStyle = "#000000"; // 设置文字颜色为黑色
                    cxt.fillText(char, x, y, 300);
                }
                this.captchaText = cap;
            },
            validateCaptcha() {
                const isValid = this.enteredCaptcha.trim() === this.captchaText;
                console.log('Generated Captcha:', this.captchaText);
                console.log('Entered Captcha:', this.enteredCaptcha);
                console.log('Captcha Valid:', isValid);
                this.captchaError = !isValid;
                this.$emit('validate-captcha', isValid);
            },
            randomLightColor() {
                const r = Math.floor(Math.random() * 156) + 100; // 生成浅色的红色值
                const g = Math.floor(Math.random() * 156) + 100; // 生成浅色的绿色值
                const b = Math.floor(Math.random() * 156) + 100; // 生成浅色的蓝色值
                return `rgb(${r},${g},${b})`;
            }
        },
        mounted() {
            this.generateCaptcha();
        }
    };
</script>

<style scoped>
    .captcha-container {
        display: flex;
        align-items: center;
        margin-top: 1rem;
    }
    .captcha-container canvas {
        margin-right: 10px;
    }
    .captcha-input {
        flex: 1;
        border-radius: 2px; /* 调整验证码输入框的圆角大小 */
        line-height: 1.5 !important;
    }
    .is-invalid {
        border-color: #dc3545 !important;
        background-image: none !important; /* 移除叹号图标 */
    }
</style>
