<template>
    <!--重置密码-->
    <!--导航栏-->
    <ZhaiHead></ZhaiHead>
    <!--导航栏-->
    <!--只是占据空间-->
    <div class="gongxi1"></div>
    <!--顶部占据空间-->

    <!--主体内容-->
    <div class="container-fluid px-0">
        <div class="shengse">
            <div class="dixian px-4 py-2 d-flex justify-content-between">
                <div><i class="bi bi-person-add"></i>VIP专用重置密码</div>
            </div>
            <div class="px-5 mt-3 pb-4">
                <div>
                    <input type="text" class="form-control custom-input" v-model="myform.username" placeholder="请输入用户名" :class="{ 'is-invalid': errors.username }">
                </div>
                <text class="tishi">*请输入注册时用户名</text>
                <div class="mt-3">
                    <input type="email" class="form-control custom-input" v-model="myform.email" placeholder="邮箱" :class="{ 'is-invalid': errors.email }">
                </div>
                <text class="tishi">*请输入注册时使用的邮箱</text>
                <div class="mt-3">
                    <input type="password" class="form-control custom-input" v-model="myform.password" placeholder="请输入密码" :class="{ 'is-invalid': errors.password }">
                </div>
                <div class="mt-3">
                    <input type="password" class="form-control custom-input" v-model="myform.password2" placeholder="请再次输入密码" :class="{ 'is-invalid': errors.password2 }">
                </div>
                <div class="col-12 mt-4 text-center">
                    <button type="button" @click="resetPassword" class="btn btn-success w-100">确认重置</button>
                </div>
            </div>
        </div>
    </div>
    <!--主体内容-->

    <!--只是占据空间-->
    <div class="gongxi2"></div>
    <!--顶部占据空间-->

    <!--公共底部-->
    <ZhaiFoot></ZhaiFoot>
    <!--公共底部-->

    <!-- 模态框 -->
    <div class="modal fade" id="errorModal" tabindex="-1" aria-labelledby="errorModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered small-modal">
            <div class="modal-content">
                <div class="modal-body d-flex align-items-center justify-content-center small-padding">
                    <i class="bi bi-emoji-frown small-icon"></i>
                    <p class="mb-0 ms-2 small-text">{{ errorMessage }}</p>
                </div>
            </div>
        </div>
    </div>
    <!-- 成功模态框 -->
    <div class="modal fade" id="successModal" tabindex="-1" aria-labelledby="successModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered small-modal">
            <div class="modal-content">
                <div class="modal-body d-flex align-items-center justify-content-center small-padding">
                    <i class="bi bi-emoji-smile small-icon" style="color: #00c26f;"></i>
                    <p class="mb-0 ms-2 small-text" style="color: #00c26f;">重置密码成功</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import http from "../utils/http.js";
    import ZhaiHead from "../components/ZhaiHead";
    import ZhaiFoot from "../components/ZhaiFoot";
    import { Modal } from 'bootstrap';

    export default {
        name: "MResetPasswords",
        components: { ZhaiHead, ZhaiFoot },
        data() {
            return {
                myform: {
                    username: "",
                    email: "",
                    password: "",
                    password2: ""
                },
                errors: {},
                errorMessage: ""
            };
        },
        methods: {
            resetPassword() {
                this.errors = {};
                if (!this.myform.username.trim()) {
                    this.showErrorModal("用户名不能为空", 'username');
                    return;
                }
                if (!this.myform.email.trim()) {
                    this.showErrorModal("邮箱不能为空", 'email');
                    return;
                }
                if (!this.validateEmail(this.myform.email)) {
                    this.showErrorModal("邮箱格式有误", 'email');
                    return;
                }
                if (!this.myform.password.trim()) {
                    this.showErrorModal("密码不能为空", 'password');
                    return;
                }
                if (!/^[^\s]{6,15}$/.test(this.myform.password)) {
                    this.showErrorModal("密码应为6-15位字符且不能包含空格", 'password');
                    return;
                }
                if (this.myform.password !== this.myform.password2) {
                    this.showErrorModal("两次密码不一致", 'password2');
                    return;
                }

                let url = "/platform/frontEnd/changePassword";
                let fm = new FormData();
                fm.append("username", this.myform.username);
                fm.append("password", this.myform.password);
                fm.append("password2", this.myform.password2);
                fm.append("email", this.myform.email);

                http.post(url, fm, true).then(response => {
                    if (response.data.status === 1) {
                        this.showSuccessModal();
                    } else {
                        this.handleFormErrors(response.data);
                    }
                }).catch(error => {
                    console.error("重置请求失败:", error);
                    this.showErrorModal("重置请求失败，请稍后重试。");
                });
            },
            validateEmail(email) {
                const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return re.test(email);
            },
            handleFormErrors(data) {
                if (data.field && data.message) {
                    this.showErrorModal(data.message, data.field);
                } else {
                    this.showErrorModal(data.msg || "重置失败！");
                }
            },
            showErrorModal(message, field) {
                this.errorMessage = message;
                if (field) {
                    this.errors[field] = true;
                }
                const modalElement = document.getElementById('errorModal');
                const modal = new Modal(modalElement);
                modal.show();

                setTimeout(() => {
                    modalElement.querySelector('.modal-content').classList.add('shake');
                    setTimeout(() => {
                        modalElement.querySelector('.modal-content').classList.remove('shake');
                    }, 1000);
                }, 0);

                setTimeout(() => {
                    modal.hide();
                }, 3000);
            },
            showSuccessModal() {
                const modalElement = document.getElementById('successModal');
                const modal = new Modal(modalElement);
                modal.show();

                setTimeout(() => {
                    modal.hide();
                    this.$router.push('/login'); // 跳转到登录页面
                }, 3000);
            }
        }
    };
</script>

<style scoped>
    .gongxi1 {
        height: 80px;
    }
    /* 提示文字红色 */
    .tishi {
        color: red;
        font-size: 0.9rem;
    }
    /* 自定义输入框样式 */
    .custom-input {
        border-radius: 2px !important;
        line-height: 1.5 !important;
    }
    /* 底部不占据空间 */
    .gongxi2 {
        height: 30px;
    }
    /* 按钮颜色字体大小 */
    .btn-success {
        background-color: #00c26f !important;
        font-size: 0.9rem;
        border: none; /* 移除按钮边框 */
    }
    /* 输入框内和提示文字的大小 */
    .form-control {
        font-size: 0.9rem;
    }
    /* 调整模态框和文本的大小 */
    .small-modal .modal-content {
        width: 100%;
        max-width: 300px;
        margin: 0 auto;
    }
    .small-padding {
        padding: 5px !important;
    }
    .small-icon {
        font-size: 1.5rem;
        color: #dc3545;
    }
    .small-text {
        font-size: 0.9rem;
        color: #dc3545;
    }
    .shake {
        animation: shake 0.5s;
    }
    @keyframes shake {
        0%, 100% { transform: translateX(0); }
        10%, 30%, 50%, 70%, 90% { transform: translateX(-2px); }
        20%, 40%, 60%, 80% { transform: translateX(2px); }
    }
    .is-invalid {
        border-color: #dc3545 !important;
        background-image: none !important;
    }
</style>
