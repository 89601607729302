<template>
    <!--PC导航栏-->
    <nav class="navbar navbar-expand-lg zhai-navColour">
        <div class="container custom-container-padding">
            <!--logo-->
            <router-link to="/" class="navbar-brand">
                <img class="im" alt="" src="../assets/daohangtubiao.jpg">
            </router-link>
            <!--logo-->
            <!--主体-->
            <button class="navbar-toggler" type="button" @click="toggleCollapse">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" :class="{ 'show': isCollapsed }" id="navbarSupportedContent">
                <!--左侧选项-->
                <ul class="navbar-nav me-auto mb-2 mb-lg-0" style="font-size: 100%;">
                    <li class="nav-item" v-for="(item, index) in navItems" :key="index">
                        <router-link :to="item.to" class="nav-link" :class="{ active: $route.path === item.to }">{{ item.name }}</router-link>
                    </li>
                </ul>
                <!--搜索-->
                <form class="d-flex align-items-center me-auto" @submit.prevent="performSearch">
                    <div class="input-group">
                        <input v-model="searchKeyword" class="form-control form-control-sm rounded-start custom-input" type="search" placeholder="" aria-label="Search">
                        <button class="btn btn-outline-secondary custom-button" type="submit">
                            <i class="bi bi-search text-white"></i>
                        </button>
                    </div>
                </form>
                <!--右侧选项-->
                <ul class="navbar-nav ms-auto right-nav" style="font-size: 100%;">
                    <li v-if="isLoggedIn" class="nav-item">
                        <router-link to="/memberCenter" class="nav-link" :class="{ active: $route.path === '/memberCenter' }">会员中心</router-link>
                    </li>
                    <li v-if="isLoggedIn" class="nav-item">
                        <a @click="handleLogout" class="nav-link" style="cursor: pointer;">退出</a>
                    </li>
                    <li v-if="!isLoggedIn" class="nav-item">
                        <router-link to="/login" class="nav-link custom-login" :class="{ active: $route.path === '/login' }">登录</router-link>
                    </li>
                    <li v-if="!isLoggedIn" class="nav-item">
                        <router-link to="/register" class="nav-link" :class="{ active: $route.path === '/register' }">注册</router-link>
                    </li>
                </ul>
            </div>
            <!--主体-->
        </div>
    </nav>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: "ZhaiHeadPC",
        data() {
            return {
                isCollapsed: false, // 控制导航栏的折叠状态
                searchKeyword: '', // 搜索关键词
                navItems: [ // 导航项
                    { name: '信息', to: '/' },
                    { name: '发布', to: '/infoPublish' },
                    { name: 'VIP升级', to: '/upgradeVIP' },
                    { name: '防骗指南', to: '/avoidFraud' }
                ]
            };
        },
        computed: {
            ...mapGetters(['isLoggedIn']) // 映射 Vuex 的 isLoggedIn getter，用于判断用户是否已登录
        },
        methods: {
            ...mapActions(['logout']), // 映射 Vuex 的 logout action，用于处理用户退出登录
            toggleCollapse() {
                this.isCollapsed = !this.isCollapsed; // 切换导航栏的折叠状态
            },
            performSearch() {
                this.$router.push({ path: '/SearchS', query: { keyWord: this.searchKeyword } }); // 执行搜索并跳转到搜索结果页
            },
            async handleLogout() {
                await this.logout(); // 调用 Vuex 的 logout 方法进行用户登出
                this.$router.push('/login'); // 确保登出后跳转到登录页
            }
        }
    };
</script>

<style scoped>
    /* 样式 */
    /*logo图片大小*/
    .navbar-brand img {
        max-height: 35px; /* 调节图片的最大高度 */
        margin-right: 5px; /* 调整图片右侧的外边距 */
    }

    /* 确保导航栏和 container 有明确的高度 */
    .navbar {
        height: 70px; /* 设置导航栏高度 */
    }

    .custom-container-padding {
        height: 70px; /* 确保 container 与 navbar 高度一致 */
        padding-left: 30px; /* 左侧内边距 */
        padding-right: 30px; /* 右侧内边距 */
        display: flex; /* 使内容在垂直方向居中对齐 */
        align-items: center; /* 垂直居中对齐 */
    }

    /* 确保 collapse 和 ul 的高度与 navbar 一致 */
    .navbar-collapse {
        height: 70px; /* 设置导航栏折叠部分的高度 */
        display: flex; /* 使内容在垂直方向居中对齐 */
        align-items: center; /* 垂直居中对齐 */
        justify-content: space-between; /* 使内容在水平方向均匀分布 */
    }

    .navbar-nav {
        height: 100%; /* 使 ul 高度与 navbar 一致 */
        display: flex;
        align-items: center; /* 垂直居中对齐 */
    }

    /* 调整每个导航项的高度 */
    .nav-item {
        height: 100%; /* 确保每个导航项高度与 navbar 一致 */
        display: flex;
        align-items: center; /* 垂直居中对齐 */
        justify-content: center; /* 内容居中 */
        margin-right: 15px; /* 右侧外边距，调整间距 */
    }

    .nav-item:last-child {
        margin-right: 0; /* 取消最后一个项的右侧外边距 */
    }

    /* 选中导航项的背景颜色和字体颜色 */
    .nav-link.active {
        background-color: white; /* 选中时背景颜色为白色 */
        color: black !important; /* 选中时字体颜色为黑色 */
        height: 100%; /* 确保高度与 nav-item 一致 */
        display: flex;
        align-items: center; /* 垂直居中对齐 */
        justify-content: center; /* 水平居中对齐 */
        border-radius: 0; /* 取消圆角 */
    }

    /* 未选中导航项的背景颜色和字体颜色 */
    .nav-link {
        color: white; /* 默认字体颜色为白色 */
        height: 100%; /* 确保高度与 nav-item 一致 */
        display: flex;
        align-items: center; /* 垂直居中对齐 */
        justify-content: center; /* 水平居中对齐 */
        border-radius: 0; /* 取消圆角 */
    }

    /* 未选中导航项的 hover 样式 */
    .nav-link:hover {
        background-color: white; /* hover 时背景颜色为白色 */
        color: black!important; /* hover 时字体颜色为黑色 */
    }

    /* 搜索框和按钮融合样式 */
    .input-group .form-control {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 0.25rem; /* 圆润的左侧边框 */
        border-bottom-left-radius: 0.25rem; /* 圆润的左侧边框 */
    }
    .input-group .btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: none; /* 移除按钮左侧边框 */
    }
    .custom-input {
        line-height: 1; /* 调整行高 */
        padding: 0.2rem 0.4rem; /* 调整内边距，确保输入框内容居中 */
        font-size: 95%; /* 保持字体大小一致 */
    }
    /* 放大镜外框样式的调整 */
    .custom-button {
        line-height: 1; /* 调整行高 */
        padding: 0.2rem 0.4rem; /* 调整内边距 */
    }
    /* 放大镜图标调整 */
    .custom-button i {
        vertical-align: middle; /* 垂直居中对齐图标 */
    }
    /* 选中前的字体颜色 */
    .custom-login {
        color: white;
    }
    /* 选中后的字体颜色和背景 */
    .custom-login.active {
        background-color: white; /* 选中时背景颜色为白色 */
        color: black;
    }
    /* 小手 */
    .custom-login:hover {
        background-color: white; /* hover 时背景颜色为白色 */
        color: black; /* hover 时字体颜色为黑色 */
    }

    /* 让右侧选项靠右对齐 */
    .right-nav {
        margin-left: auto; /* 使用 margin-left: auto; 将右侧选项推到最右 */
    }
</style>
