<template>
    <!--三个按钮-->
    <div class="row justify-content-center">
        <div class="col-auto"><router-link to="/wayHome" type="button" class="btn btn-dark nav-button">回家的路</router-link></div>
        <div class="col-auto"><router-link to="/queryAccount" type="button" class="btn btn-success nav-button">账号查询</router-link></div>
        <div class="col-auto"><router-link to="/avoidFraud" type="button" class="btn btn-success nav-button">防骗指南</router-link></div>
    </div>
    <!--三个按钮-->
</template>

<script>
    export default {
        name: "ZhaiThreeTuttonsPC"
    }
</script>

<style scoped>
    /* 添加的CSS规则 */
    .nav-button {
        padding: 5px 10px; /* 调整内边距以减小按钮大小 */
        font-size: 14px; /* 调整字体大小 */
        margin-top: 10px; /* 增加上边距 */
    }
    .btn-success{
        background-color: #18b371 !important;
    }
    .row {
        display: flex;
        justify-content: center; /* 水平居中 */
        align-items: center; /* 垂直居中 */
    }

    .col-auto {
        display: flex;
        justify-content: center; /* 水平居中 */
        align-items: center; /* 垂直居中 */
    }
    .btn-dark{
        border: none !important;
    }
    .btn-success{
        background-color: #18b371 !important;
        border: none !important;
    }


</style>