<template>
    <!-- PC会员中心页面的模板部分 -->
    <ZhaiHeadPC class="zhai-head"></ZhaiHeadPC> <!-- 导航栏组件 -->

    <!-- 主体内容容器，当用户已登录时显示 -->
    <div class="container phome-container-box errong" v-if="getUser">
        <ZhaiJiuGongPC menu="1"></ZhaiJiuGongPC> <!-- 九宫格导航组件 -->

        <!-- 会员中心主要信息显示部分 -->
        <div class="container-fluid mt-2 shengse ">
            <div class="row">
                <!-- 左侧部分：显示会员的基本信息和支付记录 -->
                <div class="col-6 kale">
                    <div class="LeftPart">
                        <div class="mx-2">
                            <!-- 会员名字 -->
                            <div class="row mt-2 hua">
                                <div class="dixian px-3 py-2 mb-1  d-flex justify-content-between">
                                    <div class="py-1"><span>欢迎登录：</span>&nbsp;{{ getUser.username }}</div> <!-- 显示会员用户名 -->
                                </div>
                            </div>
                            <!-- 会员等级 -->
                            <div class="row  py-2 hui">
                                <div class="px-3 Profile d-flex align-items-center justify-content-start">
                                    <div class="La">会员等级：</div>
                                    <span class="niandu changlv">
                                     <strong>
                                     <!-- 判断VIP是否已过期 -->
                                     <template v-if="getUser.level_expire_date && new Date() > new Date(getUser.level_expire_date)">
                                     （VIP已过期）
                                     </template>
                                     <template v-else>
                                     {{ getUser.level }}
                                      </template>
                                     </strong>
                                     </span>
                                </div>
                            </div>
                            <!-- 会员VIP有效期 -->
                            <div class="row  py-2 hui">
                                <div class="px-3 Profile d-flex align-items-center justify-content-start">
                                    <div class="La">VIP有效期：</div> <!-- 显示VIP有效期 -->
                                    <div class="niandu"><span>{{ formatExpireDate(getUser.level_expire_date) }}</span></div>
                                </div>
                            </div>
                            <!-- 支付记录明细 -->
                            <div class="hui py-2" v-if="!fetchFailed && userEvents.length > 0">
                                <div>
                                    <div class="La px-1">支付订单：</div> <!-- 显示支付订单标题 -->
                                    <table class="layui-table"> <!-- 支付记录表格 -->
                                        <colgroup>
                                            <col width="150">
                                            <col width="150">
                                            <col width="200">
                                            <col>
                                        </colgroup>
                                        <thead>
                                        <tr>
                                            <th>订单类型</th>
                                            <th>支付金额</th>
                                            <th>支付时间</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr v-for="(event, index) in userEvents" :key="index"> <!-- 循环显示每个支付记录 -->
                                            <td>{{ event.event }}</td>
                                            <td>{{ event.price }}元</td>
                                            <td>{{ formatDate(event.create_time) }}</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <!-- 支付记录明细结束 -->
                        </div>
                    </div>
                </div>
                <!-- 左侧部分结束 -->

                <!-- 右侧部分：显示VIP会员的详细信息及购买选项 -->
                <div class="col-6">
                    <div class="RightPart">
                        <div class="pt-2">
                            <table class="table table-bordered" style="margin-top:10px;"> <!-- VIP会员信息表格 -->
                                <thead>
                                <tr>
                                    <th scope="col">类型</th>
                                    <th scope="col">价格</th>
                                    <th scope="col">查看权限</th>
                                    <th scope="col">有效期</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td>VIP月度会员</td>
                                    <td>¥90</td>
                                    <td>所有内容</td>
                                    <td>+1月度</td>
                                </tr>
                                <tr>
                                    <td>VIP季度会员</td>
                                    <td>¥130</td>
                                    <td>所有内容</td>
                                    <td>+1季度</td>
                                </tr>
                                <tr>
                                    <td>VIP年度会员</td>
                                    <td>¥300</td>
                                    <td>所有内容</td>
                                    <td>+1年</td>
                                </tr>
                                <tr>
                                    <td>VIP永久会员</td>
                                    <td>¥500</td>
                                    <td>所有内容</td>
                                    <td>永久</td>
                                </tr>
                                </tbody>
                            </table>
                            <div class="text-center my-3">
                                <router-link to="/upgradeVIP" class="btn btn-primary">购买VIP</router-link> <!-- 跳转到购买VIP页面的按钮 -->
                            </div>
                        </div>
                        <!-- VIP明细表格结束 -->
                    </div>
                </div>
                <!-- 右侧部分结束 -->
            </div>
        </div>
        <!-- 会员中心主要信息显示部分结束 -->
    </div>

    <!-- 占位元素，用于填充空白区域 -->
    <div class="gongxi2"></div>

    <!-- 公共底部 -->
    <ZhaiFootPC></ZhaiFootPC>
</template>

<script>
    // 导入所需的组件和模块
    import ZhaiHeadPC from "../componentspc/ZhaiHeadPC"; // 导入PC端头部组件
    import ZhaiFootPC from "../componentspc/ZhaiFootPC"; // 导入PC端底部组件
    import ZhaiJiuGongPC from "../componentspc/ZhaiJiuGongPC"; // 导入九宫格导航组件
    import { mapGetters } from 'vuex'; // 从 Vuex 中导入 mapGetters，用于将 Vuex 中的 getter 映射到组件的计算属性
    import http from "../utils/http.js"; // 导入自定义的 HTTP 工具类，用于发送 API 请求

    export default {
        name: "PMemberCenter", // 组件名称
        components: {
            ZhaiHeadPC,
            ZhaiFootPC,
            ZhaiJiuGongPC
        }, // 声明使用的组件

        data() {
            return {
                userEvents: [], // 用于存储从后端获取的用户支付记录数据
                fetchFailed: false // 用于标识是否获取用户记录失败
            };
        },

        computed: {
            // 使用 Vuex 的 mapGetters 将 Vuex 中的 getUser getter 映射为组件的计算属性
            ...mapGetters(['getUser'])
        },

        methods: {
            // 定义方法用于获取用户的支付记录信息
            fetchUserEventInfo() {
                if (!this.getUser || !this.getUser.id) {
                    console.error('用户未登录');
                    return;
                }

                const userId = this.getUser.id;
                const url = `/platform/frontEnd/getUserEventInfo?userid=${userId}`;
                console.log(`Fetching user events with URL: ${url}`);

                http.get(url)
                    .then(response => {
                        console.log("API 响应数据:", response.data);
                        if (response.data && response.data.status === 1) {
                            this.userEvents = response.data.data;
                            this.fetchFailed = false; // 成功获取数据
                            console.log("用户支付记录:", this.userEvents);
                        } else {
                            this.fetchFailed = true; // 获取数据失败
                            console.error('获取用户支付记录失败:', response.data.msg);
                        }
                    })
                    .catch(error => {
                        this.fetchFailed = true; // API 请求出错
                        console.error('API 请求错误:', error);
                    });
            },
            // 格式化日期为 YYYY-MM-DD 的方法
            formatDate(date) {
                if (!date) return '';
                const d = new Date(date);
                const year = d.getFullYear();
                const month = String(d.getMonth() + 1).padStart(2, '0');
                const day = String(d.getDate()).padStart(2, '0');
                const hours = String(d.getHours()).padStart(2, '0');
                const minutes = String(d.getMinutes()).padStart(2, '0');
                const seconds = String(d.getSeconds()).padStart(2, '0');
                return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            },
            // 格式化VIP到期日期的方法，包含日期和时间
            formatExpireDate(date) {
                if (!date) {
                    return '无'; // 当日期为空时显示“无”
                }

                const now = new Date(); // 获取当前时间
                const expireDate = new Date(date);

                // 判断当前时间是否已经超过VIP有效期
                if (now > expireDate) {
                    return '无'; // 如果已过期，返回“无”
                }
                const d = new Date(date);
                const year = d.getFullYear();
                const month = String(d.getMonth() + 1).padStart(2, '0');
                const day = String(d.getDate()).padStart(2, '0');
                const hours = String(d.getHours()).padStart(2, '0');
                const minutes = String(d.getMinutes()).padStart(2, '0');
                const seconds = String(d.getSeconds()).padStart(2, '0');

                return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            }
        },
        // 在组件创建时调用 fetchUserEventInfo 方法获取用户支付记录
        created() {
            this.fetchUserEventInfo();
        }
    };
</script>

<style scoped>
    /* 使用CSS Reset来确保不同浏览器的一致性 */
    /** {*/
        /*margin: 0;*/
        /*padding: 0;*/
        /*box-sizing: border-box;*/
    /*}*/

    body {
        font-family: Arial, sans-serif; /* 设置全局字体 */
    }

    .gongxi2 {
        height: 20px; /* 设置占位元素的高度 */
    }

    /* 最大容器的左右内边距 */
    .errong {
        padding-left: 30px;
        padding-right: 30px;
    }
    .new-container-padding {
        padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
        padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
    }

    /*.phome-container-box{*/
        /*margin-left: auto !important;*/
        /*margin-right: auto !important;*/
    /*}*/

    /* 支付记录表格样式 */
    .layui-table {
        width: 100%;
        background-color: #fff; /* 背景颜色为白色 */
        color: #666; /* 文字颜色为灰色 */
        margin: 10px 0;
        border: 1px solid #ddd; /* 设置边框宽度、样式和颜色 */
        border-collapse: collapse; /* 使相邻的边框合并为一个单一的边框 */
    }

    /* 为支付记录表格的单元格添加边框及行高 */
    .layui-table th,
    .layui-table td {
        border: 1px solid #ddd; /* 设置边框宽度、样式和颜色 */
        text-align: center; /* 水平居中 */
        vertical-align: middle; /* 垂直居中 */
        line-height: 2rem; /* 设置行高 */
    }

    /* 支付记录表格表头文字恢复正常粗细以及背景颜色 */
    .layui-table th {
        font-weight: normal; /* 设置文字为正常粗细 */
        background-color: #f2f2f2; /* 背景颜色为浅灰色 */
    }

    /* 购买按钮样式 */
    .btn-primary {
        color: #fff; /* 按钮文字颜色为白色 */
        background-color: #00c26f; /* 按钮背景颜色为绿色 */
        margin-bottom: 1rem!important;
        margin-top: 1rem!important;
        border: none !important; /* 移除按钮边框 */
        font-size: 1rem; /* 设置按钮文字大小 */
    }

    /* VIP明细表格文字居中 */
    .table td,
    .table th {
        text-align: center; /* 水平居中 */
        vertical-align: middle; /* 垂直居中 */
    }

    /* VIP明细表格表头背景颜色 */
    .table th {
        background-color: #d5d5d5; /* 设置背景颜色为浅灰色 */
    }

    .kale {
        border-right: 1px solid #f2f2f2; /* 为左侧部分设置右边框 */
    }

    @media (min-width: 768px) {
        .zhai-head {
            position: fixed; /* 固定导航栏位置 */
            top: 0;
            width: 100%; /* 导航栏宽度为100% */
            z-index: 1000; /* 设置较高的z-index以确保导航栏在顶部 */
        }
        .errong {
            margin-top: 90px; /* 根据导航栏高度调整主内容的顶部边距 */
        }
    }

    /* 设置不同部分的字体大小 */
    .hua {
        font-size: 1rem;
    }
    .hui {
        font-size: 0.9rem;
    }
</style>
