<template>
    <!--手机导航栏-->
    <nav class="navbar navbar-expand-lg navbar-light zhai-navColour position-fixed fixed-top">
        <div class="container-fluid d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
                <!-- 左侧导航栏折叠按钮 -->
                <button class="navbar-toggler custom-icon-button LeftNavbarToggler" :class="{ 'collapsed': !isCollapse, 'active': isCollapse }" type="button" @click="toggleCollapse"
                        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <i class="fa fa-bars"></i>
                </button>
            </div>
            <!-- 导航图标 -->
            <router-link to="/" class="navbar-brand mx-auto" href="#">
                <img alt="view" src="../assets/daohangtubiao.jpg" style="height:auto; width:180px;">
            </router-link>
            <!-- 导航图标 -->
            <div class="d-flex align-items-center">
                <!-- 右侧导航栏折叠按钮 -->
                <button class="navbar-toggler custom-icon-button RightNavbarToggler" :class="{ 'collapsed': !isRightCollapse, 'active': isRightCollapse }" type="button" @click="toggleRightCollapse"
                        aria-controls="navbarSupportedContentRight" aria-expanded="false" aria-label="Toggle navigation">
                    <i class="fa fa-user"></i>
                </button>
            </div>
            <!-- 左侧导航栏内容 -->
            <div class="collapse navbar-collapse LeftNav justify-content-between" :class="{ 'show': isCollapse }" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                    <li class="nav-item">
                        <router-link to="/" class="nav-link active" aria-current="page">
                            <i class="fa fa-home px5 LinkIcon"></i>&nbsp;首页
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/infoPublish" class="nav-link">
                            <i class="fa fa-pencil-square-o px5 LinkIcon"></i>&nbsp;发布
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/upgradeVIP" class="nav-link">
                            <i class="fa fa-user-circle-o px5 LinkIcon"></i>&nbsp;VIP升级
                        </router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/avoidFraud" class="nav-link">
                            <i class="fa fa-shield px5 LinkIcon"></i>&nbsp;防骗指南
                        </router-link>
                    </li>
                </ul>
                <!-- 搜索框 -->
                <form class="d-flex align-items-center">
                    <div class="input-group">
                        <input class="form-control" type="search" placeholder="" aria-label="Search">
                        <div class="input-group-append G-CursorPointer" onclick="document.getElementsByClassName('form')[0].submit();">
                            <span class="input-group-text search-button">
                                <i class="fa fa-search"></i>
                            </span>
                        </div>
                    </div>
                </form>
            </div>

            <!-- 右侧导航栏内容 -->
            <div class="collapse navbar-collapse justify-content-end" :class="{ 'show': isRightCollapse }" id="navbarSupportedContentRight">
                <ul class="navbar-nav">
                    <li v-if="isLoggedIn" class="nav-item">
                        <router-link to="/memberCenter" class="nav-link active" aria-current="page">
                            <i class="fa fa-id-card px5 LinkIcon"></i>&nbsp;会员中心
                        </router-link>
                    </li>
                    <li v-if="isLoggedIn" class="nav-item">
                        <div @click="logout" class="nav-link">
                            <i class="fa fa-sign-out px5 LinkIcon"></i>&nbsp;退出
                        </div>
                    </li>
                    <li v-if="!isLoggedIn" class="nav-item">
                        <router-link to="/login" class="nav-link custom-login" :class="{ active: $route.path === '/login' }">
                            <i class="fa fa-sign-in px5 LinkIcon"></i>&nbsp;登录
                        </router-link>
                    </li>
                    <li v-if="!isLoggedIn" class="nav-item">
                        <router-link to="/register" class="nav-link" :class="{ active: $route.path === '/register' }">
                            <i class="fa fa-user-plus px5 LinkIcon"></i>&nbsp;注册
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
    <!--导航栏-->
</template>

<script>
    export default {
        name: "ZhaiHead",
        data() {
            return {
                isCollapse: false, // 控制左侧导航栏折叠状态
                isRightCollapse: false, // 控制右侧导航栏折叠状态
                isLoggedIn: this.checkLoginStatus() // 初始化登录状态
            };
        },
        methods: {
            toggleCollapse() {
                this.isCollapse = !this.isCollapse; // 切换左侧导航栏的折叠状态
                if (this.isCollapse) {
                    this.isRightCollapse = false; // 当左侧导航栏展开时，关闭右侧导航栏
                }
            },
            toggleRightCollapse() {
                this.isRightCollapse = !this.isRightCollapse; // 切换右侧导航栏的折叠状态
                if (this.isRightCollapse) {
                    this.isCollapse = false; // 当右侧导航栏展开时，关闭左侧导航栏
                }
            },
            logout() {
                // 清除本地存储的认证信息
                localStorage.removeItem("token");
                // 更新登录状态
                this.isLoggedIn = false;
                // 跳转到登录页面
                this.$router.push("/login");
            },
            checkLoginStatus() {
                // 检查本地存储中是否有 token 以确定用户是否登录
                return localStorage.getItem('token') !== null;
            }
        }
    }
</script>

<style scoped>
    /* 确保图标与文本对齐 */
    .nav-link {
        display: flex;
        align-items: center;
    }

    .nav-link .fa {
        margin-right: 0.3125rem; /* 调整图标和文本之间的间距 */
    }

    .navbar-toggler.LeftNavbarToggler {
        margin-left: 0.5rem !important; /* 向内移动左侧按钮 */
    }

    .navbar-toggler.RightNavbarToggler {
        margin-right: 0.5rem !important; /* 向内移动右侧按钮 */
    }

    .navbar-toggler {
        border: none;
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none; /* 移除点击时的外框 */
        box-shadow: none; /* 移除点击时的阴影 */
    }

    .navbar-toggler:focus {
        outline: none; /* 移除点击时的外框 */
        box-shadow: none; /* 移除点击时的阴影 */
    }

    .navbar-toggler.active i {
        color: #00c26f; /* 点击后图标变绿 */
    }

    .input-group .form-control {
        border-top-left-radius: 0.25rem; /* 圆润的左侧边框 */
        border-bottom-left-radius: 0.25rem; /* 圆润的左侧边框 */
        border-right: 0; /* 去掉右边框 */
    }

    .input-group-append .search-button {
        border-top-right-radius: 0.25rem; /* 圆润的右侧边框 */
        border-bottom-right-radius: 0.25rem; /* 圆润的右侧边框 */
        border-left: 0; /* 去掉左边框 */
        background-color: #2b2b37; /* 修改背景色 */
        color: white; /* 修改文字颜色 */
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%; /* 确保与输入框行高一致 */
        padding: 0 10px;
    }

    .input-group-append .search-button i {
        margin: 0;
    }

    .input-group-text {
        border: 0 !important;
    }

    .custom-icon-button {
        border: 1px solid white; /* 边框颜色 */
        border-radius: 4px; /* 圆角 */
        padding: 5px 10px; /* 内边距 */
        background-color: transparent; /* 背景色透明 */
        color: white; /* 图标颜色 */
        font-size: 16px; /* 图标大小 */
        display: flex;
        align-items: center;
        justify-content: center;
        outline: none; /* 移除点击时的外框 */
        box-shadow: none; /* 移除点击时的阴影 */
    }

    .custom-icon-button i {
        font-size: 20px; /* 图标大小 */
    }

    .custom-icon-button:focus {
        outline: none; /* 移除点击时的外框 */
        box-shadow: none; /* 移除点击时的阴影 */
    }

    .custom-icon-button:hover {
        background-color: rgba(255, 255, 255, 0.1); /* 悬停效果 */
    }
</style>
