import { createApp } from 'vue';  // 从 Vue 3 中引入 `createApp` 方法，用于创建一个 Vue 应用实例
import App from './App.vue';      // 引入根组件 `App.vue`
import router from './router';    // 引入路由配置文件，用于设置应用的路由
import store from './store';      // 引入 Vuex 状态管理文件，用于全局状态管理
import 'bootstrap/dist/css/bootstrap.min.css';  // 引入 Bootstrap 的 CSS 文件，用于提供样式
import 'bootstrap/dist/js/bootstrap.min.js';    // 引入 Bootstrap 的 JavaScript 文件，用于提供交互功能
import "bootstrap-icons/font/bootstrap-icons.css"; // 引入 Bootstrap 图标库，用于在项目中使用图标

// 创建 Vue 应用实例，并使用 Vuex 状态管理 (`store`) 和 Vue 路由 (`router`)，然后将应用挂载到 `#app` 这个 DOM 元素上
createApp(App).use(store).use(router).mount('#app');
