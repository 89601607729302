<template>
    <div class="row dage shengse mt-2">
        <router-link class="no-underline" to="/memberCenter" :class="menu=='1'?'col ge g-active':'col ge'">
            <div class="Icon col-60">
                <i class="bi bi-person"></i>
            </div>
            <div class="Title col-60">
                会员中心
            </div>
        </router-link>
        <router-link class="no-underline" to="/infoPublish" :class="menu=='2'?'col ge2 g-active':'col ge2'">
            <div class="Icon col-60">
                <i class="bi bi-plus-circle-fill"></i>
            </div>
            <div class="Title col-60">
                信息发布
            </div>
        </router-link>
        <router-link class="no-underline" to="/myRelease" :class="menu=='3'?'col ge3 g-active':'col ge3'">
            <div class="Icon col-60">
                <i class="bi bi-list-ul"></i>
            </div>
            <div class="Title col-60">
                我的发布
            </div>
        </router-link>
    </div>
    <div class="row dage shengse">
        <router-link class="no-underline" to="/myFavorite" :class="menu=='4'?'col ge1 g-active':'col ge1'">
            <div class="Icon col-60">
                <i class="bi bi-collection-fill"></i>
            </div>
            <div class="Title col-60">
                我的收藏
            </div>
        </router-link>
        <router-link class="no-underline" to="/upgradeVIP" :class="menu=='5'?'col ge22 g-active':'col ge22'">
            <div class="Icon col-60">
                <i class="bi bi-arrow-90deg-up"></i>
            </div>
            <div class="Title col-60">
                升级VIP
            </div>
        </router-link>
        <router-link class="no-underline" to="/modifiedData" :class="menu=='6'?'col ge33 g-active':'col ge33'">
            <div class="Icon col-60">
                <i class="bi bi-pencil-square"></i>
            </div>
            <div class="Title col-60">
                修改资料
            </div>
        </router-link>
    </div>
    <div class="row dage shengse">
        <router-link class="no-underline" to="/queryAccount" :class="menu=='7'?'col ge1 g-active':'col ge1'">
            <div class="Icon col-60">
                <i class="bi bi-search"></i>
            </div>
            <div class="Title col-60">
                账号查询
            </div>
        </router-link>
        <router-link class="no-underline" to="/wayHome" :class="menu=='8'?'col ge22 g-active':'col ge22'">
            <div class="Icon col-60">
                <i class="bi bi-house"></i>
            </div>
            <div class="Title col-60">
                回家的路
            </div>
        </router-link>
        <router-link class="no-underline" to="/vipGroup" :class="menu=='9'?'col ge33 g-active':'col ge33'">
            <div class="Icon col-60">
                <i class="bi bi-telegram"></i>
            </div>
            <div class="Title col-60">
                VIP电报群
            </div>
        </router-link>
    </div>
</template>

<script>
    export default {
        name: "ZhaiJiuGong",
        props: ["menu"]
    }
</script>

<style scoped>
    /* 每一行 */
    .dage {
        display: flex;
        flex-wrap: nowrap; /* 防止换行 */
        justify-content: space-between; /* 项目之间的间隔 */
        border-bottom: 1px solid #ddd;
    }

    /* 每列 */
    .col {
        flex: 1; /* 使列平均分配空间 */
        text-align: center;
        padding: 1px 0; /* 调整行高 */
        color: #262626;
    }

    /* 去除下划线 */
    .no-underline {
        text-decoration: none;
    }

    /* 图标 */
    .Icon {
        font-size: 1.5rem;

    }

    /* 标题 */
    .Title {
        font-size: 0.9rem;
    }

    /* 边框设置 */
    .dage > .col:not(:last-child) {
        border-right: 1px solid #ddd;
    }

    /* 激活状态 */
    .g-active {
        background-color: #00c26f; /* 设置你想要的绿色 */
        color: white;
    }

    /* 自定义颜色 */
    .g-active .Icon,
    .g-active .Title {
        color: white;
    }

    /* 边框设置 */
    .ge, .ge2, .ge3, .ge1, .ge22, .ge33 {
        border-top: none;
        border-right: 1px solid #999;
        border-bottom: none;
        border-left: none;
    }

    .ge3 {
        border-right: none;
    }

    .ge1, .ge22, .ge33 {
        border-top: none; /* 去掉第二行的上边框 */
    }

    .ge33 {
        border-right: none;
    }
</style>
