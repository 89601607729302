<template>
    <!--手机我的收藏 -->
    <!-- 导航栏 -->
    <ZhaiHead></ZhaiHead>
    <!-- 九宫格 -->
    <div class="container-fluid zhu mb-2">
        <ZhaiJiuGong menu="4"></ZhaiJiuGong>
    </div>
    <!-- 主体部分 -->
    <div class="container-fluid shengse">
        <div class="row shengse">
            <div class="dixian px-3 py-2 d-flex justify-content-between">
                <div>我的收藏</div>
            </div>
        </div>
        <!-- 条件渲染 -->
        <div v-if="posts.length === 0" class="text-center my-3 pb-3">
            {{ getUser ? '暂无收藏内容' : '用户未登录' }}
        </div>
    </div>
    <!-- 卡片展示区 -->
    <div class="container-fluid mt-2 px-0">
        <div class="row card-container">
            <div class="col-md-4 mb-1 card" v-for="item in posts" :key="item.id">
                <ZhaiCard :data="item"></ZhaiCard>
            </div>
        </div>
    </div>
    <!-- 分页 -->
    <div v-if="posts.length > 0" class="bg-white my-2 p-3 G-Field text-center d-flex justify-content-center align-items-center">
        <a class="page-link pagination-btn" href="#" aria-label="Previous" @click.prevent="prevPage" :disabled="currentPage === 1">
            <span aria-hidden="true">«</span>
        </a>
        <span style="margin: 0 1rem;">第 {{ currentPage }} 页，共 {{ totalPages }} 页</span>
        <a class="page-link pagination-btn" href="#" aria-label="Next" @click.prevent="nextPage" :disabled="currentPage === totalPages">
            <span aria-hidden="true">»</span>
        </a>
    </div>

    <!-- 公共底部 -->
    <ZhaiFoot></ZhaiFoot>
</template>

<script>
    import ZhaiHead from "../components/ZhaiHead";
    import ZhaiFoot from "../components/ZhaiFoot";
    import ZhaiJiuGong from "../components/ZhaiJiuGong";
    import ZhaiCard from "../components/ZhaiCard";
    import http from "../utils/http.js";
    import { mapGetters } from 'vuex';

    export default {
        name: "MMyFavorite",
        components: { ZhaiHead, ZhaiFoot, ZhaiJiuGong, ZhaiCard },
        data() {
            return {
                posts: [],
                currentPage: 1,  // 当前页码
                pageSize: 24,
                totalPages: 1,  // 总页数
            };
        },
        computed: {
            ...mapGetters(['getUser']),
        },
        mounted() {
            this.fetchPosts();
        },
        methods: {
            fetchPosts() {
                if (!this.getUser || !this.getUser.id) {
                    console.error('用户未登录');
                    return;
                }

                const userId = this.getUser.id;
                const url = `/platform/frontEnd/collect?userid=${userId}&pageNum=${this.currentPage}&pageSize=${this.pageSize}`;

                http.get(url)
                    .then(response => {
                        if (response.data && response.data.status === 1) {
                            this.posts = response.data.data.list;
                            this.totalPages = Math.ceil(response.data.data.total / this.pageSize);  // 计算总页数
                        } else {
                            console.error('获取收藏信息失败:', response.data.msg);
                        }
                    })
                    .catch(error => {
                        console.error('API 请求错误:', error);
                    });
            },
            prevPage() {
                if (this.currentPage > 1) {
                    this.currentPage--;
                    this.fetchPosts();
                }
            },
            nextPage() {
                if (this.currentPage < this.totalPages) {
                    this.currentPage++;
                    this.fetchPosts();
                }
            }
        }
    };
</script>

<style scoped>
    .pagination {
        display: block;
    }
    .zhu {
        margin-top: 80px;
    }

    .pagination li {
        display: inline-block;
        margin-left: 2px;
        border: 1px solid #ededed;
        padding: 0 12px;
        color: #3e3e3e;
        line-height: 30px;
        font-size: 16px;
        text-decoration: none;
    }

    .card-container {
        display: flex;
        flex-wrap: wrap;
        margin-left: -1px;
        margin-right: -1px;
    }

    .col-md-4 {
        padding-left: 2px;
        padding-right: 2px;
        display: flex;
    }

    .pagination-btn {
        background: none;
        border: 1px solid #dee2e6;
        border-radius: 2px;
        cursor: pointer;
        padding: 0.3rem 0.75rem;
        font-size: 1.2rem;
        color: black;
        text-decoration: none;
        display: flex;
        align-items: center;
        transition: background-color 0.3s ease;
    }

    .pagination-btn:hover {
        background-color: #f8f9fa;
        border-color: #dee2e6;
    }

    .pagination-btn:disabled {
        color: #dee2e6;
        cursor: not-allowed;
        border-color: #dee2e6;
    }

    .card {
        border: none;
    }
</style>
