<template>
    <!--PC 信息发布 -->
    <ZhaiHeadPC class="zhai-head"></ZhaiHeadPC>

    <!-- 主体内容容器 -->
    <div class="container errong">
        <ZhaiJiuGongPC menu="2"></ZhaiJiuGongPC>

        <div class="row mt-3">
            <div class="zida dixian px-3 py-2 d-flex justify-content-between">
                <div>信息发布:</div>
            </div>
            <div class="p-3">
                <div class="huiziti2" style="padding: 1rem;color: #edff00;background: #2b2b37;line-height: 1.6rem;">
                    <p>1.现要求发帖必须发帖者本人<strong>手持写有“快发卡.com”的纸条</strong>拍照并上传...</p>
                </div>
                <div class="py-2 huiziti2">
                    <div class="mb-2">
                        <label class="mb-2" title="">信息标题:</label>
                        <div class="input-group mb-3">
                            <input type="text" v-model="info.name" :class="{ 'is-invalid': errors.name }" class="form-control" placeholder="填写你的信息标题">
                        </div>
                    </div>
                    <div class="mb-2">
                        <label class="mb-2" title="">所属地区:</label>
                        <ProvinceCitySelectorPC
                                @update:province="provinceUpdated"
                                @update:city="cityUpdated"
                                :provinceError="errors.province"
                                :cityError="errors.city"
                        />
                    </div>
                    <div class="mb-2">
                        <label class="mb-2" title="">账号:</label>
                        <div class="input-group mb-3">
                            <input type="text" v-model="info.account" :class="{ 'is-invalid': errors.account }" class="form-control" placeholder="填写账号">
                        </div>
                    </div>
                    <div class="mb-2">
                        <label class="mb-2" title="">账号年龄:</label>
                        <div class="input-group mb-3">
                            <input type="text" v-model="info.age" :class="{ 'is-invalid': errors.age }" class="form-control" placeholder="填写账号的年龄">
                        </div>
                    </div>
                    <div class="mb-2">
                        <label class="mb-2" title="">账号价格:</label>
                        <div class="input-group mb-3">
                            <input type="text" v-model="info.price" :class="{ 'is-invalid': errors.price }" class="form-control" placeholder="填写账号的价格">
                        </div>
                    </div>
                    <div class="mb-2">
                        <label class="mb-2" title="">账号特点:</label>
                        <div class="input-group mb-3 select-with-arrow">
                            <select v-model="info.condition" :class="{ 'is-invalid': errors.condition }" class="form-control custom-select">
                                <option value="" disabled>选择账号特点</option>
                                <option  v-for="option in conditionOptions" :key="option.value" :value="option.value">
                                    {{ option.label }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="mb-2">
                        <label class="mb-2" title="">详细介绍:</label>
                        <div class="form-floating">
                            <textarea class="form-control" v-model="info.history" :class="{ 'is-invalid': errors.history }" style="height: 100px"></textarea>
                            <label >详情</label>
                        </div>
                        <span style="color: rgb(255, 0, 0);">填写详细介绍将会有助于审核，过于简短将不会被审核通过。</span>
                    </div>
                    <div class="mb-2">
                        <label class="mb-2" title="">账号等级:</label>
                        <div class="input-group mb-3">
                            <input type="text" v-model="info.level" :class="{ 'is-invalid': errors.level }" class="form-control" placeholder="填写账号等级">
                        </div>
                    </div>
                    <div class="mb-2">
                        <label class="mb-2" title="">注意事项:</label>
                        <div class="input-group mb-3">
                            <input type="text" v-model="info.attention" :class="{ 'is-invalid': errors.attention }" class="form-control" placeholder="填写账号的注意事项">
                        </div>
                    </div>
                    <div class="mb-2">
                        <label class="mb-2" title="">电话:</label>
                        <div class="input-group mb-3">
                            <input type="text" v-model="info.phone" :class="{ 'is-invalid': errors.phone }" class="form-control" placeholder="填写您的电话">
                        </div>
                    </div>
                    <div class="mb-2">
                        <label class="mb-2" title="">邮箱:</label>
                        <div class="input-group mb-3">
                            <input type="text" v-model="info.email" :class="{ 'is-invalid': errors.email }" class="form-control" placeholder="填写您的邮箱">
                        </div>
                    </div>
                    <div class="mb-2">
                        <label class="mb-2" title="">来源:</label>
                        <div class="input-group mb-3">
                            <input type="text" v-model="info.source" :class="{ 'is-invalid': errors.source }" class="form-control" placeholder="填写账号的来源">
                        </div>
                    </div>
                    <div class="mt-2">
                        <div class="input-group mt-3">
                            <input type="file" ref="images" @change="previewImages" multiple style="display: none;">
                            <button type="button" class="btn btn-success custom-button-rounded" @click="triggerFileInput">发布图片</button>
                        </div>
                        <div class="form-floating mt-2 preview-container">
                            <div class="preview-label-container">
                                <div class="preview-label">预览：</div>
                            </div>
                            <div id="previewContainer" class="preview-images" ref="previewContainer">
                                <div v-for="(url, index) in previewUrls" :key="index" class="image-wrapper">
                                    <div class="image-container" @mouseover="showIcons(index)" @mouseleave="hideIcons(index)">
                                        <img :src="url" class="preview-image" alt="">
                                        <div class="icon-container" :class="{ 'icon-visible': visibleIcons === index }">
                                            <i class="bi bi-eye-fill icon" @click="viewImage(index)"></i>
                                            <i class="bi bi-trash-fill icon" @click="deleteImage(index)"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="input-group mt-3 px-3 mb-3">
                        <button type="button" class="btn btn-success mx-auto" @click="release">确认发布</button>
                    </div>
                    <div style="color: red;">
                        <div class="jiacu">
                            现要求发帖必须要求发帖人<strong>手持写有“快发卡.com”的纸条</strong>拍照并上传才会过审，否则一律不予通过...
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- 公共底部 -->
    <ZhaiFootPC></ZhaiFootPC>
    <!-- 发帖需要升级任意等级VIP 模态框 -->
    <div class="modal fade" id="myModal6" tabindex="-1" aria-labelledby="vipModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style="max-width: 1000px;">
            <div class="modal-content" style="width: 100%;">
                <div class="modal-header">
                    <h4 class="modal-title" style="font-size: 1rem!important;" id="vipModalLabel">发帖需要升级任意等级VIP</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p class="px-2" style="color: red; text-align: left; margin-top: 10px;">免费用户无发帖权限！升级任意等级VIP即可免费发帖！</p>
                    <p class="px-2" style="text-align: left; margin-top: 10px; margin-bottom: 10px;">发帖要求：需要手持本人手持“kuaifaka.com”的照片（可以不露脸，但从服装、背景等需要能看出是同一人），只要手持，发帖就能通过！</p>
                </div>
                <div class="modal-footer text-center">
                    <button type="button" class="btn btn-primary mx-auto" data-bs-dismiss="modal" @click="redirectToUpgradeVIP">确定</button>
                </div>
            </div>
        </div>
    </div>



    <!--发帖重要提示  模态框 -->
    <div class="modal custom-modal" id="myModal7">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title lf">发帖重要提示</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div>必须<strong style="color:red;">手持写有“kuaifaka.com”的纸条</strong>拍照并上传...</div>
                    <div class="text-center">
                        <img src="../assets/手持.png" style="width: 80%" alt="..." />
                        <div class="text-center daxiao">(例图)</div>
                    </div>
                </div>
                <div class="modal-footer text-center">
                    <button type="button" class="btn btn-success mx-auto" data-bs-dismiss="modal">我已知晓</button>
                </div>
            </div>
        </div>
    </div>


    <!-- 错误提示模态框 -->
    <div class="modal fade" id="errorModal" tabindex="-1" aria-labelledby="errorModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style="max-width: 300px;">
            <div class="modal-content">
                <div class="modal-body d-flex align-items-center justify-content-center">
                    <i class="bi bi-emoji-frown" style="font-size: 2rem; color: #dc3545;"></i>
                    <p class="mb-0 ms-2" style="font-size: 1rem; color: #dc3545;">{{ errorMessage }}</p>
                </div>
            </div>
        </div>
    </div>

    <!-- 发布成功提示框 -->
    <div class="modal fade" id="successModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style="width: 400px;">
            <div class="modal-content">
                <div class="modal-body text-center d-flex align-items-center justify-content-center">
                    <i class="bi bi-emoji-smile" style="font-size: 2rem; color: #28a745;"></i>
                    <p class="mb-0 ms-2" style="font-size: 1rem; color: #28a745;">上传成功，如审核通过可在我的发布板块查看</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    // 导入所需的组件和库
    import ZhaiHeadPC from "../componentspc/ZhaiHeadPC"; // 导入PC端头部组件
    import ZhaiFootPC from "../componentspc/ZhaiFootPC"; // 导入PC端底部组件
    import ZhaiJiuGongPC from "../componentspc/ZhaiJiuGongPC"; // 导入PC端九宫格导航组件
    import ProvinceCitySelectorPC from "../componentspc/ProvinceCitySelectorPC"; // 导入PC端省市选择器组件
    import { Modal } from 'bootstrap'; // 从Bootstrap中导入Modal类，用于操作模态框
    import Viewer from 'viewerjs'; // 导入Viewer.js库，用于图片预览
    import 'viewerjs/dist/viewer.css'; // 导入Viewer.js的样式文件
    import http from "../utils/http.js"; // 导入自定义的HTTP请求工具
    import { mapGetters } from 'vuex'; // 从Vuex中导入mapGetters，用于获取Vuex中的状态

    export default {
        name: "PInfoPublish", // 组件名称
        components: {
            ZhaiHeadPC, // 注册PC端头部组件
            ZhaiFootPC, // 注册PC端底部组件
            ZhaiJiuGongPC, // 注册PC端九宫格导航组件
            ProvinceCitySelectorPC // 注册PC端省市选择器组件
        },
        data() {
            return {
                conditionOptions: [
                    { value: '安全可靠', label: '安全可靠' },
                    { value: '账号老练', label: '账号老练' },
                    { value: '活跃用户', label: '活跃用户' },
                    { value: '优质内容', label: '优质内容' },
                    { value: '安全可靠', label: '安全可靠' },
                    { value: '账号老练', label: '账号老练' },
                    { value: '活跃用户', label: '活跃用户' },
                    { value: '安全可靠', label: '安全可靠' },
                    { value: '账号老练', label: '账号老练' },
                    { value: '活跃用户', label: '活跃用户' }
                    // 可以根据实际需求添加更多选项
                ],
                // 用于存储表单输入信息的对象
                info: {
                    name: '', // 信息标题
                    account: '', // 账号
                    age: '', // 账号年龄
                    price: '', // 账号价格
                    condition: '', // 账号特点
                    history: '', // 详细介绍
                    level: '', // 账号等级
                    attention: '', // 注意事项
                    phone: '', // 联系电话
                    email: '', // 联系邮箱
                    source: '', // 账号来源
                    province: '', // 所属省份
                    city: '' // 所属城市
                },
                errors: {
                    // 用于存储各个输入字段的错误状态
                    name: false,
                    account: false,
                    age: false,
                    price: false,
                    condition: false,
                    history: false,
                    level: false,
                    attention: false,
                    phone: false,
                    email: false,
                    source: false,
                    province: false,
                    city: false
                },
                previewUrls: [], // 存储预览图片的URL数组
                visibleIcons: null, // 当前显示操作图标的图片索引
                viewer: null, // Viewer.js实例，用于图片预览
                errorMessage: "", // 错误提示消息内容
            };
        },
        computed: {
            ...mapGetters(['isLoggedIn', 'getUser']) // 使用Vuex的getters从状态管理中获取用户登录状态和用户信息
        },
        methods: {
            //发帖需要升级任意等级VIP模态框确定按钮点击跳转函数
            redirectToUpgradeVIP() {
                this.$router.push('/upgradeVIP');
            },
            // 触发文件选择框
            triggerFileInput() {
                this.$refs.images.click(); // 触发图片文件输入框的点击事件
            },
            // 更新省份信息
            provinceUpdated(province) {
                this.info.province = province; // 更新省份数据
            },
            // 更新城市信息
            cityUpdated(city) {
                this.info.city = city; // 更新城市数据
            },
            // 预览选中的图片，并进行图片大小和格式验证
            previewImages(event) {
                const files = event.target.files; // 获取选中的文件列表
                const maxImages = 4; // 限制最多上传4张图片
                const maxSize = 1024 * 1024 * 1; // 图片大小限制为1MB
                const allowedTypes = ["image/png", "image/jpeg", "image/bmp", "image/webp"]; // 允许的图片格式

                // 检查当前预览的图片数量是否超过限制
                if (this.previewUrls.length + files.length > maxImages) {
                    this.showErrorModal(`最多只能上传 ${maxImages} 张图片`);
                    return;
                }

                for (let i = 0; i < files.length; i++) {
                    const file = files[i];

                    // 检查图片大小
                    if (file.size > maxSize) {
                        this.showErrorModal(`图片 ${file.name} 大小不能超过1MB`);
                        return;
                    }

                    // 检查图片格式
                    if (!allowedTypes.includes(file.type)) {
                        this.showErrorModal(`图片 ${file.name} 格式不支持，仅支持 PNG, JPEG, BMP 和 WEBP 格式`);
                        return;
                    }

                    // 生成预览图片的URL并添加到previewUrls数组中
                    this.previewUrls.push(URL.createObjectURL(file));
                }

                // 更新Viewer.js实例
                this.$nextTick(() => {
                    this.viewer.update();
                });
            },
            // 显示操作图标
            showIcons(index) {
                this.visibleIcons = index; // 设置当前显示图标的图片索引
            },
            // 隐藏操作图标
            hideIcons() {
                this.visibleIcons = null; // 清空当前显示图标的图片索引
            },
            // 查看图片
            viewImage(index) {
                this.viewer.view(index); // 使用Viewer.js查看指定索引的图片
            },
            // 删除图片
            deleteImage(index) {
                this.previewUrls.splice(index, 1); // 从预览列表中删除指定索引的图片
                this.$nextTick(() => {
                    this.viewer.update(); // 更新Viewer.js实例
                });
            },
            // 发布信息，包含表单验证和图片上传
            release() {
                // 重置所有字段的错误状态
                this.errors = {};

                // 验证各个必填字段是否为空
                if (!this.info.name.trim()) {
                    this.showErrorModal("信息标题不能为空", 'name');
                    return;
                }
                if (!this.info.province.trim()) {
                    this.showErrorModal("省份不能为空", 'province');
                    return;
                }
                if (!this.info.city.trim()) {
                    this.showErrorModal("城市不能为空", 'city');
                    return;
                }
                if (!this.info.account.trim()) {
                    this.showErrorModal("账号不能为空", 'account');
                    return;
                }
                if (!this.info.age.trim()) {
                    this.showErrorModal("账号年龄不能为空", 'age');
                    return;
                }
                if (!this.info.price.trim()) {
                    this.showErrorModal("账号价格不能为空", 'price');
                    return;
                }
                if (!this.info.condition.trim()) {
                    this.showErrorModal("账号特点不能为空", 'condition');
                    return;
                }
                if (!this.info.history.trim()) {
                    this.showErrorModal("详细介绍不能为空", 'history');
                    return;
                }
                if (!this.info.level.trim()) {
                    this.showErrorModal("账号等级不能为空", 'level');
                    return;
                }
                if (!this.info.attention.trim()) {
                    this.showErrorModal("注意事项不能为空", 'attention');
                    return;
                }
                if (!this.info.phone.trim()) {
                    this.showErrorModal("电话不能为空", 'phone');
                    return;
                }
                if (!this.info.email.trim()) {
                    this.showErrorModal("邮箱不能为空", 'email');
                    return;
                }
                // 验证邮箱格式是否正确
                if (!this.validateEmail(this.info.email)) {
                    this.showErrorModal("邮箱格式有误", 'email');
                    return;
                }

                // 获取当前用户的ID
                const userId = this.getUser && this.getUser.id;

                // 检查用户是否登录
                if (!userId) {
                    this.showErrorModal("用户未登录");
                    return;
                }

                // 检查是否上传了至少一张图片
                const imageInput = this.$refs.images;
                if (!imageInput || imageInput.files.length === 0) {
                    this.showErrorModal("请上传至少一张图片", 'images');
                    return;
                }

                // 创建FormData对象并添加表单数据
                const formData = new FormData();
                formData.append('name', this.info.name);
                formData.append('account', this.info.account);
                formData.append('age', this.info.age);
                formData.append('price', this.info.price);
                formData.append('condition', this.info.condition);
                formData.append('history', this.info.history);
                formData.append('level', this.info.level);
                formData.append('attention', this.info.attention);
                formData.append('phone', this.info.phone);
                formData.append('email', this.info.email);
                formData.append('source', this.info.source);
                formData.append('province', this.info.province);
                formData.append('city', this.info.city);
                formData.append('userid', userId);

                // 添加图片文件到FormData对象
                if (imageInput && imageInput.files.length > 0) {
                    for (let i = 0; i < imageInput.files.length; i++) {
                        formData.append('images', imageInput.files[i]);
                    }
                }

                // 发送POST请求提交表单数据
                http.post('/platform/frontEnd/inforelease', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    if (response.data.status === 1) { // 假设 status === 1 表示成功
                        this.showSuccessModal();
                    } else {
                        this.showErrorModal(response.data.msg || '发布失败，请稍后再试。');
                    }
                }).catch(error => {
                    let errorMessage = '发布失败，请稍后再试。';
                    if (error.response && error.response.data && error.response.data.msg) {
                        errorMessage = error.response.data.msg; // 使用后端返回的错误信息
                    } else if (error.message) {
                        errorMessage = error.message;
                    }
                    this.showErrorModal(errorMessage);
                });
            },
            // 显示错误提示模态框
            showErrorModal(message, field) {
                this.errorMessage = message;
                if (field) {
                    this.errors[field] = true; // 标记相应字段为错误状态
                }
                const modalElement = document.getElementById('errorModal');
                const modal = new Modal(modalElement);
                modal.show();

                // 添加抖动效果
                const modalDialog = modalElement.querySelector('.modal-dialog');
                modalDialog.classList.add('shake');

                setTimeout(() => {
                    modalDialog.classList.remove('shake');
                    modal.hide();
                }, 3000);
            },
            // 显示发布成功提示模态框
            showSuccessModal() {
                const modalElement = document.getElementById('successModal');
                const modal = new Modal(modalElement);
                modal.show();

                setTimeout(() => {
                    modal.hide();
                    this.$router.push('/');
                }, 3000);
            },
            // 验证邮箱格式
            validateEmail(email) {
                const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // 正则表达式验证邮箱格式
                return re.test(email);
            },
            // 检查用户是否登录
            checkLoginStatus() {
                if (!this.isLoggedIn) {
                    this.$router.push('/login'); // 如果未登录，跳转到登录页
                }
            }
        },
        created() {
            // 组件创建时检查用户登录状态
            this.checkLoginStatus();
        },
        watch: {
            // 监听登录状态的变化
            isLoggedIn(val) {
                if (!val) {
                    this.$router.push('/login'); // 如果用户未登录，跳转到登录页
                }
            }
        },
        beforeRouteEnter(to, from, next) {
            // 路由进入前检查token
            const token = localStorage.getItem('token');
            if (!token) {
                next('/login'); // 如果没有token，跳转到登录页
            } else {
                next(); // 否则继续路由
            }
        },
        mounted() {
            // 组件挂载后初始化Viewer.js并显示发帖提示模态框
            this.$nextTick(() => {
                if (this.$refs.previewContainer) {
                    this.viewer = new Viewer(this.$refs.previewContainer, {
                        url: 'src'
                    });
                }

                // 获取用户的VIP到期时间
                const expireDate = this.getUser.level_expire_date ? new Date(this.getUser.level_expire_date) : null;
                const now = new Date();

                // 判断VIP是否过期或者为空
                if (!expireDate || expireDate < now) {
                    // 显示升级VIP的提示模态框
                    const modalElement = document.getElementById('myModal6');
                    const modal = new Modal(modalElement);
                    modal.show();
                } else {
                    // 显示发帖重要提示模态框
                    const modalElement = document.getElementById('myModal7');
                    const modal = new Modal(modalElement);
                    modal.show();
                }
            });
        }
    };
</script>


<style scoped>
    body {
        font-family: Arial, sans-serif;
    }

    .gongxi2 {
        height: 20px;
    }

    .errong {
        padding-left: 30px;
        padding-right: 30px;
    }

    .form-control {
        font-size: 0.8rem !important;
    }

    .btn-success {
        background-color: #00c26f !important;
        border: none !important;
        font-size: 0.9rem !important;
        border-radius: 10px;
        padding: 7px !important;
        font-size: 0.9rem;
    }

    .custom-button {
        border-radius: 10px;
        margin-left: 0;
    }

    .di {
        display: block !important;
    }

    .custom-modal .modal-dialog {
        width: 35%;
        margin: auto;
        margin-top: 2rem !important;
    }

    /* 错误提示模态框 */
    .modal-content {
        padding: 5px; /* 减小内边距 */
    }

    .modal-header,
    .modal-body,
    .modal-footer {
        padding: 10px;
    }

    .modal-footer {
        display: flex;
        justify-content: center;
    }

    .zida {
        font-size: 0.9rem;
    }

    .huiziti2 {
        font-size: 0.8rem;
    }

    .preview-container {
        border: 1px solid #d3d3d3;
        padding: 10px;
        position: relative;
        min-height: 100px;
    }

    .preview-label-container {
        display: flex;
        align-items: flex-start;
    }

    .preview-label {
        font-size: 1rem;
        font-weight: normal;
        padding: 0 5px;
        margin-right: 10px;
        align-self: flex-start;
        color: #4e5b6b;
    }

    .preview-images {
        display: flex;
        flex-wrap: wrap;
    }

    .preview-image {
        width: 100%;
        object-fit: cover;
    }

    .image-wrapper {
        flex-basis: 15%;
        max-width: 15%;
        margin-right: 10px;
        margin-bottom: 10px;
        display: flex;
        justify-content: flex-start;
    }

    .image-container {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .icon-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        gap: 10px;
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    .icon-visible {
        opacity: 1;
    }

    .icon {
        font-size: 30px;
        color: white;
        cursor: pointer;
    }

    @media (min-width: 768px) {
        .zhai-head {
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 1000;
        }

        .errong {
            margin-top: 90px;
        }
    }

    .is-invalid {
        border-color: red !important;
        background-image: none !important;
        box-shadow: none !important;
    }

    .custom-button-rounded {
        border-radius: 2px !important;
    }
    .btn-success.mx-auto {
        border-radius: 2px !important;
    }
    .modal-body {
        padding: 0px !important; /* 将模态框内边距设置为0px，并使用 !important 覆盖原有样式 */
    }
    @keyframes shake {
        0%, 100% { transform: translateX(0); }
        25% { transform: translateX(-5px); }
        50% { transform: translateX(5px); }
        75% { transform: translateX(-5px); }
    }

    .shake {
        animation: shake 0.5s ease-in-out;
    }
    .select-with-arrow select {
        color: #495057!important; /* 将字体颜色设置为与其他输入框相同的颜色 */
        font-size: 1rem; /* 增大字体 */
        padding: 8px; /* 调整内边距 */
    }

    .select-with-arrow {
        position: relative;
    }

    .select-with-arrow select {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
    }

    .select-with-arrow::after {
        content: '\25BC'; /* Unicode字符表示下箭头 */
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        font-size: 12px;
        color: #495057;
    }
</style>
