<template>
    <!--导航栏-->
    <ZhaiHead></ZhaiHead>
    <!--导航栏-->

    <!--展示文字-->
    <div class="container-fluid zhu">
        <div class="facai">恭喜发财</div>
    </div>
    <!--展示文字-->

    <!--主体内容-->
    <div class="container-fluid shengse">
        <div class="row mt-2">
            <div class="dixian px-4 py-2 d-flex justify-content-between">
                <div><i class="bi bi-person-add"></i>用户登录</div>
            </div>
        </div>
        <div class="px-4 mt-3">
            <div class="mb-3">
                <input
                        type="email"
                        class="form-control custom-input"
                        :class="{'is-invalid': errors.username}"
                        id="exampleFormControlInput1"
                        v-model="myform.username"
                        placeholder="请输入用户名">
            </div>
            <div class="mb-3">
                <input
                        type="password"
                        class="form-control custom-input"
                        :class="{'is-invalid': errors.password}"
                        id="exampleFormControlInput2"
                        v-model="myform.password"
                        placeholder="请输入密码">
            </div>
            <CaptchaComponent
                    @validate-captcha="validateCaptcha"
                    ref="captchaComponent"
            ></CaptchaComponent>
            <div class="col-12 mt-4 text-center">
                <button type="button" @click="reg()" class="btn btn-success w-100 z-f-sm">登录</button>
            </div>
            <div class="san text-center mt-3">
                <span><router-link class="changlv no-underline" to="/register">注册</router-link></span>
                <span class="lian"><router-link class="changlv no-underline lian" to="/retrieveUserName">找回用户名</router-link></span>
                <span class="lian"><router-link class="changlv no-underline lian" to="/resetPasswords">重置密码（VIP）</router-link></span>
            </div>
            <div class="zhan"></div>
        </div>
    </div>
    <!--主体内容-->

    <!--只是占据空间-->
    <div class="gongxi2"></div>
    <!--顶部占据空间-->

    <!--公共底部-->
    <ZhaiFoot></ZhaiFoot>
    <!--公共底部-->

    <!-- 错误信息模态框 -->
    <div class="modal fade" id="errorModal" tabindex="-1" aria-labelledby="errorModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered small-modal" >
            <div class="modal-content">
                <div class="modal-body d-flex align-items-center justify-content-center small-padding">
                    <i class="bi bi-emoji-frown small-icon"></i>
                    <p class="mb-0 ms-2 small-text">{{ errorMessage }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import http from "../utils/http.js";
    import ZhaiHead from "../components/ZhaiHead";
    import ZhaiFoot from "../components/ZhaiFoot";
    import { Modal } from 'bootstrap';
    import CaptchaComponent from "../componentspc/CaptchaComponent.vue"; // 引入验证码组件

    export default {
        name: "MLogin",
        components: { ZhaiHead, ZhaiFoot, CaptchaComponent },
        data() {
            return {
                myform: {
                    username: "",
                    password: ""
                },
                errorMessage: "",
                errors: {
                    username: false,
                    password: false
                },
                captchaValid: false
            };
        },
        methods: {
            validateCaptcha(isValid) {
                this.captchaValid = isValid;
                this.$refs.captchaComponent.captchaError = !isValid;
            },
            reg() {
                this.errors.username = !this.myform.username.trim();
                this.errors.password = !this.myform.password.trim();

                if (this.errors.username) {
                    this.showErrorModal("用户名不可为空");
                    return;
                }
                if (this.errors.password) {
                    this.showErrorModal("密码不可为空");
                    return;
                }

                if (!this.$refs.captchaComponent.enteredCaptcha.trim()) {
                    this.showErrorModal("验证码不能为空");
                    this.$refs.captchaComponent.captchaError = true;
                    return;
                }
                this.validateCaptcha(this.$refs.captchaComponent.enteredCaptcha.trim() === this.$refs.captchaComponent.captchaText);

                if (!this.captchaValid) {
                    this.showErrorModal("图形验证码输入不正确！");
                    return;
                }

                let url = "/platform/frontEnd/login";
                let fm = new FormData();
                fm.append("username", this.myform.username);
                fm.append("password", this.myform.password);

                http.post(url, fm, { untoken: true }).then(response => {
                    if (response.data.status === 1 && response.data.data) {
                        const data = response.data.data;
                        const userData = {
                            user: {
                                id: data.id,
                                username: data.username,
                                email: data.email,
                                level: data.level,
                                level_expire_date: data.level_expire_date
                            },
                            token: data.token
                        };

                        if (!userData.user || !userData.token) {
                            this.showErrorModal("登录失败，用户或令牌无效");
                            return;
                        }

                        this.$store.dispatch('login', userData);
                        localStorage.setItem('authToken', userData.token);
                        localStorage.setItem('user', JSON.stringify(userData.user));
                        this.$router.push('/memberCenter');
                    } else {
                        this.showErrorModal(response.data.msg || "登录失败！");
                    }
                }).catch(error => {
                    this.showErrorModal("请求失败，请稍后重试。");
                });
            },
            showErrorModal(message) {
                this.errorMessage = message;
                const modalElement = document.getElementById('errorModal');
                const modal = new Modal(modalElement);
                modal.show();

                setTimeout(() => {
                    modalElement.querySelector('.modal-content').classList.add('shake');
                    setTimeout(() => {
                        modalElement.querySelector('.modal-content').classList.remove('shake');
                    }, 1000);
                }, 0);

                setTimeout(() => {
                    modal.hide();
                }, 3000);
            },
            handleEnterKey(event) {
                if (event.key === 'Enter') {
                    this.reg();
                }
            }
        },
        mounted() {
            window.addEventListener('keyup', this.handleEnterKey);
        },
        beforeUnmount() {
            window.removeEventListener('keyup', this.handleEnterKey);
        }
    }
</script>

<style scoped>
    /*恭喜发财的样式*/
    .facai {
        font-size: 20px;
        color: lightslategrey;
        text-align: center;
    }

    /*随机生成文字框的样式*/
    .br5 {
        border-radius: 5px;
        border: 1px solid #f2f2f2;
    }

    /*底部不占据空间*/
    .gongxi2 {
        height: 20px;
    }
    /*下方占据空间*/
    .zhan {
        height: 30px;
    }
    /*登录按钮下面的三个选项链接的间距*/
    .lian {
        margin-left:5px;
    }
    /*按钮颜色字体大小*/
    .btn-success {
        background-color: #00c26f !important;
        font-size: 0.9rem;
        border: none!important;
    }
    /*输入框内和提示文字的大小*/
    .form-control {
        font-size: 0.9rem;
    }
    /* 调整验证码图片与输入框对齐 */
    .d-flex.align-items-center {
        display: flex;
        align-items: center;
    }
    .ml-3 {
        margin-left: 1rem;
    }
    .zhu {
        margin-top: 85px;
        margin-bottom: 10px;
    }
    /* 垂直居中对齐 */
    .san {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        white-space: nowrap;
    }
    .changlv {
        color: #18b371 !important;
        font-size: 1rem;
        text-decoration: none;
    }
    .no-underline:hover {
        text-decoration: underline;
    }

    /* 自定义输入框样式 */
    .custom-input {
        border-radius: 2px !important;
        line-height: 1.5 !important;
    }

    /* 添加模态框抖动效果 */
    @keyframes shake {
        0%, 100% { transform: translateX(0); }
        10%, 30%, 50%, 70%, 90% { transform: translateX(-2px); }
        20%, 40%, 60%, 80% { transform: translateX(2px); }
    }
    .modal-content.shake {
        animation: shake 0.5s;
    }

    /* 调整模态框和文本的大小 */
    .small-modal .modal-content {
        width: 100%;
        max-width: 250px;
        margin: 0 auto;
    }
    .small-padding {
        padding: 0px !important;
    }
    .small-icon {
        font-size: 1.5rem;
        color: #dc3545;
    }
    .small-text {
        font-size: 0.9rem;
        color: #dc3545;
    }

    /* 添加输入框错误状态样式 */
    .is-invalid {
        border-color: #dc3545 !important;
        background-image: none !important; /* 移除叹号图标 */
    }
</style>
