<template>
    <div class="bg-white my-2 p-3 G-Field " style="text-align:center;">
        <nav aria-label="Page navigation example">
            <ul class="pagination">
                <li class="page-item">
                    <router-lin class="page-link" href="#" aria-label="Previous">
                        <span aria-hidden="true">&laquo;</span>
                    </router-lin>
                </li>
                <li class="page-item"><router-link class="page-link" to="#" exact-active-class="active-page">1</router-link></li>
                <li class="page-item"><router-link class="page-link" to="#">2</router-link></li>
                <li class="page-item"><router-link class="page-link" to="#">3</router-link></li>
                <li class="page-item"><router-link class="page-link" to="#">...</router-link></li>
                <li class="page-item"><router-link class="page-link" to="#">4528</router-link></li>
                <li class="page-item">
                    <router-lin class="page-link" href="#" aria-label="Next">
                        <span aria-hidden="true">&raquo;</span>
                    </router-lin>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script>
    export default {
        name: "ZhaiPaging"
    }
</script>

<style scoped>
    /* 将分页组件的父容器设置为 Flex 容器并居中内容 */
    .G-Field {
        display: flex;
        justify-content: center;
        align-items: center; /* 新增的对齐项：上下居中对齐 */
        height: 70px; /* 设定高度，使居中效果明显 */
    }

    /* 分页框的的颜色 */
    .page-link {
        color: #6c757d; /* 这里是你想要的较淡的颜色，你可以根据需要调整 */
    }

    /* 分页框的样式 */
    .pagination {
        line-height: 0.6; /* 减少行高，可以根据需要进一步调整 */
        border-color: #dee2e6; /* 这里是你想要的较淡的边框颜色，你可以根据需要调整 */
    }

    /* 为分页框设置外上边距 */
    ul {
        margin-top: 1rem !important;
    }

    /* 分页被选中的页码背景颜色 */
    .page-item .page-link.active-page {
        background-color: #212429 !important;
        color: white !important; /* 设置文本颜色，以确保可读性 */
    }
</style>