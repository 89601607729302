<template>
    <!--回家的路-->
    <!--导航栏-->
    <ZhaiHead></ZhaiHead>
    <!--导航栏-->
    <!--九宫格-->
    <div class="container-fluid zhu  mb-2">
        <ZhaiJiuGong menu="8"></ZhaiJiuGong>
    </div>
    <!--九宫格-->
    <!--主体-->
    <div class="container-fluid shengse">
        <div class="row shengse">
            <div class="dixian px-4 py-2 d-flex justify-content-between">
                <div class="lf">回家的路</div>
            </div>
            <div class="p-3 gohome">
                <h1 style="text-align: center;">！！！请将本页截图保存！！！</h1>
                <h1>1.永久地址：快发卡.com、卡卡.com</h1>
                <p>提供两个永久中文域名，直接从浏览器地址栏输入中文域名即可！</p>
                <h1>2.IOS客户端（适用于苹果手机） </h1>
                <p><a href="/ios/ios.html">IOS轻量版客户端</a></p>
                <h1>3.导航找回</h1>
                <p>wwwone4.com，wwwone5.com，wwwone6.com，the.rrrone.com，the.mmmone.com，学数学.com，学语文.com</p>
                <h1>4.邮箱找回</h1>
                <p>发送任意邮件给：404489561.com，自动回复最新地址。</p>
            </div>

        </div>
    </div>
    <!--主体-->
    <!--公共底部-->
    <ZhaiFoot></ZhaiFoot>
    <!--公共底部-->

</template>

<script>
    import ZhaiHead from "../components/ZhaiHead";
    import ZhaiFoot from "../components/ZhaiFoot";
    import ZhaiJiuGong from "../components/ZhaiJiuGong";
    import ZhaiCard from "../components/ZhaiCard";
    export default {
        name: "MMyFavorite",
        components:{  ZhaiHead, ZhaiFoot,ZhaiJiuGong,ZhaiCard}
    }
</script>

<style scoped>
    /*文本的样式*/
    .gohome h1 {
        font-size: 1.5rem;
        margin-top: 2rem;
        color: #00c26f;
        padding-left: 15px;
        padding-right: 15px;
    }
    .gohome p {
        font-size: 1rem;
        padding: 1rem 0;
        color: #000;
        line-height: 2rem;
        padding-left: 15px;
        padding-right: 15px;
    }
    .zhu{
        margin-top: 80px;
    }


</style>