<template>
    <!--防骗指南-->
    <!--导航栏-->
    <ZhaiHead></ZhaiHead>
    <!--导航栏-->
    <!--主体-->
    <div class="container-fluid zhu px-0">
        <div class="row shengse">
            <div class="dixian px-4 py-2 d-flex justify-content-between">
                <div>防骗指南</div>
            </div>
            <div class="p-3 gohome">
                <h1>为了让广大用户避免上当受骗，大家做到以下几点，基本可以有效预防。</h1>
                <h1>防骗第一条：凡是有二次消费的，皆不是本站产品！</h1>
                <h1>防骗第二条：使用之前先通过下方说明查询该账号</h1>
                <p style="color: #00c26f;">本站为VIP用户提供了 <a href="/contactsearch.php">账号查询</a> 功能，通过联系方式查询，可以查到与该账号或文章匹配的所有帖子，以及用户反馈。可以有效避坑、防骗。</p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
            </div>

        </div>
    </div>
    <!--主体-->
    <!--公共底部-->
    <ZhaiFoot></ZhaiFoot>
    <!--公共底部-->

</template>

<script>
    import ZhaiHead from "../components/ZhaiHead";
    import ZhaiFoot from "../components/ZhaiFoot";

    export default {
        name: "MMyFavorite",
        components:{ ZhaiHead, ZhaiFoot}
    }
</script>

<style scoped>
    /*顶部占据空间*/
    .gongxi{
        height: 90px;
    }
    /*文本的样式*/
    .gohome h1 {
        font-size: 1.5rem;
        margin-top: 2rem;
        color: #00c26f;
        padding-left: 15px;
        padding-right: 15px;
    }
    .gohome p {
        font-size: 1rem;
        padding: 1rem 0;
        color: #000;
        line-height: 2rem;
        padding-left: 15px;
        padding-right: 15px;
    }
    .zhu{
        margin-top: 80px;
    }


</style>