<template>
    <router-view/>  <!-- 用于显示路由匹配的组件，在多页面应用中通过路由控制组件的显示 -->
</template>

<script>
    export default {
        name: 'App', // 组件名称，便于调试和开发时的识别
        created() {
            // 组件创建时立即检查登录状态
            this.checkLoginStatus();

            // 设置一个定时器，每隔60秒调用 `checkLoginStatus` 方法，定时检查用户登录状态
            setInterval(() => {
                console.log('setInterval', setInterval);
                this.checkLoginStatus();
            }, 60000);
        },
        methods: {
            /**
             * 检查用户的登录状态
             * 调用 Vuex 的 `checkLoginStatus` action 进行检查
             * 如果用户未登录，则根据当前页面的路径决定是否需要跳转到登录页面
             */
            checkLoginStatus() {
                this.$store.dispatch('checkLoginStatus').then(() => {
                    // 检查 Vuex store 中的 `isLoggedIn` getter，以判断用户是否已登录
                    if (!this.$store.getters.isLoggedIn) {
                        // 打印当前路由信息以便调试
                        console.log('当前路由路径:', this.$route.path);
                        console.log('当前路由名称:', this.$route.name);

                        // 定义一个数组，包含无需跳转登录的页面路径
                        const noJumpLoginPages = [
                            '/login',
                            '/',
                            '/details',
                            '/register',
                            '/resetPasswords',
                            '/retrieveUserName',
                            '/SearchS',
                            '/resetPasswords'
                        ];

                        // 获取当前路径的第一部分，以处理路径包含动态参数的情况，例如 '/details/:id'
                        const currentPath = this.$route.path.split('/')[1];
                        // 从 `noJumpLoginPages` 中提取允许的路径的第一部分，用于比较
                        const allowedPaths = noJumpLoginPages.map(path => path.split('/')[1]);

                        // 检查当前路径或路由名称是否包含在 `noJumpLoginPages` 中
                        if (allowedPaths.includes(currentPath) || this.$route.name === 'detailPage') {
                            console.log('在允许的页面，无需重定向');  // 如果在允许的页面，则不进行跳转
                        } else {
                            console.log('重定向到登录页面');  // 如果不在允许的页面，跳转到登录页面
                            this.$router.push('/login');
                        }
                    }
                });
            }
        }
    }
</script>

<style lang="scss">
    /* 字体大小设置为12px */
    .z-f-sm {
        font-size: 12px;
    }

    /* 整体背景色 */
    .beijing {
        background-color: #f2f2f2;
    }

    /* 绿色字体 */
    .changlv {
        color: #18b371;
    }

    /* 通用字体颜色 */
    .zitiyanse {
        color: #333333;
    }

    /* 导航栏背景色 */
    .zhai-navColour {
        background-color: #212429;
        width: 100%;
    }

    /* 导航栏左侧按钮背景色 */
    .zhai-col {
        background-color: #f2f2f2 !important;
    }

    /* 右上角头像尺寸 */
    .you {
        height: 32px;
        width: 35px;
    }

    /* 导航栏链接字体颜色设置为白色 */
    .nav-link {
        color: white !important;
    }

    /* 搜索按钮边框颜色设置为白色 */
    .zhai-btn-outline-success {
        color: white;
        border-color: white;
    }

    /* 底部黑色背景 */
    .dibu {
        background-color: #2b2b35;
    }

    /* 实时人数和时间的绿色条 */
    .lvtiao {
        color: #01ad65;
        padding-top: 15px;
        padding-bottom: 15px;
    }

    /* 选中状态的颜色 */
    .active {
        color: #18b371;
    }

    /* 卡片正文多行文本省略号 */
    .card-text {
        display: -webkit-box;  /* 使用 flexbox 容器 */
        -webkit-box-orient: vertical;  /* 设置垂直方向 */
        -webkit-line-clamp: 2;  /* 显示2行，超出部分隐藏 */
        overflow: hidden;  /* 隐藏超出部分 */
        text-overflow: ellipsis;  /* 显示省略号 */
    }

    /* 卡片日期颜色 */
    .ri {
        color: #989898;
    }

    /* 背景为白色 */
    .shengse {
        background-color: #fff;
    }

    /* 小字字体 */
    .ziti {
        font-style: normal;
        font-size: 0.9rem !important;
    }

    /* 加粗字体 */
    .jiacu {
        font-weight: bold;
    }

    /* 去除下划线 */
    .no-underline {
        text-decoration: none;
    }

    /* 灰色底线 */
    .dixian {
        border-bottom: 1px solid #f2f2f2 !important;
    }

    /* 会员中心页的小字体 */
    .huiziti {
        font-size: 0.9rem;
    }

    /* 卡片的主要字体大小 */
    .daxiao {
        font-size: 0.8rem;
    }

    /* 详情页日期字体大小 */
    .zitipc {
        font-style: normal;
        font-size: 0.6rem;
    }

    /* PC端框标题字体大小 */
    .lf {
        font-size: 1rem !important;
    }

</style>
