<template>
    <div class="container-fluid mt-3">
        <div class="row dage shengse">
            <router-link class="no-underline col" to="/memberCenter" :class="menu=='1'?'col ge g-active':'col ge'">
                <div class="Icon">
                    <i class="bi bi-person"></i>
                </div>
                <div class="Title">
                    会员中心
                </div>
            </router-link>
            <router-link class="no-underline col" to="/infoPublish" :class="menu=='2'?'col ge2 g-active':'col ge2'">
                <div class="Icon">
                    <i class="bi bi-plus-circle-fill"></i>
                </div>
                <div class="Title">
                    信息发布
                </div>
            </router-link>
            <router-link class="no-underline col" to="/myRelease" :class="menu=='3'?'col ge3 g-active':'col ge3'">
                <div class="Icon">
                    <i class="bi bi-list-ul"></i>
                </div>
                <div class="Title">
                    我的发布
                </div>
            </router-link>
            <router-link class="no-underline col" to="/myFavorite" :class="menu=='4'?'col ge1 g-active':'col ge1'">
                <div class="Icon">
                    <i class="bi bi-collection-fill"></i>
                </div>
                <div class="Title">
                    我的收藏
                </div>
            </router-link>
            <router-link class="no-underline col" to="/upgradeVIP" :class="menu=='5'?'col ge22 g-active':'col ge22'">
                <div class="Icon">
                    <i class="bi bi-arrow-90deg-up"></i>
                </div>
                <div class="Title">
                    升级VIP
                </div>
            </router-link>
            <router-link class="no-underline col" to="/modifiedData" :class="menu=='6'?'col ge33 g-active':'col ge33'">
                <div class="Icon">
                    <i class="bi bi-pencil-square"></i>
                </div>
                <div class="Title">
                    修改资料
                </div>
            </router-link>
            <router-link class="no-underline col" to="/queryAccount" :class="menu=='7'?'col ge1 g-active':'col ge1'">
                <div class="Icon">
                    <i class="bi bi-search"></i>
                </div>
                <div class="Title">
                    联系方式查询
                </div>
            </router-link>
            <router-link class="no-underline col" to="/wayHome" :class="menu=='8'?'col ge22 g-active':'col ge22'">
                <div class="Icon">
                    <i class="bi bi-house"></i>
                </div>
                <div class="Title">
                    回家的路
                </div>
            </router-link>
            <router-link class="no-underline col" to="">
                <div class="Icon">
                    <i class=""></i>
                </div>
                <div class="Title">

                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ZhaiJiuGongPC",
        props: ["menu"]
    }
</script>

<style scoped>
    /* 每一行 */
    .dage {
        display: flex;
        flex-wrap: nowrap; /* 防止换行 */
        justify-content: space-between; /* 项目之间的间隔 */
        border-bottom: none; /* 移除底部边框 */
    }

    /* 每列 */
    .col {
        flex: 1; /* 使列平均分配空间 */
        text-align: center;
        padding: 5px 0; /* 减少内边距以减少行高 */
        color: #262626;
    }

    /* 去除下划线 */
    .no-underline {
        text-decoration: none;
    }

    /* 图标 */
    .Icon {
        font-size: 0.9rem; /* 调整图标大小 */
        margin-bottom: 2px; /* 减少图标与标题之间的间距 */
        color: black; /* 设置图标颜色为黑色 */
    }

    /* 标题 */
    .Title {
        font-size: 0.95rem; /* 调整标题大小 */
        color: black; /* 设置标题颜色为黑色 */
    }

    /* 边框设置 */
    .dage > .col:not(:last-child) {
        border-right: 1px solid #ddd;
    }

    /* 激活状态 */
    .g-active {
        background-color: #00c26f; /* 设置你想要的绿色 */
        color: white;
    }

    /* 自定义颜色 */
    .g-active .Icon,
    .g-active .Title {
        color: white;
    }
</style>
