<template>
    <ZhaiHeadPC class="zhai-head"></ZhaiHeadPC>
    <!-- 包含了头部组件 -->

    <div class="container errong phome-container-box">
        <div class="col-12">
            <ZhaiThreeTuttonsPC></ZhaiThreeTuttonsPC>
        </div>
        <!-- 包含了三个按钮组件 -->

        <div class="row mt-2">
            <div class="col-12 bg-white text-black p-3 tn mb-2 brl d-flex align-items-start justify-content-between">
                <div class="province-label mr-2 daxiao jiacu">省份:</div>
                <!-- 省份标签 -->

                <div class="province-links d-flex flex-wrap daxiao">
                    <a
                            v-for="province in provinces"
                            :key="province.name"
                            class="text-decoration-none mx-2 text-black"
                            :class="{ active: selectedProvince.name === province.name }"
                            @click.prevent="selectProvince(province)"
                            href="#"
                    >
                        <span>{{ province.name }}</span>
                    </a>
                    <!-- 动态生成的省份链接，选中的省份会添加active类 -->
                </div>
            </div>
        </div>

        <div v-if="showCities" class="row mt-2">
            <div class="col-12 bg-white text-black p-3 tn mb-2 brl d-flex align-items-start justify-content-between">
                <div class="province-label mr-2 daxiao jiacu">地市:</div>
                <!-- 地市标签 -->

                <div class="province-links d-flex flex-wrap daxiao">
                    <router-link
                            v-for="city in selectedProvince.cities"
                            :key="city"
                            class="text-decoration-none mx-2 text-black"
                            :class="{ active: selectedCity === city }"
                            @click.prevent="selectCity(city)"
                            to="#">
                        <span>{{ city }}</span>
                    </router-link>
                    <!-- 动态生成的地市链接，选中的地市会添加active类 -->
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 bg-white text-black p-3 tn float-left mb-2 brl d-flex align-items-start justify-content-between">
                <div class="province-label mr-2 daxiao jiacu">特点:</div>
                <!-- 特点标签 -->

                <div class="province-links d-flex flex-wrap daxiao">
                    <a
                            class="text-decoration-none mx-2 text-black"
                            v-for="feature in features"
                            :key="feature"
                            :class="{ active: selectedFeature === feature }"
                            @click.prevent="selectFeature(feature)"
                            href="#">
                        <span>{{ feature }}</span>
                    </a>
                    <!-- 动态生成的特点链接，选中的特点会添加active类 -->
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 bg-white text-black p-3 tn float-left mb-3 brl d-flex align-items-start justify-content-between">
                <div class="province-label mr-2 daxiao jiacu">排序:</div>
                <!-- 排序标签 -->

                <div class="province-links d-flex flex-wrap daxiao">
                    <a
                            class="text-decoration-none mx-2 text-black"
                            :class="{ active: sortBy === 'publishTime' }"
                            href="#"
                            @click.prevent="changeSort('publishTime')">
                        <span>发布时间</span>
                    </a>
                    <a
                            class="text-decoration-none mx-2 text-black"
                            :class="{ active: sortBy === 'collection' }"
                            href="#"
                            @click.prevent="changeSort('collection')">
                        <span>收藏数量</span>
                    </a>
                    <!-- 两个排序链接，选中的排序方式会添加active类 -->
                </div>
            </div>
        </div>
    </div>

    <div class="container errong2 phome-container-box">
        <div class="row card-container">
            <div class="col-md-4 mb-1" v-for="item in items" :key="item.id">
                <ZhaiCardPC :data="item"></ZhaiCardPC>
                <!-- 动态生成的卡片组件 -->
            </div>
        </div>
        <div class="bg-white my-2 p-3 G-Field" style="text-align:center;">
            <nav aria-label="Page navigation example">
                <ul class="pagination">
                    <li class="page-item" :class="{ disabled: currentPage === 1 }">
                        <a class="page-link" href="#" aria-label="Previous" @click.prevent="fetchPage(currentPage - 1)">
                            <span aria-hidden="true">&laquo;</span>
                        </a>
                    </li>
                    <li class="page-item" v-for="page in paginationPages" :key="page" :class="{ active: currentPage === page }">
                        <a class="page-link" href="#" @click.prevent="fetchPage(page)">{{ page }}</a>
                    </li>
                    <li class="page-item" :class="{ disabled: currentPage === totalPages }">
                        <a class="page-link" href="#" aria-label="Next" @click.prevent="fetchPage(currentPage + 1)">
                            <span aria-hidden="true">&raquo;</span>
                        </a>
                    </li>
                    <!-- 分页导航 -->
                </ul>
            </nav>
        </div>
    </div>

    <div class="gongxi2"></div>
    <!-- 一个空的div，用于调整页面布局 -->

    <ZhaiFootPC></ZhaiFootPC>
    <!-- 包含了底部组件 -->
</template>

<script>
    import http from "../utils/http.js";
    import ZhaiHeadPC from "../componentspc/ZhaiHeadPC";
    import ZhaiFootPC from "../componentspc/ZhaiFootPC";
    import ZhaiCardPC from "../componentspc/ZhaiCardPC";
    import ZhaiThreeTuttonsPC from "../componentspc/ZhaiThreeTuttonsPC";

    export default {
        name: "PHome",
        components: {
            ZhaiHeadPC,
            ZhaiFootPC,
            ZhaiCardPC,
            ZhaiThreeTuttonsPC
        },
        data() {
            return {
                items: [],
                currentPage: 1,
                totalPages: 1,
                sortBy: 'publishTime',
                selectedProvince: { name: '全部', cities: [] },
                selectedCity: '全部',
                selectedFeature: '不限',
                provinces: this.getProvinces(),
                features: ['不限', '高发言', '高回帖', '无封禁', '抢楼'],
                showCities: false,
                provinceKeyword: '',  // 省份关键词
                cityKeyword: '',      // 城市关键词
                featureKeyword: '',   // 特点关键词
                sortKeyword: '发布时间' // 默认排序关键词
            };
        },
        mounted() {
            this.fetchPage(1);
        },
        computed: {
            paginationPages() {
                let pages = [];
                let startPage = Math.max(1, this.currentPage - 2);
                let endPage = Math.min(this.totalPages, this.currentPage + 2);

                for (let i = startPage; i <= endPage; i++) {
                    pages.push(i);
                }

                if (startPage > 1) {
                    pages.unshift('...');
                    pages.unshift(1);
                }

                if (endPage < this.totalPages) {
                    pages.push('...');
                    pages.push(this.totalPages);
                }

                return pages;
            }
        },
        methods: {
            // 更新页面标题
            updateTitle() {
                const titleParts = [
                    this.provinceKeyword,
                    this.cityKeyword,
                    this.featureKeyword
                ].filter(Boolean); // 移除空字符串

                // 如果有关键词，则格式为“关键词1 关键词2 关键词3 的全部信息 - 港湾”
                // 否则，标题设置为“港湾”
                document.title = titleParts.length ? `${titleParts.join(' ')}的全部信息 - 港湾` : '港湾';
            },

            changeSort(sortOption) {
                this.sortBy = sortOption;
                this.sortKeyword = sortOption === 'publishTime' ? '发布时间' : '收藏数量'; // 更新排序关键词
                this.updateTitle(); // 更新标题
                this.fetchPage(1);
            },

            fetchPage(page) {
                if (page === '...' || page < 1 || page > this.totalPages) {
                    return;
                }

                const params = new URLSearchParams({
                    pageNum: page || 1,
                    pageSize: 24,
                    sortBy: this.sortBy
                });

                if (this.selectedProvince.name !== '全部') {
                    params.append('province', this.selectedProvince.name);
                }
                if (this.selectedCity !== '' && this.selectedCity !== '全部') {
                    params.append('city', this.selectedCity);
                }
                if (this.selectedFeature !== '不限') {
                    params.append('high', this.selectedFeature);
                }

                const url = `/platform/frontEnd/unifiedList?${params.toString()}`;

                http.get(url).then(response => {
                    if (response.data.status === 1) {
                        this.items = response.data.data.list || [];
                        this.currentPage = response.data.data.pageNum || 1;
                        this.totalPages = response.data.data.pages || 1;
                    } else {
                        alert(response.data.msg);
                    }
                }).catch(error => {
                    console.error("请求失败:", error);
                    alert("请求失败，请稍后重试。");
                });
            },

            selectProvince(province) {
                this.selectedProvince = province;
                this.provinceKeyword = province.name !== '全部' ? province.name : ''; // 更新省份关键词
                this.selectedCity = '全部'; // 重置城市选择
                this.cityKeyword = ''; // 清除城市关键词
                this.showCities = province.name !== '全部';
                this.updateTitle(); // 更新标题
                this.fetchPage(1);
            },

            selectCity(city) {
                this.selectedCity = city;
                this.cityKeyword = city !== '全部' ? city : ''; // 更新城市关键词
                this.updateTitle(); // 更新标题
                this.fetchPage(1);
            },

            selectFeature(feature) {
                this.selectedFeature = feature;
                this.featureKeyword = feature !== '不限' ? feature : ''; // 更新特点关键词
                this.updateTitle(); // 更新标题
                this.fetchPage(1);
            },

            getProvinces() {
                return [
                    { name: '全部', cities: [] },
                    { name: '北京', cities: ['全部', '海淀', '丰台', '门头沟', '昌平', '房山', '大兴', '朝阳', '东城', '石景山', '西城', '通州', '顺义', '怀柔', '平谷', '密云', '延庆'] },
                    { name: '上海', cities: ['全部', '黄浦', '徐汇', '长宁', '静安', '普陀', '虹口', '杨浦', '闵行', '宝山', '嘉定', '浦东', '金山', '松江', '青浦', '奉贤', '崇明'] },
                    { name: '天津', cities: ['全部', '和平', '河东', '河西', '南开', '河北', '红桥', '东丽', '西青', '津南', '北辰', '武清', '宝坻', '滨海新区', '宁河', '静海', '蓟州'] },
                    { name: '重庆', cities: ['全部', '万州', '涪陵', '渝中', '大渡口', '江北', '沙坪坝', '九龙坡', '南岸', '北碚', '綦江', '大足', '渝北', '巴南', '黔江', '长寿', '江津', '合川', '永川', '南川', '璧山', '铜梁', '潼南', '荣昌', '开州', '梁平', '武隆'] },
                    { name: '河北', cities: ['全部', '石家庄', '唐山', '秦皇岛', '邯郸', '邢台', '保定', '张家口', '承德', '沧州', '廊坊', '衡水'] },
                    { name: '山西', cities: ['全部', '太原', '大同', '阳泉', '长治', '晋城', '朔州', '晋中', '运城', '忻州', '临汾', '吕梁'] },
                    { name: '辽宁', cities: ['全部', '沈阳', '大连', '鞍山', '抚顺', '本溪', '丹东', '锦州', '营口', '阜新', '辽阳', '盘锦', '铁岭', '朝阳', '葫芦岛'] },
                    { name: '吉林', cities: ['全部', '长春', '吉林', '四平', '辽源', '通化', '白山', '松原', '白城', '延边'] },
                    { name: '黑龙江', cities: ['全部', '哈尔滨', '齐齐哈尔', '鸡西', '鹤岗', '双鸭山', '大庆', '伊春', '佳木斯', '七台河', '牡丹江', '黑河', '绥化', '大兴安岭'] },
                    { name: '江苏', cities: ['全部', '南京', '无锡', '徐州', '常州', '苏州', '南通', '连云港', '淮安', '盐城', '扬州', '镇江', '泰州', '宿迁'] },
                    { name: '浙江', cities: ['全部', '杭州', '宁波', '温州', '嘉兴', '湖州', '绍兴', '金华', '衢州', '舟山', '台州', '丽水'] },
                    { name: '安徽', cities: ['全部', '合肥', '芜湖', '蚌埠', '淮南', '马鞍山', '淮北', '铜陵', '安庆', '黄山', '滁州', '阜阳', '宿州', '巢湖', '六安', '亳州', '池州', '宣城'] },
                    { name: '福建', cities: ['全部', '福州', '厦门', '莆田', '三明', '泉州', '漳州', '南平', '龙岩', '宁德'] },
                    { name: '江西', cities: ['全部', '南昌', '景德镇', '萍乡', '九江', '新余', '鹰潭', '赣州', '吉安', '宜春', '抚州', '上饶'] },
                    { name: '山东', cities: ['全部', '济南', '青岛', '淄博', '枣庄', '东营', '烟台', '潍坊', '济宁', '泰安', '威海', '日照', '莱芜', '临沂', '德州', '聊城', '滨州', '菏泽'] },
                    { name: '河南', cities: ['全部', '郑州', '开封', '洛阳', '平顶山', '安阳', '鹤壁', '新乡', '焦作', '濮阳', '许昌', '漯河', '三门峡', '南阳', '商丘', '信阳', '周口', '驻马店', '济源'] },
                    { name: '湖北', cities: ['全部', '武汉', '黄石', '十堰', '宜昌', '襄阳', '鄂州', '荆门', '孝感', '荆州', '黄冈', '咸宁', '随州', '恩施'] },
                    { name: '湖南', cities: ['全部', '长沙', '株洲', '湘潭', '衡阳', '邵阳', '岳阳', '常德', '张家界', '益阳', '郴州', '永州', '怀化', '娄底', '湘西'] },
                    { name: '广东', cities: ['全部', '广州', '韶关', '深圳', '珠海', '汕头', '佛山', '江门', '湛江', '茂名', '肇庆', '惠州', '梅州', '汕尾', '河源', '阳江', '清远', '东莞', '中山', '潮州', '揭阳', '云浮'] },
                    { name: '广西', cities: ['全部', '南宁', '柳州', '桂林', '梧州', '北海', '防城港', '钦州', '贵港', '玉林', '百色', '贺州', '河池', '来宾', '崇左'] },
                    { name: '海南', cities: ['全部', '海口', '三亚', '三沙', '儋州'] },
                    { name: '四川', cities: ['全部', '成都', '自贡', '攀枝花', '泸州', '德阳', '绵阳', '广元', '遂宁', '内江', '乐山', '南充', '眉山', '宜宾', '广安', '达州', '雅安', '巴中', '资阳'] },
                    { name: '贵州', cities: ['全部', '贵阳', '六盘水', '遵义', '安顺', '毕节', '铜仁'] },
                    { name: '云南', cities: ['全部', '昆明', '曲靖', '玉溪', '保山', '昭通', '丽江', '普洱', '临沧'] },
                    { name: '西藏', cities: ['全部', '拉萨'] },
                    { name: '陕西', cities: ['全部', '西安', '铜川', '宝鸡', '咸阳', '渭南', '延安', '汉中', '榆林', '安康', '商洛'] },
                    { name: '甘肃', cities: ['全部', '兰州', '嘉峪关', '金昌', '白银', '天水', '武威', '张掖', '平凉', '酒泉', '庆阳', '定西', '陇南'] },
                    { name: '青海', cities: ['全部', '西宁', '海东'] },
                    { name: '宁夏', cities: ['全部', '银川', '石嘴山', '吴忠', '固原', '中卫'] },
                    { name: '新疆', cities: ['全部', '乌鲁木齐', '克拉玛依', '吐鲁番', '哈密'] },
                    { name: '香港', cities: ['全部', '香港岛', '九龙', '新界'] },
                    { name: '澳门', cities: ['全部', '澳门半岛', '离岛'] },
                    { name: '台湾', cities: ['全部', '台北', '高雄', '基隆', '台中', '台南', '新竹', '嘉义'] }
                ];
            }
        }
    }
</script>

<style scoped>
    /**, *::before, *::after {*/
    /*margin: 0;*/
    /*padding: 0;*/
    /*box-sizing: border-box;*/
    /*}*/
    /* 全局重置样式，去除默认边距和填充，统一盒模型 */

    body {
        font-size: 100%;
    }
    /* 设置body的字体大小为100% */

    .gongxi2 {
        height: 20px;
    }
    /* 空div的高度 */

    .errong {
        padding-left: 30px;
        padding-right: 30px;
    }
    /* container的左右填充 */
    .phome-container-box{
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .errong2 {
        padding-left: 15px;
        padding-right: 15px;
    }
    /* 另一个container的左右填充 */

    .tn {
        clear: both;
    }
    /* 清除浮动 */

    .province-label {
        flex-shrink: 0;
        margin-right: 10px;
    }
    /* 省份标签样式，保持固定宽度，不收缩，右边距10px */

    .province-links {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        margin-left: 0;
        flex-grow: 1;
        gap: 0.5rem;
    }
    /* 省份链接的容器，使用flex布局，换行排列，间距0.5rem */

    .daxiao {
        font-size: 0.95rem;
    }
    /* 统一设置字体大小 */

    .active {
        color: #18b371 !important;
    }
    /* 选中状态的样式，字体颜色变为#18b371 */

    .card-container {
        display: flex;
        flex-wrap: wrap;
        margin-left: -1px;
        margin-right: -1px;
    }
    /* 卡片容器，使用flex布局，换行排列，左右负边距1px */

    .col-md-4 {
        padding-left: 2px;
        padding-right: 2px;
    }
    /* 卡片列的左右填充 */

    @media (min-width: 768px) {
        .zhai-head {
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 1000;
        }
        .errong {
            margin-top: 70px;
        }
        /* 当屏幕宽度大于768px时，固定头部组件，顶部距离0，全宽，z-index为1000，内容区域的顶部外边距70px */
    }

    .G-Field {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70px;
    }
    /* 分页导航容器，使用flex布局，垂直和水平居中，固定高度70px */

    .page-link {
        color: #6c757d;
    }
    /* 分页链接的默认颜色 */

    .pagination {
        line-height: 1;
        border-color: #dee2e6;
    }
    /* 分页容器的行高和边框颜色 */

    .active > .page-link,
    .page-link.active {
        background-color: #212429 !important;
        color: white !important;
        border-color: #212429 !important;
    }
    /* 选中的分页链接样式，背景颜色和边框颜色为#212429，字体颜色为白色 */
</style>
