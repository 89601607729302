<template>
    <!-- 我的发布页面 -->
    <!-- 导航栏组件 -->
    <ZhaiHead></ZhaiHead>
    <!-- 九宫格导航 -->
    <div class="container-fluid zhu mb-2">
        <ZhaiJiuGong menu="3"></ZhaiJiuGong>
    </div>
    <!-- 发布内容区域 -->
    <div class="container-fluid shengse">
        <div class="row shengse">
            <!-- 顶部标题和提示信息 -->
            <div class="dixian px-3 py-2 d-flex justify-content-between">
                <div>我的发布</div>
            </div>
            <!-- 发帖权限提示 -->
            <div style="font-size: 0.9rem; padding:1em; color:red;">
                <strong>发帖权限：</strong>
                <template v-if="getUser && getUser.level_expire_date && new Date() <= new Date(getUser.level_expire_date)">
                    正常（拒审两次将被禁止发帖）
                </template>
                <template v-else>
                    无权限（需开通会员）
                </template>
            </div>
            <!-- 付费置顶提示 -->
            <div style="font-size: 0.9rem; padding:0 1em 1em; color:red;">
                <strong>付费置顶：</strong>帖子审核通过后，可以通过“付费置顶”按钮来自助置顶推广。
            </div>
        </div>
        <!-- 条件渲染：当posts数组为空时显示此部分 -->
        <div class="shengse my-2 p-3 G-Field" v-if="posts.length === 0">
            <!-- 分页导航（当没有帖子时隐藏分页按钮） -->
            <div id="page" style="text-align: center">
                <ul class="pagination">
                    <li><a title="上一页" href="/mypost.php?c=1&amp;page=-1" style="display:none;">«</a></li>
                    <li><a title="下一页" style="display:none;">»</a></li>
                </ul>
                <div style="clear:both;"></div>
            </div>
        </div>
    </div>
    <!-- 卡片展示区域 -->
    <!-- 卡片展示区域 -->
    <div class="container-fluid mt-2 px-0">
        <div class="row card-container">
            <!-- 循环展示每个发布的帖子 -->
            <div class="col-md-4 mb-1 card" v-for="post in posts" :key="post.id">
                <a @click.prevent="handleCardClick(post)" class="card-link">
                    <div class="card h-100">
                        <div class="row g-0 h-100">
                            <div class="col-4 p-0 d-flex align-items-center justify-content-center">
                                <div class="image-container">
                                    <img :src="post.img" alt="..." class="card-img">
                                </div>
                            </div>
                            <div class="col-8 d-flex flex-column h-100">
                                <div class="card-body p-1 d-flex flex-column h-100">
                                    <div class="header flex-shrink-0">
                                        <h5 class="card-title mt-3">{{ post.name }}</h5>
                                        <div class="ri mb-1 mt-2">
                                            <i class="bi bi-clock riqiziti" id="upload-date">
                                                <span>&nbsp;{{ post.createTime.substr(0, 10) }}&nbsp;&nbsp;</span>
                                            </i>
                                            <i class="bi bi-star-fill riqiziti" id="collect">
                                                <span>&nbsp;{{ post.collectCount }}{{ post.collection }}</span>
                                            </i>
                                        </div>
                                    </div>
                                    <div class="content flex-grow-1">
                                        <p class="card-text">{{ post.history }}</p>
                                    </div>
                                    <div class="footer mb-2 flex-shrink-0">
                                        <span class="bi bi-send-fill weiz changlv" id="location">
                                            &nbsp;{{ post.province }}-{{ post.city }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
    <!-- 分页导航区域，当有帖子时显示 -->
    <div v-if="posts.length > 0" class="bg-white my-2 p-3 G-Field text-center d-flex justify-content-center align-items-center">
        <!-- 上一页按钮 -->
        <a class="page-link pagination-btn" href="#" aria-label="Previous" @click.prevent="prevPage" :disabled="currentPage === 1">
            <span aria-hidden="true">«</span>
        </a>
        <!-- 显示当前页码和总页数 -->
        <span style="margin: 0 1rem;">第 {{ currentPage }} 页，共 {{ totalPages }} 页</span>
        <!-- 下一页按钮 -->
        <a class="page-link pagination-btn" href="#" aria-label="Next" @click.prevent="nextPage" :disabled="currentPage === totalPages">
            <span aria-hidden="true">»</span>
        </a>
    </div>

    <!-- 公共底部组件 -->
    <ZhaiFoot></ZhaiFoot>
</template>

<script>
    import ZhaiHead from "../components/ZhaiHead";
    import ZhaiFoot from "../components/ZhaiFoot";
    import ZhaiJiuGong from "../components/ZhaiJiuGong";
    import http from "../utils/http.js";
    import { mapGetters } from "vuex";

    export default {
        name: "MMyRelease",
        components: { ZhaiHead, ZhaiFoot, ZhaiJiuGong },
        data() {
            return {
                posts: [],
                currentPage: 1,
                pageSize: 24,
                totalPages: 1,
            };
        },
        computed: {
            ...mapGetters(["getUser"]),
        },
        mounted() {
            this.fetchPosts();
        },
        methods: {
            fetchPosts() {
                if (!this.getUser) {
                    console.error("用户未登录");
                    return;
                }

                const userId = this.getUser.id;
                const url = `/platform/frontEnd/myRelease?userid=${userId}&pageNum=${this.currentPage}&pageSize=${this.pageSize}`;

                http.get(url)
                    .then((response) => {
                        if (response.data && response.data.status === 1) {
                            this.posts = response.data.data.list;
                            this.totalPages = Math.ceil(response.data.data.total / this.pageSize);
                        } else {
                            console.error("获取发布信息失败:", response.data.msg);
                        }
                    })
                    .catch((error) => {
                        console.error("API 请求错误:", error);
                    });
            },
            handleCardClick(post) {
                // 跳转到详情页面
                this.$router.push({
                    name: "detailPage",
                    query: { id: post.id },
                });
            },
            prevPage() {
                if (this.currentPage > 1) {
                    this.currentPage--;
                    this.fetchPosts();
                }
            },
            nextPage() {
                if (this.currentPage < this.totalPages) {
                    this.currentPage++;
                    this.fetchPosts();
                }
            },
        },
    };
</script>

<style scoped>
    .zhu{
        margin-top: 80px;
    }
    /* 原有样式保持不变 */
    .card {
        text-decoration: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        border: none;
    }
    .col-4 {
        width: 115px;
        height: 155px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        padding: 0;
    }
    .image-container {
        width: 99px;
        height: 132px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 4px;
    }
    .card-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .col-8 {
        max-height: 155px;
    }
    .card-body {
        display: flex;
        flex-direction: column;
        padding: 0.1rem;
        height: 100%;
    }
    .card-title {
        font-size: 1rem;
    }
    .card-text {
        margin-bottom: 0.5rem !important;
        font-size: 0.9rem !important;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .ri {
        display: flex;
        align-items: center;
        font-size: 0.8rem;
    }
    .riqiziti {
        font-style: normal;
        font-size: 0.8rem;
        margin-right: 4px;
    }
    .ri i + i {
        margin-left: 4px;
    }
    .changlv {
        color: #18b371 !important;
    }
    .content {
        display: flex;
        flex-direction: column;
        margin-top: 0.2rem;
    }
    .card-link {
        text-decoration: none;
        color: inherit;
    }
    .weiz {
        font-size: 0.9rem;
    }
</style>