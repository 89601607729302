<template>
    <!--手机端找回用户名-->
    <!--导航栏-->
    <ZhaiHead></ZhaiHead>
    <!--导航栏-->
    <!--主体内容-->
    <div class="container-fluid zhu shengse px-0 deng">
        <div class="dixian px-4 py-2 d-flex justify-content-between">
            <div><i class="bi bi-person-add"></i>&nbsp;找回用户名</div>
        </div>
        <!-- 输入表单 -->
        <div class="px-5 zhai-s pb-4" v-if="!username">
            <div class="mt-2">
                <input type="email" class="form-control custom-input" id="exampleFormControlInput4" v-model="myform.email" placeholder="邮箱">
            </div>
            <text class="tishi">*请输入注册时使用的邮箱</text>

            <div class="mt-3">
                <input type="password" class="form-control custom-input" id="password" v-model="myform.password" placeholder="请输入密码">
            </div>
            <text class="tishi">*请输入注册时密码</text>

            <!-- 注册按钮 -->
            <div class="col-12 mt-4 text-center">
                <button type="button" @click="retrieveUsername" class="btn btn-success w-100">找回用户名</button>
            </div>
        </div>
        <!-- 结果展示 -->
        <div class="px-5 zhai-s mt-2" v-if="username">
            <div>用户名：<span>{{ username }}</span></div>
            <!-- 登录 -->
            <div class="col-12 text-center mt-3">
                <button type="button" @click="goToLogin" class="btn btn-success w-100">返回登录</button>
            </div>
            <div class="gongxi2"></div>
        </div>
    </div>
    <!--主体内容-->

    <!--只是占据空间-->
    <div class="gongxi2"></div>

    <!--公共底部-->
    <ZhaiFoot></ZhaiFoot>
    <!--公共底部-->

    <!-- 错误模态框 -->
    <div class="modal fade" id="loginWarningModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered small-modal">
            <div class="modal-content shake-modal">
                <div class="modal-body text-center d-flex align-items-center justify-content-center small-padding">
                    <i class="bi bi-emoji-frown small-icon"></i>
                    <p class="mb-0 ms-2 small-text">{{ errorMessage }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import http from "../utils/http.js"; // 导入 http 请求模块
    import ZhaiHead from "../components/ZhaiHead"; // 导入导航栏组件
    import ZhaiFoot from "../components/ZhaiFoot"; // 导入底部组件
    import 'bootstrap/dist/css/bootstrap.min.css';
    import { Modal } from 'bootstrap';
    export default {
        name: "MRetrieveUserName", // 组件名称
        components: { ZhaiHead, ZhaiFoot }, // 注册组件
        data() {
            return {
                myform: {
                    email: "", // 邮箱
                    password: "" // 密码
                },
                username: null, // 存储找回的用户名
                errorMessage: '' // 存储错误信息
            };
        },
        methods: {
            retrieveUsername() {
                console.log('找回用户名按钮点击');

                // 前端验证
                if (!this.myform.email || !this.myform.password) {
                    this.errorMessage = "必填项不可为空";
                    let modal = new Modal(document.getElementById('loginWarningModal'));
                    modal.show();

                    // 模态框抖动并在2秒后消失
                    setTimeout(() => {
                        document.querySelector('.shake-modal').classList.add('shake');
                    }, 100);
                    setTimeout(() => {
                        modal.hide();
                    }, 2000);
                    return;
                }

                // 准备找回用户名请求
                let url = "/platform/frontEnd/rUsernName1"; // 找回用户名接口 URL
                let fm = new FormData(); // 创建 FormData 对象
                fm.append("email", this.myform.email); // 添加邮箱到 FormData
                fm.append("password", this.myform.password); // 添加密码到 FormData

                // 发送 POST 请求
                http.post(url, fm, true).then(response => {
                    console.log(response); // 打印完整的响应以调试
                    if (response.data.status === 1) {
                        console.log('用户名:', response.data.data); // 打印用户名
                        this.username = response.data.data; // 更新用户名
                    } else {
                        this.errorMessage = response.data.msg; // 更新错误信息
                        // 显示错误信息模态框
                        let modal = new Modal(document.getElementById('loginWarningModal'));
                        modal.show();

                        // 模态框抖动并在2秒后消失
                        setTimeout(() => {
                            document.querySelector('.shake-modal').classList.add('shake');
                        }, 100);
                        setTimeout(() => {
                            modal.hide();
                        }, 2000);
                    }
                }).catch(error => {
                    console.error('请求失败:', error);
                    this.errorMessage = error.response ? error.response.data.msg : '请求失败，请稍后再试'; // 更新错误信息
                    // 显示错误信息模态框
                    let modal = new Modal(document.getElementById('loginWarningModal'));
                    modal.show();

                    // 模态框抖动并在2秒后消失
                    setTimeout(() => {
                        document.querySelector('.shake-modal').classList.add('shake');
                    }, 100);
                    setTimeout(() => {
                        modal.hide();
                    }, 2000);
                });
            },
            goToLogin() {
                this.$router.push('/login'); // 跳转到登录页面
            }
        },
        mounted() {
            console.log('组件挂载完成');
        }
    }
</script>

<style scoped>
    /*主体与顶端的内边距*/
    .zhai-s {
        padding-top: 10px;
    }

    /*提示文字红色*/
    .tishi {
        color: red;
        font-size: 0.9rem;
    }

    /*底部不占据空间*/
    .gongxi2 {
        height: 30px;
    }
    /* 自定义输入框样式 */
    .custom-input {
        border-radius: 2px !important;
        line-height: 1.5 !important;
    }

    /*按钮颜色字体大小*/
    .btn-success {
        background-color: #00c26f !important;
        font-size: 0.9rem;
        border: none;
    }

    /*输入框内和提示文字的大小*/
    .form-control {
        font-size: 0.9rem;
    }

    .deng {
        margin: 0 auto;
    }
    .zhu {
        margin-top: 85px;
    }

    /* 调整模态框和文本的大小 */
    .small-modal .modal-content {
        width: 100%;
        max-width: 250px;
        margin: 0 auto;
    }
    .small-padding {
        padding: 0px !important;
    }
    .small-icon {
        font-size: 1.5rem;
        color: #dc3545;
    }
    .small-text {
        font-size: 0.9rem;
        color: #dc3545;
    }

    /* 抖动动画 */
    @keyframes shake {
        0% { transform: translateX(0); }
        25% { transform: translateX(-5px); }
        50% { transform: translateX(5px); }
        75% { transform: translateX(-5px); }
        100% { transform: translateX(0); }
    }

    .shake {
        animation: shake 0.5s;
    }
</style>
