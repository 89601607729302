<template>
    <!--PC详情页-->
    <!-- 导航栏 -->
    <ZhaiHeadPC class="zhai-head"></ZhaiHeadPC>
    <!-- 导航栏 -->
    <!-- 主体内容容器 -->
    <div class="container errong">
        <div class="container-fluid p-0 my-2"></div>
        <div class="row gx-2">
            <!-- 左侧部分 -->
            <div class="col-lg-8">
                <div class="LeftPart">
                    <div class="row pp shengse pb-3 pt-1">
                        <div><span class="Title">{{ details.name }}</span></div>
                        <div class="ri mb-1">
                            <small class="bi bi-clock zitipc" id=""><span>&nbsp;{{ details.createTime.substr(0, 10) }}&nbsp;&nbsp;</span></small>
                            <small class="bi bi-person-fill zitipc" id="collect"><span>&nbsp;匿名</span></small>
                        </div>
                        <div class="daxiao">
                            <div class="py-1 jiushi"><span class="jiacu">所属地区：</span>&nbsp;{{ details.province }}-{{ details.city }}</div>
                            <div class="py-1 jiushi"><span class="jiacu">账号年龄：</span>&nbsp;{{ details.age }}</div>
                            <div class="py-1 jiushi"><span class="jiacu">价格：</span>&nbsp;{{ details.price }}</div>
                            <div class="py-1 jiushi"><span class="jiacu">等级：</span>&nbsp;{{ details.level }}</div>
                            <div class="py-1 jiushi"><span class="jiacu">特点：</span>&nbsp;{{ details.condition }}</div>
                            <div class="mt-2">
                                <!-- 收藏按钮 -->
                                <button type="button" @click="toggleCollect" class="btn btn-outline-success bi-bag-heart jiushi me-2 button-large">
                                    {{ isCollected ? '已收藏' : '收藏' }}
                                </button>
                                <!-- 申请删除按钮 -->
                                <button type="button" @click="applyDel" class="btn btn-outline-success bi-trash3 jiushi button-large" data-bs-toggle="modal" data-bs-target="#myModal2">申请删除</button>
                            </div>
                        </div>
                    </div>

                    <!--商品-->
                    <div class="row shengse mt-2 rounded-top-1">
                        <div class="dixian px-4 py-2 d-flex justify-content-between">
                            <div><i class="shou bi bi-telephone-fill"></i>商品</div>
                            <div class="iden">
                                <a class="text-decoration-none ruhe zitiyanse hover-hand" @click="showModalInfo('myModal5')">如何鉴别？</a>
                            </div>
                        </div>
                    </div>
                    <!--商品-->
                    <!--如何鉴别-->
                    <div class="row shengse pt-4 pb-0">
                        <div class="px-4">
                            <div>
                                <div class="alert alert-warning zhuzi" role="alert">在 Bootstrap 5 中并没有直接名为 border-solid 的类。相反，Bootstrap 5 使用了 border-style-solid 作为类名的一部分。但由于 Bootstrap 5 默认边框样式就是实线，所以实际上你不需要特别指定 border-style-solid。只需要使用 border-bottom 就足够了</div>
                            </div>
                        </div>
                    </div>
                    <!--如何鉴别-->
                    <!--账号-->
                    <div class="row shengse pb-4 rounded-bottom-1">
                        <div class="px-4">
                            <template v-if="!isLoggedIn">
                                <div class="py-1 jiushi"><span class="jiacu">账号：</span>&nbsp;请先<router-link to="/login" class="login-register-link">登录</router-link>或<router-link to="/register" class="login-register-link">注册</router-link></div>
                                <div class="py-1 jiushi"><span class="jiacu">微信：</span>&nbsp;请先<router-link to="/login" class="login-register-link">登录</router-link>或<router-link to="/register" class="login-register-link">注册</router-link></div>
                                <div class="py-1 jiushi"><span class="jiacu">邮箱：</span>&nbsp;请先<router-link to="/login" class="login-register-link">登录</router-link>或<router-link to="/register" class="login-register-link">注册</router-link></div>
                                <div class="py-1 jiushi"><span class="jiacu">注意事项：</span>&nbsp;请先<router-link to="/login" class="login-register-link">登录</router-link>或<router-link to="/register" class="login-register-link">注册</router-link></div>
                                <div class="py-1 jiushi"><span class="jiacu">手机号：</span>&nbsp;请先<router-link to="/login" class="login-register-link">登录</router-link>或<router-link to="/register" class="login-register-link">注册</router-link></div>
                                <div class="py-1 jiushi"><span class="jiacu">来源：</span>&nbsp;请先<router-link to="/login" class="login-register-link">登录</router-link>或<router-link to="/register" class="login-register-link">注册</router-link></div>
                            </template>
                            <template v-else-if="vipExpired">
                                <div class="py-1 jiushi"><span class="jiacu">账号：</span>&nbsp;VIP可以无限制查看信息，请&nbsp;<router-link to="/upgradeVIP" class="upgrade-vip-link">升级VIP</router-link></div>
                                <div class="py-1 jiushi"><span class="jiacu">微信：</span>&nbsp;VIP可以无限制查看信息，请&nbsp;<router-link to="/upgradeVIP" class="upgrade-vip-link">升级VIP</router-link></div>
                                <div class="py-1 jiushi"><span class="jiacu">邮箱：</span>&nbsp;VIP可以无限制查看信息，请&nbsp;<router-link to="/upgradeVIP" class="upgrade-vip-link">升级VIP</router-link></div>
                                <div class="py-1 jiushi"><span class="jiacu">注意事项：</span>&nbsp;VIP可以无限制查看信息，请&nbsp;<router-link to="/upgradeVIP" class="upgrade-vip-link">升级VIP</router-link></div>
                                <div class="py-1 jiushi"><span class="jiacu">手机号：</span>&nbsp;VIP可以无限制查看信息，请&nbsp;<router-link to="/upgradeVIP" class="upgrade-vip-link">升级VIP</router-link></div>
                                <div class="py-1 jiushi"><span class="jiacu">来源：</span>&nbsp;VIP可以无限制查看信息，请&nbsp;<router-link to="/upgradeVIP" class="upgrade-vip-link">升级VIP</router-link></div>
                            </template>
                            <template v-else>
                                <div v-if="details.account" class="py-1 jiushi"><span class="jiacu">账号：</span>&nbsp;{{ details.account }}</div>
                                <div v-if="details.remark" class="py-1 jiushi"><span class="jiacu">微信：</span>&nbsp;{{ details.remark }}</div>
                                <div v-if="details.email" class="py-1 jiushi"><span class="jiacu">邮箱：</span>&nbsp;{{ details.email }}</div>
                                <div v-if="details.attention" class="py-1 jiushi"><span class="jiacu">注意事项：</span>&nbsp;{{ details.attention }}</div>
                                <div v-if="details.phone" class="py-1 jiushi"><span class="jiacu">手机号：</span>&nbsp;{{ details.phone }}</div>
                                <div v-if="details.source" class="py-1 jiushi"><span class="jiacu">来源：</span>&nbsp;{{ details.source }}</div>
                            </template>
                            <div class="py-1 zhuzi">
                                <span style="color:red">！！！警告：凡是需要二次消费的皆非我站产品！</span>
                            </div>
                            <div class="mt-2">
                                <!-- 防骗指南按钮 -->
                                <button data-bs-toggle="modal" data-bs-target="#myModal4" type="button" href="" class="btn btn-outline-success bi-eye button-large">防骗指南</button>
                                <!-- 信息规则按钮 -->
                                <button data-bs-toggle="modal" data-bs-target="#myModal3" type="button" href="" class="btn btn-outline-success bi-info-circle button-large">信息规则</button>
                            </div>
                        </div>
                    </div>

                    <!--账号-->
                    <!--详细介绍-->
                    <div class="row shengse mt-2 rounded-top-1">
                        <div class="dixian px-4 py-2 d-flex justify-content-between">
                            <div class="deziti"><i class="shou bi bi-info-circle"></i>详细介绍</div>
                        </div>
                    </div>
                    <!--详细介绍-->
                    <!--截图-->
                    <div class="row shengse pb-4 rounded-bottom-1">
                        <div class="d-flex flex-wrap" ref="gallery">
                            <div class="p-2"
                                 v-for="(img, index) in imgList"
                                 :key="index"
                                 style="flex: 0 0 25%; max-width: 25%;">
                                <img class="Img w-100 clickable-img" :src="img" alt="图片预览" />
                            </div>

                            <div class="px-3 pb-3 mt-3">
                                <p class="mb-2 indented" style="font-size: 0.9rem;">
                                    {{ details.history }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <!--截图-->

                    <!--用户反馈-->
                    <div class="row shengse mt-2 rounded-bottom-1 rounded-top-1">
                        <div class="dixian px-4 py-2 mb-3 d-flex justify-content-between">
                            <div class="deziti"><i class="shou bi bi-chat-dots-fill"></i>用户反馈</div>
                        </div>
                        <ZhaiCommentPC v-for="(feedback, index) in feedbackList" :key="index" :data="feedback"></ZhaiCommentPC>
                        <div class="fk-ad">
                            <!-- 我要反馈按钮 -->
                            <button type="button" @click="checkLoginBeforeFeedback" class="btn btn-outline-success mx-auto bi-file-plus-fill button-large">我要反馈</button>
                        </div>
                    </div>
                    <!--用户反馈-->
                </div>
            </div>
            <!-- 右侧部分 -->
            <div class="col-lg-4">
                <div class="RightPart">
                    <div class="bdcard">
                        <div class="row rr mt-4">
                            <div class="deziti zuo px-4 pb-2 d-flex justify-content-between">
                                <div><i class="shou bi bi-geo-alt-fill"></i>本地其他推荐</div>
                            </div>
                            <div v-for="(item, index) in recommendList" :key="index" class="no-underline mb-1">
                                <ZhaiCardPC :data="item" @click="updateRoute(item.id)"></ZhaiCardPC>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- 主体内容容器 -->

    <!-- 只是占据空间 -->
    <div class="gongxi2"></div>

    <!-- 公共底部 -->
    <ZhaiFootPC></ZhaiFootPC>
    <!-- 我要反馈 模态框 -->
    <div class="modal fade" id="myModal">
        <div class="modal-dialog modal-dialog-centered feedback-modal">
            <div class="modal-content">
                <!-- 模态框头部 -->
                <div class="modal-header">
                    <h4 class="modal-title motou">我要反馈</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <!-- 模态框内容 -->
                <div class="ant-modal-body mb-2 mt-1">
                    <form class="myform px-3">
                        <div class="form-group mt-3 ">
                            <label style="font-size:1rem;">反馈信息：</label>
                            <input style="font-size:1rem;" type="text" id="feedback-info" class="form-control" v-model="feedbackInfo" name="feedback-info" placeholder="" readonly>
                        </div>
                        <div class="form-group mt-4">
                            <label style="font-size:1rem;">反馈内容：</label>
                            <textarea class="form-control " id="content" v-model="feedbackContent" placeholder="平台鼓励反馈真实的体验，请勿提交违背事实的虚假反馈，一经发现将关闭你的评论权限，情节严重的将可能直接封号。"></textarea>
                        </div>
                        <div class="form-group mt-4 px-2">
                            <label style="font-size:1rem;">验证码：</label>
                            <div style="max-width: 300px;">
                                <!-- 这里是包裹验证码输入框的 div -->
                                <CaptchaComponent ref="captchaComponent" @validate-captcha="validateCaptcha"></CaptchaComponent>
                            </div>
                        </div>
                        <div class="form-group mt-3">
                            <div class="text-center mb-3">
                                <button type="button" @click="submitFeedback" class="btn btn-success px-5 toushu feedback-submit-btn button-large2">提交</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!--申请删除  模态框 -->
    <div class="modal fade" id="myModal2">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <!-- 模态框头部 -->
                <div class="modal-header">
                    <h4 class="modal-title motou">申请删除</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <!-- 模态框内容 -->
                <div class="modal-body">
                    <div class="ant-modal-body motou px-3">
                        <div>本站所有账号及文档来自网络，如有内容侵犯到你的隐私、权益或未经你的允许而被他人发布，平台很愿意为你删除。请你提供相关证明材料发邮件至 404489561@gmail.com ，我们通常会在24小时内处理你的请求，最长不会超过48小时，如果事实逻辑清晰我们将及时为你删除。</div>
                    </div>
                </div>
                <!-- 模态框底部 -->
                <div class="modal-footer di text-center">
                    <button type="button" class="btn btn-success toushu button-large2" data-bs-dismiss="modal">关闭</button>
                </div>
            </div>
        </div>
    </div>
    <!--信息规则 模态框 -->
    <div class="modal fade" id="myModal3">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <!-- 模态框头部 -->
                <div class="modal-header">
                    <h4 class="modal-title motou">信息规则</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <!-- 模态框内容 -->
                <div class="modal-body motou px-3">
                    <div>部分信息会隐藏账号信息，需要升级VIP会员来享受无限制查看信息的权限。</div>
                </div>
                <!-- 模态框底部 -->
                <div class="modal-footer di text-center">
                    <button type="button" class="btn btn-success toushu button-large2" data-bs-dismiss="modal">关闭</button>
                </div>
            </div>
        </div>
    </div>
    <!--防骗 模态框 -->
    <div class="modal fade" id="myModal4">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <!-- 模态框头部 -->
                <div class="modal-header">
                    <h4 class="modal-title motou">防骗指南</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <!-- 模态框内容 -->
                <div class="modal-body p-2">
                    <p class="px-3 motou">
                        为了让广大用户避免上当受骗,大家做到以下几点,基本可以有效预防: <br>
                        1.本站只提供3年以上的账号,品质卓然,账号的绑定的邮箱、手机、违规记录、使用方向等附属信息都会提供给您；<br>
                        2.本站只在此地址提供服务，其他皆为仿冒；<br>
                        3.除vip会员费用，本站无二次收费；<br>
                        4.建议拿到账号后立即修改账号密码并换绑邮箱或者手机号，以便长期使用；
                    </p>
                </div>
                <!-- 模态框底部 -->
                <div class="modal-footer di text-center">
                    <button type="button" class="btn btn-success toushu button-large2" data-bs-dismiss="modal">关闭</button>
                </div>
            </div>
        </div>
    </div>
    <!--如何鉴别 模态框 -->
    <div class="modal fade" id="myModal5">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <!-- 模态框头部 -->
                <div class="modal-header">
                    <h4 class="modal-title">如何鉴别</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <!-- 模态框内容 -->
                <div class="modal-body p-2">
                    当您对账号的质量有所顾虑的时候，建议您先购买季度会员，找到适合您的账号并且感觉
                    本站产品的质量可以的情况下再进行VIP升级；
                </div>
                <!-- 模态框底部 -->
                <div class="modal-footer mt-2 text-center" style="display: block;">
                    <div class="row">
                        <div class="col-6">
                            <button type="button" class="btn btn-success toushu button-large" data-bs-dismiss="modal">关闭</button>
                        </div>
                        <div class="col-6">
                            <router-link to="/avoidFraud" class="input-group text-decoration-none">
                                <button type="button" class="btn btn-success toushu button-large2">新手防骗指南</button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- 登录警示 模态框 -->
    <div class="modal fade" id="loginWarningModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style="width: 150px;">
            <div class="modal-content">
                <div class="modal-body text-center d-flex align-items-center justify-content-center">
                    <i class="bi bi-emoji-frown" style="font-size: 2rem; color: #f16b6b;"></i>
                    <p class="mb-0 ms-2" style="font-size: 1rem; color: #f16b6b;">请先登录！</p>
                </div>
            </div>
        </div>
    </div>
    <!-- 成功模态框 -->
    <div class="modal fade" id="successModal" tabindex="-1" aria-labelledby="successModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style="max-width: 200px;">
            <div class="modal-content">
                <div class="modal-body d-flex align-items-center justify-content-center">
                    <i class="bi bi-emoji-smile" style="font-size: 2rem; color: #00c26f;"></i>
                    <p class="mb-0 ms-2" style="font-size: 1rem; color: #00c26f;"></p>
                </div>
            </div>
        </div>
    </div>

    <!-- 失败模态框 -->
    <div class="modal fade" id="generalErrorModal" tabindex="-1" aria-labelledby="errorModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-shake" style="max-width: 200px;">
            <div class="modal-content shake">
                <div class="modal-body d-flex align-items-center justify-content-center">
                    <i class="bi bi-emoji-frown" style="font-size: 2rem; color: #dc3545;"></i>
                    <p class="mb-0 ms-2" style="font-size: 1rem; color: #dc3545;"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // 导入必要的模块和组件
    import Viewer from 'viewerjs'; // 引入图片预览插件
    import 'viewerjs/dist/viewer.css'; // 引入图片预览插件的样式
    import { Modal } from 'bootstrap'; // 引入Bootstrap的模态框功能
    import ZhaiHeadPC from "../componentspc/ZhaiHeadPC"; // 引入自定义的导航栏组件
    import ZhaiFootPC from "../componentspc/ZhaiFootPC"; // 引入自定义的底部组件
    import ZhaiCardPC from "../componentspc/ZhaiCardPC"; // 引入自定义的卡片组件
    import ZhaiCommentPC from "../componentspc/ZhaiCommentPC"; // 引入自定义的评论组件
    import CaptchaComponent from "../componentspc/CaptchaComponent.vue"; // 引入自定义的验证码组件
    import http from "../utils/http.js"; // 引入封装好的HTTP请求工具
    import { mapGetters, mapActions } from 'vuex'; // 引入Vuex的辅助函数，用于获取store中的状态和方法

    export default {
        name: "PDetails", // 定义组件名称
        components: {
            ZhaiHeadPC, // 注册导航栏组件
            ZhaiFootPC, // 注册底部组件
            ZhaiCardPC, // 注册卡片组件
            ZhaiCommentPC, // 注册评论组件
            CaptchaComponent // 注册验证码组件
        },
        data() {
            return {
                // 定义组件的数据对象
                feedbackInfo: '', // 反馈信息
                feedbackContent: '', // 反馈内容
                details: {
                    // 详情数据对象，存储从后端获取的数据
                    name: '',
                    uploadDate: '',
                    collection: '',
                    province: '',
                    city: '',
                    age: '',
                    price: '',
                    level: '',
                    condition: '',
                    account: '',
                    remark:'',
                    email: '',
                    attention: '',
                    phone: '',
                    source: '',
                    img: '',
                    createTime: '',
                    history: '',
                    presets3: '',
                    presets4: '',
                    presets5: ''
                },
                imgList: [], // 存储图片URL的列表
                recommendList: [], // 存储推荐列表数据
                feedbackList: [], // 存储用户反馈列表数据
                isCollected: false // 是否已收藏的标识
            };
        },
        computed: {
            ...mapGetters(['isLoggedIn', 'getUser']), // 映射Vuex中的状态到当前组件

            // 计算属性：判断VIP是否过期
            vipExpired() {
                if (!this.isLoggedIn) return false; // 用户未登录的情况下，不进行VIP过期检查
                const expireDate = this.getUser?.level_expire_date ? new Date(this.getUser.level_expire_date) : null;
                const now = new Date();
                return !expireDate || expireDate < now; // 如果VIP过期日期为空或已过期，返回true
            }
        },
        created() {
            // 组件创建时执行的逻辑
            this.checkLoginStatus(); // 检查用户登录状态
            this.fetchDetails(); // 获取详情数据
            this.fetchFeedbackList(); // 获取用户反馈数据
            this.checkCollectStatus(); // 检查是否已收藏
        },
        mounted() {
            // 组件挂载完成时执行的逻辑
            this.initViewer(); // 初始化图片预览插件
        },
        watch: {
            // 监听图片列表的变化
            imgList() {
                this.initViewer(); // 每当图片列表发生变化时，重新初始化图片预览插件
            },
            '$route.query.id'(newId) {
                this.fetchDetails();  // 当路由参数的 id 变化时，重新获取详情数据
                this.fetchFeedbackList();  // 获取新的用户反馈数据
            }
        },
        methods: {
            updateRoute(id) {
                this.$router.push({ query: { id: id } });
            },
            // 显示指定模态框的方法
            showModalInfo(modalId) {
                const modalElement = document.getElementById(modalId); // 获取模态框元素
                if (modalElement) {
                    const modal = new Modal(modalElement); // 创建模态框实例
                    modal.show(); // 显示模态框
                }
            },
            ...mapActions(['logout', 'checkLoginStatus']), // 映射Vuex中的action到当前组件

            // 申请删除按钮点击事件的处理函数
            applyDel() {
                console.log('申请删除按钮被点击');
            },

            // 检查当前商品是否已被用户收藏
            checkCollectStatus() {
                if (!this.isLoggedIn) return; // 如果用户未登录，则不执行检查收藏状态逻辑

                const id = this.$route.query.id; // 从查询参数中获取商品ID
                const userId = this.getUser.id; // 获取用户ID

                const postData = {
                    accountid: id,
                    userid: userId,
                    action: 'check' // 动作类型为"check"，即检查状态
                };

                // 向服务器发送POST请求，检查是否已收藏
                http.post('/platform/frontEnd/handleCollect', postData)
                    .then(response => {
                        if (response.data.status === 1) {
                            this.isCollected = response.data.data.isCollected; // 更新收藏状态
                        } else {
                            console.error('检查收藏状态失败:', response.data.msg);
                        }
                    })
                    .catch(error => {
                        console.error('检查收藏状态时出错:', error);
                    });
            },

            // 切换收藏状态的函数
            toggleCollect() {
                if (!this.isLoggedIn) {
                    // 如果用户未登录，显示提示模态框
                    const warningModalElement = document.getElementById('loginWarningModal');
                    const warningModal = new Modal(warningModalElement);

                    if (warningModalElement.classList.contains('show')) {
                        // 如果模态框已经显示，添加抖动动画
                        warningModalElement.classList.remove('shake-animation');
                        void warningModalElement.offsetWidth;
                        warningModalElement.classList.add('shake-animation');
                    } else {
                        // 如果模态框未显示，显示模态框并添加抖动动画
                        warningModalElement.classList.add('shake-animation');
                        warningModal.show();
                        setTimeout(() => {
                            warningModal.hide(); // 2秒后隐藏模态框
                        }, 2000);
                        setTimeout(() => {
                            warningModalElement.classList.remove('shake-animation'); // 移除抖动动画
                        }, 600);
                    }
                    return;
                }

                const accountId = this.details.id; // 获取详情ID
                const userId = this.getUser.id; // 获取用户ID
                const action = this.isCollected ? 'cancel' : 'collect'; // 判断当前是收藏还是取消收藏

                if (!userId || !accountId) return; // 如果用户ID或详情ID不存在，不执行后续逻辑

                const postData = {
                    accountid: accountId, // 账号ID
                    userid: userId, // 用户ID
                    action: action // 执行动作（收藏或取消收藏）
                };

                // 发送切换收藏状态的POST请求
                http.post('/platform/frontEnd/handleCollect', postData)
                    .then(response => {
                        if (response.data && response.data.status === 1) {
                            this.isCollected = !this.isCollected; // 切换收藏状态
                            this.showModal('successModal', this.isCollected ? '收藏成功' : '取消收藏成功', 'bi bi-emoji-smile', '#00c26f');
                        } else {
                            this.showModal('generalErrorModal', action === 'collect' ? '收藏失败' : '取消收藏失败', 'bi bi-emoji-frown', '#dc3545');
                        }
                    })
                    .catch(error => {
                        console.error(`${action === 'collect' ? '收藏' : '取消收藏'}请求错误:`, error);
                        this.showModal('generalErrorModal', '请求发生错误，请稍后再试', 'bi bi-emoji-frown', '#dc3545');
                    });
            },

            // 提交反馈的函数
            submitFeedback() {
                const codeInput = document.querySelector('.captcha-input'); // 获取验证码输入框
                const enteredCaptcha = codeInput ? codeInput.value.trim() : ''; // 获取用户输入的验证码
                const actualCaptcha = this.$refs.captchaComponent.captchaText; // 获取实际验证码

                // 检查反馈内容是否为空
                if (!this.feedbackContent || !this.feedbackContent.trim()) {
                    this.showModal('generalErrorModal', '必填项不可为空！', 'bi bi-emoji-frown', '#dc3545');
                    return;
                }

                // 检查验证码是否为空
                if (!enteredCaptcha) {
                    this.showModal('generalErrorModal', '必填项不可为空！', 'bi bi-emoji-frown', '#dc3545');
                    return;
                }

                // 验证码不匹配的情况
                if (enteredCaptcha !== actualCaptcha) {
                    this.showModal('generalErrorModal', '验证码错误！', 'bi bi-emoji-frown', '#dc3545');
                    this.$refs.captchaComponent.generateCaptcha(); // 生成新的验证码
                    return;
                }

                const feedbackData = {
                    message: this.feedbackContent, // 反馈内容
                    accountid: this.details.id, // 账号ID
                    userid: this.getUser.id, // 用户ID
                    username: this.getUser.username, // 用户名
                    userlevel: this.getUser.level || 'defaultLevel' // 用户等级
                };

                // 发送反馈的POST请求
                http.post('/platform/frontEnd/feedback', feedbackData, false, true)
                    .then(response => {
                        if (response.data.status === 1) {
                            this.showModal('successModal', '反馈提交成功', 'bi bi-emoji-smile', '#00c26f');
                            const feedbackModal = Modal.getInstance(document.getElementById('myModal'));
                            feedbackModal.hide(); // 隐藏反馈模态框
                        } else {
                            this.showModal('generalErrorModal', `反馈提交失败: ${response.data.msg}`, 'bi bi-emoji-frown', '#dc3545');
                        }
                    })
                    .catch(error => {
                        console.error(`提交反馈时出错: ${error}`);
                        this.showModal('generalErrorModal', `请求发生错误: ${error.response.data.msg || '未知错误'}`, 'bi bi-emoji-frown', '#dc3545');
                    });
            },

            // 显示模态框的函数
            showModal(modalId, message, iconClass, textColor) {
                const modalElement = document.getElementById(modalId); // 获取模态框元素
                const modalBodyP = modalElement.querySelector('.modal-body p'); // 获取模态框中的文本元素
                const icon = modalElement.querySelector('.bi'); // 获取模态框中的图标元素

                icon.className = iconClass; // 设置图标的类名
                icon.style.color = textColor; // 设置图标的颜色
                modalBodyP.textContent = message; // 设置模态框的提示信息

                const modal = new Modal(modalElement); // 创建模态框实例
                modalElement.classList.add('shake-animation'); // 添加抖动动画
                modal.show(); // 显示模态框

                setTimeout(() => {
                    modalElement.classList.remove('shake-animation'); // 移除抖动动画
                    modal.hide(); // 隐藏模态框
                }, 3000);
            },

            // 在反馈前检查用户是否登录
            checkLoginBeforeFeedback() {
                const warningModalElement = document.getElementById('loginWarningModal');
                const warningModal = new Modal(warningModalElement);

                if (this.isLoggedIn) {
                    // 如果用户已登录，显示反馈模态框
                    const feedbackModal = new Modal(document.getElementById('myModal'));
                    feedbackModal.show();
                } else {
                    // 如果用户未登录，显示提示模态框并添加抖动动画
                    if (warningModalElement.classList.contains('show')) {
                        warningModalElement.classList.remove('shake-animation');
                        void warningModalElement.offsetWidth;
                        warningModalElement.classList.add('shake-animation');
                    } else {
                        warningModalElement.classList.add('shake-animation');
                        warningModal.show();
                        setTimeout(() => {
                            warningModal.hide();
                        }, 2000);
                        setTimeout(() => {
                            warningModalElement.classList.remove('shake-animation');
                        }, 600);
                    }
                }
            },

            // 验证验证码的函数
            validateCaptcha(isValid) {
                if (!isValid) {
                    alert('图形验证码输入不正确！');
                }
            },

            // 初始化反馈信息
            initializeFeedbackInfo() {
                this.feedbackInfo = this.details.name; // 将详情中的名称设置为反馈信息
            },

            // 初始化图片预览插件
            initViewer() {
                this.$nextTick(() => {
                    const gallery = this.$refs.gallery;
                    if (gallery) {
                        if (this.viewerInstance) {
                            this.viewerInstance.destroy(); // 如果已有实例，销毁之前的实例
                        }
                        this.viewerInstance = new Viewer(gallery, {
                            url(image) {
                                return image.src; // 设置图片URL
                            }
                        });
                    }
                });
            },

            // 获取商品详情数据，通过查询参数获取商品ID
            fetchDetails() {
                const id = this.$route.query.id; // 从查询参数中获取商品ID
                if (!id) {
                    return;
                }
                // 向服务器请求商品详情数据
                http.get(`/platform/frontEnd/details?id=${id}`)
                    .then(response => {
                        if (response.data.status === 1) {
                            // 如果请求成功，更新details和图片列表
                            this.details = response.data.data;
                            this.imgList = this.details.img ? this.details.img.split(',') : [];
                            this.fetchRecommendList(this.details.id); // 获取推荐商品列表
                        } else {
                            console.error('获取详情数据失败:', response.data.msg);
                        }
                    })
                    .catch(error => {
                        console.error('获取详情数据时出错:', error);
                    });
            },

            // 获取推荐商品列表数据
            fetchRecommendList(id) {
                if (!id) return;
                // 向服务器请求推荐商品数据
                http.get(`/platform/frontEnd/recommendList?id=${id}`)
                    .then(response => {
                        if (response.data.status === 1) {
                            this.recommendList = response.data.data || []; // 更新推荐商品列表
                        } else {
                            console.error('获取推荐列表失败:', response.data.msg);
                        }
                    })
                    .catch(error => {
                        console.error('获取推荐列表时出错:', error);
                    });
            },

            // 获取用户反馈数据
            fetchFeedbackList() {
                const id = this.$route.query.id; // 从查询参数中获取商品ID
                // 向服务器请求用户反馈数据
                http.get(`/platform/frontEnd/feedbackAccountid?feedbackAccountid=${id}`)
                    .then(response => {
                        if (response.data.status === 1) {
                            this.feedbackList = response.data.data || []; // 更新反馈列表
                        } else {
                            console.error('获取用户反馈数据失败:', response.data.msg);
                        }
                    })
                    .catch(error => {
                        console.error('获取用户反馈数据时出错:', error);
                    });
            }
        }
    }
</script>

<style scoped>
    .motou{
        font-size: 1rem!important;
    }
    .gongxi2 {
        height: 20px;
    }

    .errong {
        padding-left: 30px;
        padding-right: 30px;
    }

    .card-body > p {
        margin-bottom: 0 !important;
    }

    .container {
        padding-left: 20px;
        padding-right: 20px;
    }

    .LeftPart {
        margin-top: 10px;
    }

    /*模态框按钮颜色以及去边框*/
    .toushu {
        background-color: #00c26f !important;
        border: none !important;
    }

    .btn {
        font-size: 0.6rem;
        padding: 0.2rem 0.5rem;
        line-height: 1.5;
    }

    .btn-outline-success {
        color: #00c26f;
        border-color: #00c26f;
    }

    .feedback-submit-btn {
        line-height: 2.0;
        font-size: 0.9rem !important;
    }

    .me-2 {
        margin-right: 0.5rem;
    }

    .pp {
        padding-left: 0.8rem !important;
        padding-right: 0 !important;
        border-radius: 2px;
    }

    .col-lg-4 {
        margin-left: 20px;
    }

    .col-lg-8 {
        width: calc(100% - 20px - 33.333333%);
    }

    .zuo {
        padding-left: 10px !important;
    }

    .shou {
        font-style: normal;
    }

    .zhuzi {
        font-size: 0.8rem;
    }

    .jiushi {
        font-size: 0.9rem;
    }

    .indented {
        text-indent: 2em;
    }

    .fk-ad {
        text-align: center;
        padding-bottom: 2rem;
    }

    .bi-bag-heart {
        margin-right: 5px;
    }

    .bi-eye {
        margin-right: 5px;
    }

    .ruhe {
        font-size: 0.6rem;
    }

    .bi-telephone-fill {
        font-size: 10px;
    }

    h4 {
        font-size: 0.8rem !important;
    }
     /*模态框输入框里字体大小*/
    .form-control {
        font-size: 1rem !important;
    }

    .qing {
        padding-left: 0 !important;
    }

    .code-input {
        width: 110px;
    }

    .modal-dialog {
        width: 40%;
        max-height: 50%;
        margin: auto;
    }

    .modal-body {
        padding: 0;
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem;
        border-bottom: 1px solid #dee2e6;
    }

    .modal-content {
        position: relative;
        background-color: white;
        border: 1px solid #dee2e6;
        margin: auto;
    }

    .modal-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: transform 0.2s ease;
        cursor: grab;
    }

    .modal-image:active {
        cursor: grabbing;
    }

    .outside-close {
        position: fixed;
        top: 10px;
        right: 10px;
        background-color: rgba(255, 255, 255, 0.3);
        border: none;
        font-size: 1rem;
        cursor: pointer;
        z-index: 1051;
        opacity: 0.7;
    }

    .outside-close:hover {
        opacity: 1;
    }

    .modal-controls {
        position: fixed;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        gap: 10px;
        z-index: 1051;
    }

    .btn-control {
        background-color: transparent;
        border: none;
        font-size: 1.5rem;
        opacity: 0.7;
        color: white;
    }

    .btn-control:hover {
        opacity: 1;
    }

    .rounded-top-2 {
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
    }

    .rounded-bottom-2 {
        border-bottom-left-radius: 2px;
        border-bottom-right-radius: 2px;
    }

    .login-register-link {
        color: #18b371 !important;
        font-size: inherit;
        text-decoration: none;
        padding: 0;
    }

    .login-register-link:hover {
        text-decoration: underline;
    }

    @keyframes shake {
        0%, 100% {
            transform: translateX(0);
        }
        10%, 30%, 50%, 70%, 90% {
            transform: translateX(-10px);
        }
        20%, 40%, 60%, 80% {
            transform: translateX(10px);
        }
    }

    .shake-animation {
        animation: shake 0.5s;
    }

    /*四大按钮的字体大小和内边距*/
    .button-large {
        padding: 0.3rem 0.7rem;
        font-size: 0.9rem !important;
    }
    .button-large2{
        padding: 0.3rem 0.7rem;
        font-size: 1rem !important;
    }

    .ZhaiCardPC {
        --bs-gutter-x: 0!important;
    }

    .rr {
        --bs-gutter-x: 1.5rem!important;
    }

    @keyframes shake {
        0%, 100% {
            transform: translateX(0);
        }
        10%, 30%, 50%, 70%, 90% {
            transform: translateX(-2px);
        }
        20%, 40%, 60%, 80% {
            transform: translateX(2px);
        }
    }

    .shake-animation {
        animation: shake 0.5s;
    }

    .hover-hand {
        cursor: pointer;
    }

    /* 调整模态框垂直居中并向上偏移 */
    .modal-dialog-centered {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100vh; /* 保持模态框在视口中垂直居中 */
    }

    .modal-content {
        margin: auto;
        transform: translateY(-45%); /* 将模态框向上移动20% */
    }
    .feedback-modal {
        width:40%; /* 你可以调整这个百分比以达到你想要的宽度 */
        max-width: 800px; /* 设置最大宽度以避免过宽 */
        margin: auto;
    }
    .upgrade-vip-link {
        color: #00c26f !important;
        font-size: inherit;
        text-decoration: none;
        cursor: pointer;
    }

    .upgrade-vip-link:hover {
        text-decoration: underline;
    }



</style>
