<template>
    <!-- 防骗指南 -->
    <!-- 导航栏 -->
    <ZhaiHeadPC class="zhai-head"></ZhaiHeadPC>

    <!-- 主体内容容器 -->
    <div class="container errong">
        <div class="container-fluid mt-3">
            <div class="row shengse pb-2 mt-2">
                <div class="dixian px-3 py-2 d-flex justify-content-between">
                    <div class="lf">
                        <i class="bi bi-eye"></i> <!-- 添加眼睛图标 -->
                        防骗指南
                    </div>
                </div>
                <div class="p-3 gohome">
                    <h1>为了让广大用户避免上当受骗，大家做到以下几点，基本可以有效预防。</h1>
                    <h1>防骗第一条：凡是有二次消费的，皆不是本站产品！</h1>
                    <h1>防骗第二条：使用之前先通过下方说明查询该账号</h1>
                    <p style="color: #00c26f;">
                        本站为VIP用户提供了 <a href="/contactsearch.php">账号查询</a> 功能，通过联系方式查询，可以查到与该账号或文章匹配的所有帖子，以及用户反馈。可以有效避坑、防骗。
                    </p>
                </div>
            </div>
        </div>
    </div>
    <!-- 只是占据空间 -->
    <div class="gongxi2"></div>

    <!-- 公共底部 -->
    <ZhaiFootPC></ZhaiFootPC>
</template>

<script>
    import ZhaiHeadPC from "../componentspc/ZhaiHeadPC"; // 导入导航栏组件
    import ZhaiFootPC from "../componentspc/ZhaiFootPC"; // 导入底部组件

    export default {
        name: "PWayHome", // 组件名称
        components: { ZhaiHeadPC, ZhaiFootPC } // 注册组件
    }
</script>

<style scoped>
    /* 使用CSS Reset来确保不同浏览器的一致性 */
    /** {*/
        /*margin: 0;*/
        /*padding: 0;*/
        /*box-sizing: border-box;*/
    /*}*/

    body {
        font-family: Arial, sans-serif;
    }

    .gongxi2 {
        height: 20px;
    }

    /* 最大容器的左右内边距 */
    .errong {
        padding-left: 30px;
        padding-right: 30px;
    }

    /* 文本的样式 */
    .gohome h1 {
        font-size: 1.5rem;
        margin-top: 2rem;
        color: #00c26f;
        padding-left: 15px;
        padding-right: 15px;
    }

    .gohome p {
        font-size: 1rem;
        padding: 1rem 0;
        color: #000;
        line-height: 2rem;
        padding-left: 15px;
        padding-right: 15px;
    }

    @media (min-width: 768px) {
        .zhai-head {
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 1000;
        }
        .errong {
            margin-top: 80px; /* 根据导航栏高度调整 */
        }
    }
</style>
