<template>
    <!--PC找回用户名-->
    <!--导航栏-->
    <ZhaiHeadPC class="zhai-head"></ZhaiHeadPC>
    <!--展示文字-->
    <div class="facai">恭喜发财</div>
    <!--主体内容-->
    <div class="container-fluid mt-3 shengse px-0 deng">
        <div class="dixian px-4 py-2 d-flex justify-content-between">
            <div><i class="bi bi-person-add"></i>&nbsp;找回用户名</div>
        </div>
        <div class="px-5 zhai-s pb-4" v-if="!username">
            <div class="mt-2">
                <input type="email" class="form-control" id="exampleFormControlInput4" v-model="myform.email" placeholder="邮箱">
            </div>
            <text class="tishi">*请输入注册时使用的邮箱</text>

            <div class="mt-3">
                <input type="password" class="form-control" id="password" v-model="myform.password" placeholder="请输入密码">
            </div>
            <text class="tishi">*请输入注册时密码</text>

            <!-- 按钮 -->
            <div class="col-12 mt-4 text-center">
                <button type="button" @click="retrieveUsername" class="btn btn-success w-100">找回用户名</button>
            </div>

        </div>

        <!-- 结果展示 -->
        <div class="px-5 zhai-s mt-2" v-if="username">
            <div>用户名：<span>{{ username }}</span></div>
            <!-- 登录 -->
            <div class="col-12 mt-2 denglu text-center">
                <button type="button" @click="goToLogin" class="btn btn-success w-100 anniu">返回登录</button>
            </div>
            <!-- 登录 -->
            <div class="gongxi2"></div>
        </div>
    </div>
    <!--只是占据空间-->
    <div class="gongxi2"></div>

    <!--公共底部-->
    <ZhaiFootPC></ZhaiFootPC>

    <!-- 错误模态框 -->
    <div class="modal fade" id="loginWarningModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style="width: 300px;">
            <div class="modal-content shake-modal">
                <div class="modal-body text-center d-flex align-items-center justify-content-center">
                    <i class="bi bi-emoji-frown" style="font-size: 2rem; color: #f16b6b;"></i>
                    <p class="mb-0 ms-2" style="font-size: 1rem; color: #f16b6b;">{{ errorMessage }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import http from "../utils/http.js"; // 导入 http 请求模块
    import ZhaiHeadPC from "../componentspc/ZhaiHeadPC"; // 导入导航栏组件
    import ZhaiFootPC from "../componentspc/ZhaiFootPC"; // 导入底部组件
    import 'bootstrap/dist/css/bootstrap.min.css';
    import { Modal } from 'bootstrap';

    export default {
        name: "PRetrieveUserName", // 组件名称
        components: { ZhaiHeadPC, ZhaiFootPC }, // 注册组件
        data() {
            return {
                myform: {
                    email: "", // 邮箱
                    password: "" // 密码
                },
                username: null, // 存储找回的用户名
                errorMessage: '' // 存储错误信息
            };
        },
        methods: {
            retrieveUsername() {
                console.log('找回用户名按钮点击');

                // 前端验证
                if (!this.myform.email || !this.myform.password) {
                    this.errorMessage = "必填项不可为空";
                    let modal = new Modal(document.getElementById('loginWarningModal'));
                    modal.show();

                    // 模态框抖动并在2秒后消失
                    setTimeout(() => {
                        document.querySelector('.shake-modal').classList.add('shake');
                    }, 100);
                    setTimeout(() => {
                        modal.hide();
                    }, 2000);
                    return;
                }

                // 准备找回用户名请求
                let url = "/platform/frontEnd/rUsernName1"; // 找回用户名接口 URL
                let fm = new FormData(); // 创建 FormData 对象
                fm.append("email", this.myform.email); // 添加邮箱到 FormData
                fm.append("password", this.myform.password); // 添加密码到 FormData

                // 发送 POST 请求
                http.post(url, fm, true).then(response => {
                    console.log(response); // 打印完整的响应以调试
                    if (response.data.status === 1) {
                        console.log('用户名:', response.data.data); // 打印用户名
                        this.username = response.data.data; // 更新用户名
                    } else {
                        this.errorMessage = response.data.msg; // 更新错误信息
                        // 显示错误信息模态框
                        let modal = new Modal(document.getElementById('loginWarningModal'));
                        modal.show();

                        // 模态框抖动并在2秒后消失
                        setTimeout(() => {
                            document.querySelector('.shake-modal').classList.add('shake');
                        }, 100);
                        setTimeout(() => {
                            modal.hide();
                        }, 2000);
                    }
                }).catch(error => {
                    console.error('请求失败:', error);
                    this.errorMessage = error.response ? error.response.data.msg : '请求失败，请稍后再试'; // 更新错误信息
                    // 显示错误信息模态框
                    let modal = new Modal(document.getElementById('loginWarningModal'));
                    modal.show();

                    // 模态框抖动并在2秒后消失
                    setTimeout(() => {
                        document.querySelector('.shake-modal').classList.add('shake');
                    }, 100);
                    setTimeout(() => {
                        modal.hide();
                    }, 2000);
                });
            },
            goToLogin() {
                this.$router.push('/login'); // 跳转到登录页面
            }
        },
        mounted() {
            console.log('组件挂载完成');
        }
    }
</script>

<style scoped>
    /*主体与顶端的内边距*/
    .zhai-s {
        padding-top: 10px;
    }

    /*提示文字红色*/
    .tishi {
        color: red;
        font-size: 0.9rem;
    }

    /*恭喜发财的样式*/
    .facai {
        font-size: 20px;
        color: lightslategrey;
        text-align: center;
    }

    /*底部不占据空间*/
    .gongxi2 {
        height: 30px;
    }

    /*按钮颜色字体大小*/
    .btn-success {
        background-color: #00c26f !important;
        font-size: 0.9rem;
        border: none !important;
    }

    /*输入框内和提示文字的大小*/
    .form-control {
        font-size: 0.9rem;
    }

    .deng {
        max-width: 35%;
        margin: 0 auto;
    }
    @media (min-width: 768px) {
        .zhai-head {
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 1000;
        }
        .facai {
            margin-top: 95px; /* 确保文字在导航栏下方显示 */
        }
        .container-fluid {
            margin-top: 20px; /* 根据导航栏高度调整 */
        }
    }
    .modal-body {
        padding: 0px !important; /* 将内边距设置为0px，并使用 !important 覆盖原有样式 */
    }

    /* 抖动动画 */
    @keyframes shake {
        0% { transform: translateX(0); }
        25% { transform: translateX(-5px); }
        50% { transform: translateX(5px); }
        75% { transform: translateX(-5px); }
        100% { transform: translateX(0); }
    }

    .shake {
        animation: shake 0.5s;
    }
</style>
