<template>
    <!--手机端账号查询-->
    <!--导航栏-->
    <ZhaiHead></ZhaiHead>
    <!--主体内容-->
    <div class="container-fluid zhu py-2">
        <!--三个按钮-->
        <ZhaiThreeTuttons></ZhaiThreeTuttons>
    </div>
    <!--主体-->
    <div class="container-fluid shengse">
        <div class="row shengse">
            <div class="qc px-4 py-2">账号查询</div>
            <div class="alert alert-success cha">通过账号查询，可以查到与该账号匹配的所有帖子，以及用户反馈。可以有效避坑、防骗。</div>
            <!-- 查询输入框和按钮 -->
            <div class="layui-inline d-flex align-flex-end">
                <div class="chaxun px-2 py-2 huiziti">账号</div>
                <input v-model="contactInfo" placeholder="QQ,微信,电话" class="huiziti kuan">
            </div>
            <div class="input-group mt-3 px-3 mb-3">
                <button type="button" class="btn btn-success mx-auto" @click="handleSubmit">查询</button>
            </div>
        </div>
    </div>
    <div v-if="posts.length" class="container-fluid mt-2 px-0">
        <div class="row card-container">
            <div class="col-md-4 mb-1 card" v-for="post in posts" :key="post.id">
                <ZhaiCard :data="post"></ZhaiCard>
            </div>
        </div>
        <!-- 分页导航 -->
        <div class="bg-white my-2 p-3 G-Field text-center d-flex justify-content-center align-items-center">
            <a class="page-link pagination-btn" href="#" aria-label="Previous" @click.prevent="prevPage" :disabled="currentPage === 1">
                <span aria-hidden="true">«</span>
            </a>
            <span style="margin: 0 1rem;">第 {{ currentPage }} 页，共 {{ totalPages }} 页</span>
            <a class="page-link pagination-btn" href="#" aria-label="Next" @click.prevent="nextPage" :disabled="currentPage === totalPages">
                <span aria-hidden="true">»</span>
            </a>
        </div>
    </div>
    <div v-if="showNoData" class="bg-white my-2 p-3 G-Field text-center">
        <div class="text-center" style="font-size: 0.9rem;">未查询到相关数据</div>
    </div>
    <!-- 公共底部 -->
    <ZhaiFoot></ZhaiFoot>

    <!-- 必填项不能为空模态框 -->
    <div class="modal fade" id="emptyFieldModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style="width:200px;">
            <div class="modal-content">
                <div class="modal-body text-center d-flex align-items-center justify-content-center">
                    <i class="bi bi-emoji-frown" style="font-size: 2rem; color: #f16b6b;"></i>
                    <p class="mb-0 ms-2" style="font-size: 1rem; color: #f16b6b;">必填项不能为空</p>
                </div>
            </div>
        </div>
    </div>

    <!-- 格式错误模态框 -->
    <div class="modal fade" id="formatErrorModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style="width: 320px;">
            <div class="modal-content">
                <div class="modal-body text-center d-flex align-items-center justify-content-center">
                    <i class="bi bi-emoji-frown" style="font-size: 2rem; color: #f16b6b;"></i>
                    <p class="mb-0 ms-2" style="font-size: 1rem; color: #f16b6b;">请输入正确的QQ、微信、电话</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ZhaiHead from "../components/ZhaiHead";
    import ZhaiFoot from "../components/ZhaiFoot";
    import ZhaiThreeTuttons from "../components/ZhaiThreeTuttons";
    import ZhaiCard from "../components/ZhaiCard";
    import http from "../utils/http.js";
    import { Modal } from 'bootstrap';

    export default {
        name: "MQueryAccount",
        components: { ZhaiHead, ZhaiFoot, ZhaiThreeTuttons, ZhaiCard },
        data() {
            return {
                contactInfo: '',
                posts: [],
                currentPage: 1,
                pageSize: 24,
                totalPages: 1,
                showNoData: false,
            };
        },
        methods: {
            handleSubmit() {
                if (!this.contactInfo) {
                    let modalElement = document.getElementById('emptyFieldModal');
                    let modal = new Modal(modalElement);
                    modalElement.classList.add('shake');
                    modal.show();

                    // 2秒后移除模态框并隐藏
                    setTimeout(() => {
                        modal.hide();
                        modalElement.classList.remove('shake');
                    }, 2000);
                } else if (!this.isValidContactInfo(this.contactInfo)) {
                    let modalElement = document.getElementById('formatErrorModal');
                    let modal = new Modal(modalElement);
                    modalElement.classList.add('shake');
                    modal.show();

                    // 2秒后移除模态框并隐藏
                    setTimeout(() => {
                        modal.hide();
                        modalElement.classList.remove('shake');
                    }, 2000);
                } else {
                    this.fetchPosts();
                }
            },
            isValidContactInfo(contactInfo) {
                const regex = /^[a-zA-Z0-9_\-@.]+$/;
                return regex.test(contactInfo);
            },
            fetchPosts() {
                const url = `/platform/frontEnd/searchByContact?contactInformation=${this.contactInfo}&pageNum=${this.currentPage}&pageSize=${this.pageSize}&sortBy=collection`;
                http.get(url)
                    .then(response => {
                        if (response.data && response.data.status === 1) {
                            this.posts = response.data.data.list;
                            this.totalPages = response.data.data.pages;
                            this.showNoData = false;
                        } else if (response.data.status === -29) {
                            this.posts = [];
                            this.totalPages = 1;
                            this.showNoData = true;
                        } else {
                            console.error('获取信息失败:', response.data.msg);
                        }
                    })
                    .catch(error => {
                        console.error('API 请求错误:', error);
                    });
            },
            prevPage() {
                if (this.currentPage > 1) {
                    this.currentPage--;
                    this.fetchPosts();
                }
            },
            nextPage() {
                if (this.currentPage < this.totalPages) {
                    this.currentPage++;
                    this.fetchPosts();
                }
            }
        }
    };
</script>

<style scoped>
    /* 提示框样式 */
    .alert-success {
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
    }

    .alert {
        position: relative;
        padding: 0.75rem 1.25rem;
        margin-bottom: 1rem;
        border: 1px solid transparent;
        border-radius: 0.25rem;
        font-size: 0.85rem;
    }

    /* 输入框的样式 */
    .kuan {
        flex-grow: 1;
        border: 1px solid #dbdbdb;
        outline: none;
        box-shadow: none;
        border-left: 1px solid #dbdbdb !important;
        border-top: 1px solid #dbdbdb !important;
    }

    /* 查询按钮的样式 */
    .btn-success {
        background-color: #00c26f !important;
        border: none !important;
    }

    .zhu {
        margin-top: 80px;
    }

    /* 卡片容器的布局样式 */
    .card-container {
        display: flex;
        flex-wrap: wrap;
        margin-left: -1px;
        margin-right: -1px;
    }

    .col-md-4 {
        padding-left: 2px;
        padding-right: 2px;
        display: flex;
    }

    /* 分页按钮的样式 */
    .pagination-btn {
        background: none;
        border: 1px solid #dee2e6;
        border-radius: 2px;
        cursor: pointer;
        padding: 0.3rem 0.75rem;
        font-size: 1.2rem;
        color: black;
        text-decoration: none;
        display: flex;
        align-items: center;
        transition: background-color 0.3s ease;
    }

    .pagination-btn:hover {
        background-color: #f8f9fa;
        border-color: #dee2e6;
    }

    .pagination-btn:disabled {
        color: #dee2e6;
        cursor: not-allowed;
        border-color: #dee2e6;
    }
    .modal-dialog {
        width: 300px;
        margin: 0 auto; /* 确保水平居中 */
    }
    .modal-body {
        padding: 0px !important; /* 将内边距设置为8px，并使用 !important 覆盖原有样式 */
    }
    @keyframes shake {
        0%, 100% {
            transform: translateX(0);
        }
        20%, 60% {
            transform: translateX(-10px);
        }
        40%, 80% {
            transform: translateX(10px);
        }
    }

    .modal.shake {
        animation: shake 0.5s; /* 抖动动画持续0.5秒 */
    }

</style>
