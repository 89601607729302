<template>
    <div class="px-3">
        <div class="fakubox">
            <div class="fk-u">{{ data.username }}<span>{{ data.userlevel }}</span><div class="fk-t">{{ data.date }}</div></div>
            <p>{{ data.message }}</p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ZhaiComment",
        props: {
            data: {
                type: Object,
                required: true
            }
        }
    }
</script>

<style scoped>
    /*反馈内容大框*/
    .fakubox {
        background: #f5f7f8;
        margin-bottom: 0.8rem;
        padding: 0.7rem;
        font-size: 0.8rem!important;
    }
    /*用户名等级和反馈时间的样式*/
    .fk-u {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
        font-weight: 600;
        color: #2b2b37;
        height: 2rem;
        line-height: 1.5rem;
        font-size: 0.8rem!important;
    }
    /*反馈时间的样式*/
    .fakubox .fk-t {
        float: right;
        font-weight: 400;
        font-size: 0.6rem!important;
    }
    /*用户vip等级的样式*/
    .fakubox .fk-u span {
        background: #f16b6b;
        color: #fff;
        font-size: 0.6rem;
        padding: 3px 8px;
        border-radius: 5px;
        margin-left: 8px;
    }
</style>
