<template>
    <!--手机端详情页-->
    <!-- 导航栏 -->
    <ZhaiHead></ZhaiHead>
    <div class="container-fluid zhu py-2">
        <!-- 详情顶部 -->
        <div class="row shengse py-2">
            <div class="px-4">
                <div><span class="Title">{{ details.name }}</span></div>
                <div class="ri mb-1">
                    <small class="bi bi-clock ziti" id="upload-date">
                        <span>&nbsp;{{ details.createTime ? details.createTime.substr(0, 10) : '' }}&nbsp;&nbsp;</span>
                    </small>
                    <small class="bi bi-person-fill ziti" id="collect"><span>&nbsp;匿名</span></small>
                </div>
                <div>
                    <div class="py-1 jiushi"><span class="jiacu">所属地区：</span>&nbsp;{{ details.province }}-{{ details.city }}</div>
                    <div class="py-1 jiushi"><span class="jiacu">账号年龄：</span>&nbsp;{{ details.age }}</div>
                    <div class="py-1 jiushi"><span class="jiacu">价格：</span>&nbsp;{{ details.price }}</div>
                    <div class="py-1 jiushi"><span class="jiacu">等级：</span>&nbsp;{{ details.level }}</div>
                    <div class="py-1 jiushi"><span class="jiacu">特点：</span>&nbsp;{{ details.condition }}</div>
                    <div class="mt-2">
                        <button type="button" @click="toggleCollect" class="btn btn-outline-success bi-bag-heart">{{ isCollected ? '已收藏' : '收藏' }}</button>
                        <button type="button" @click="applyDel" class="btn btn-outline-success bi-trash3">申请删除</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- 详情顶部 -->

        <!-- 商品部分 -->
        <div class="row shengse mt-2">
            <div class="dixian px-4 py-2 d-flex justify-content-between">
                <div><i class="shou bi bi-telephone-fill"></i>商品</div>
                <div class="iden">
                    <a class="text-decoration-none ruhe zitiyanse" @click="showModalInfo('myModal5')">如何鉴别？</a>
                </div>
            </div>
        </div>
        <!-- 商品部分 -->

        <!-- 如何鉴别 -->
        <div class="row shengse pt-4 pb-0">
            <div class="px-4">
                <div>
                    <div class="alert alert-warning zhuzi" role="alert">在 Bootstrap 5 中并没有直接名为 border-solid 的类。相反，Bootstrap 5 使用了 border-style-solid 作为类名的一部分。但由于 Bootstrap 5 默认边框样式就是实线，所以实际上你不需要特别指定 border-style-solid。只需要使用 border-bottom 就足够了</div>
                </div>
            </div>
        </div>
        <!-- 如何鉴别 -->

        <!-- 账号详情 -->
        <div class="row shengse pb-4">
            <div class="px-4">
                <template v-if="!isLoggedIn">
                    <div class="py-1 jiushi"><span class="jiacu">账号：</span>&nbsp;请先<router-link to="/login" class="login-register-link">登录</router-link>或<router-link to="/register" class="login-register-link">注册</router-link></div>
                    <div class="py-1 jiushi"><span class="jiacu">微信：</span>&nbsp;请先<router-link to="/login" class="login-register-link">登录</router-link>或<router-link to="/register" class="login-register-link">注册</router-link></div>
                    <div class="py-1 jiushi"><span class="jiacu">邮箱：</span>&nbsp;请先<router-link to="/login" class="login-register-link">登录</router-link>或<router-link to="/register" class="login-register-link">注册</router-link></div>
                    <div class="py-1 jiushi"><span class="jiacu">注意事项：</span>&nbsp;请先<router-link to="/login" class="login-register-link">登录</router-link>或<router-link to="/register" class="login-register-link">注册</router-link></div>
                    <div class="py-1 jiushi"><span class="jiacu">手机号：</span>&nbsp;请先<router-link to="/login" class="login-register-link">登录</router-link>或<router-link to="/register" class="login-register-link">注册</router-link></div>
                    <div class="py-1 jiushi"><span class="jiacu">来源：</span>&nbsp;请先<router-link to="/login" class="login-register-link">登录</router-link>或<router-link to="/register" class="login-register-link">注册</router-link></div>
                </template>
                <template v-else-if="vipExpired">
                    <div class="py-1 jiushi"><span class="jiacu">账号：</span>&nbsp;VIP可以无限制查看信息，请&nbsp;<router-link to="/upgradeVIP" class="upgrade-vip-link">升级VIP</router-link></div>
                    <div class="py-1 jiushi"><span class="jiacu">微信：</span>&nbsp;VIP可以无限制查看信息，请&nbsp;<router-link to="/upgradeVIP" class="upgrade-vip-link">升级VIP</router-link></div>
                    <div class="py-1 jiushi"><span class="jiacu">邮箱：</span>&nbsp;VIP可以无限制查看信息，请&nbsp;<router-link to="/upgradeVIP" class="upgrade-vip-link">升级VIP</router-link></div>
                    <div class="py-1 jiushi"><span class="jiacu">注意事项：</span>&nbsp;VIP可以无限制查看信息，请&nbsp;<router-link to="/upgradeVIP" class="upgrade-vip-link">升级VIP</router-link></div>
                    <div class="py-1 jiushi"><span class="jiacu">手机号：</span>&nbsp;VIP可以无限制查看信息，请&nbsp;<router-link to="/upgradeVIP" class="upgrade-vip-link">升级VIP</router-link></div>
                    <div class="py-1 jiushi"><span class="jiacu">来源：</span>&nbsp;VIP可以无限制查看信息，请&nbsp;<router-link to="/upgradeVIP" class="upgrade-vip-link">升级VIP</router-link></div>
                </template>
                <template v-else>
                    <div v-if="details.account" class="py-1 jiushi"><span class="jiacu">账号：</span>&nbsp;{{ details.account }}</div>
                    <div v-if="details.remark" class="py-1 jiushi"><span class="jiacu">微信：</span>&nbsp;{{ details.remark }}</div>
                    <div v-if="details.email" class="py-1 jiushi"><span class="jiacu">邮箱：</span>&nbsp;{{ details.email }}</div>
                    <div v-if="details.attention" class="py-1 jiushi"><span class="jiacu">注意事项：</span>&nbsp;{{ details.attention }}</div>
                    <div v-if="details.phone" class="py-1 jiushi"><span class="jiacu">手机号：</span>&nbsp;{{ details.phone }}</div>
                    <div v-if="details.source" class="py-1 jiushi"><span class="jiacu">来源：</span>&nbsp;{{ details.source }}</div>
                </template>
                <div class="py-1 zhuzi">
                    <span style="color:red">！！！警告：凡是需要二次消费的皆非我站产品！</span>
                </div>
                <div class="mt-2">
                    <button @click="showModalInfo('myModal4')" type="button" class="btn btn-outline-success bi-eye">防骗指南</button>
                    <button @click="showModalInfo('myModal3')" type="button" class="btn btn-outline-success bi-info-circle">信息规则</button>
                </div>
            </div>
        </div>
        <!-- 账号详情 -->

        <!-- 详细介绍 -->
        <div class="row shengse mt-2">
            <div class="dixian px-4 py-2 d-flex justify-content-between">
                <div><i class="shou bi bi-info-circle"></i>详细介绍</div>
            </div>
        </div>
        <!-- 详细介绍 -->

        <!-- 截图部分 -->
        <div v-viewer class="row shengse pb-4 image-container" id="images" ref="gallery">
            <div class="col-6 p-3" v-for="(img, index) in imgList" :key="index">
                <img class="Img w-100 clickable-img" :src="img" :alt="'图片' + (index + 1)">
            </div>
            <div class="px-3 pb-3 mt-3">
                <p class="mb-2 indented" style="font-size:1rem;">
                    {{ details.history }}
                </p>
            </div>
        </div>
        <!-- 截图部分 -->

        <!-- 用户反馈 -->
        <div class="row shengse mt-2">
            <div class="dixian px-4 py-2 mb-3 d-flex justify-content-between">
                <div><i class="shou bi bi-chat-dots-fill"></i>用户反馈</div>
            </div>
            <!-- 反馈列表 -->
            <ZhaiComment v-for="(feedback, index) in feedbackList" :key="index" :data="feedback"></ZhaiComment>
            <div class="fk-ad">
                <button type="button" @click="checkLoginBeforeFeedback" class="btn btn-outline-success mx-auto bi-file-plus-fill">我要反馈</button>
            </div>
        </div>
        <!-- 用户反馈 -->
    </div>

    <!--本地其他推荐部分-->
    <div class="col-lg-4">
        <div class="bdcard">
            <div class="row rr mt-4">
                <div class="deziti zuo px-4 pb-2 d-flex justify-content-between">
                    <div><i class="shou bi bi-geo-alt-fill"></i>本地其他推荐</div>
                </div>
                <!-- 显示推荐列表 -->
                <div v-if="recommendList.length > 0">
                    <div v-for="(item, index) in recommendList" :key="index" class="no-underline mb-1">
                        <ZhaiCard :data="item" @click="updateRoute(item.id)"></ZhaiCard>
                    </div>
                </div>
                 <!--如果推荐列表为空，显示提示信息 -->
                <div v-else class="text-center text-muted py-4">
                    暂无推荐内容
                </div>
            </div>
        </div>
    </div>

    <!-- 公共底部 -->
    <ZhaiFoot></ZhaiFoot>
    <!-- 公共底部 -->

    <!-- 登录警示 模态框 -->
    <div class="modal fade" id="loginWarningModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style="max-width: 150px;">
            <div class="modal-content">
                <div class="modal-body text-center d-flex align-items-center justify-content-center">
                    <i class="bi bi-emoji-frown" style="font-size: 1.5rem; color: #f16b6b;"></i>
                    <p class="mb-0 ms-2" style="font-size: 0.9rem; color: #f16b6b;">请先登录！</p>
                </div>
            </div>
        </div>
    </div>

    <!-- 成功模态框 -->
    <div class="modal fade" id="successModal" tabindex="-1" aria-labelledby="successModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style="max-width: 200px;">
            <div class="modal-content">
                <div class="modal-body d-flex align-items-center justify-content-center">
                    <i class="bi bi-emoji-smile" style="font-size: 1.5rem; color: #00c26f;"></i>
                    <p class="mb-0 ms-2" style="font-size: 0.9rem; color: #00c26f;"></p>
                </div>
            </div>
        </div>
    </div>

    <!-- 失败模态框 -->
    <div class="modal fade" id="errorModal" tabindex="-1" aria-labelledby="errorModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-shake" style="max-width: 200px;">
            <div class="modal-content shake">
                <div class="modal-body d-flex align-items-center justify-content-center">
                    <i class="bi bi-emoji-frown" style="font-size: 1.5rem; color: #dc3545;"></i>
                    <p class="mb-0 ms-2" style="font-size: 0.9rem; color: #dc3545;"></p>
                </div>
            </div>
        </div>
    </div>

    <!-- 我要反馈 模态框 -->
    <div class="modal fade" id="myModal">
        <div class="modal-dialog">
            <div class="modal-content">
                <!-- 模态框头部 -->
                <div class="modal-header">
                    <h4 class="modal-title motou">我要反馈</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <!-- 模态框内容 -->
                <div class="ant-modal-body my-2">
                    <form class="myform">
                        <div class="form-group mt-3 px-2">
                            <label style="font-size:1rem;">反馈信息：</label>
                            <input type="text" id="feedback-info" class="form-control motou" v-model="feedbackInfo" name="feedback-info" placeholder="" readonly>
                        </div>
                        <div class="form-group mt-4 px-2">
                            <label style="font-size:1rem;">反馈内容：</label>
                            <textarea  class="form-control " id="content" v-model="feedbackContent" placeholder="平台鼓励反馈真实的体验，请勿提交违背事实的虚假反馈，一经发现将关闭你的评论权限，情节严重的将可能直接封号。"></textarea>
                        </div>
                        <div class="form-group mt-4 px-2">
                            <label style="font-size:1rem;">验证码：</label>
                            <div style="max-width: 300px;">
                                <!-- 这里是包裹验证码输入框的 div -->
                                <CaptchaComponent ref="captchaComponent" @validate-captcha="validateCaptcha"></CaptchaComponent>
                            </div>
                        </div>
                        <div class="form-group mt-3">
                            <div class="text-center mb-3">
                                <button type="button" @click="submitFeedback" class="btn btn-success px-5 toushu feedback-submit-btn">提交</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <!-- 申请删除 模态框 -->
    <div class="modal" id="myModal2">
        <div class="modal-dialog">
            <div class="modal-content">
                <!-- 模态框头部 -->
                <div class="modal-header">
                    <h4 class="modal-title motou">申请删除 </h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <!-- 模态框内容 -->
                <div class="modal-body">
                    <div class="ant-modal-body motou">
                        <div>本站所有账号及文档来自网络，如有内容侵犯到你的隐私、权益或未经你的允许而被他人发布，平台很愿意为你删除。请你提供相关证明材料发邮件 至 404489561@gmail.com ，我们通常会在24小时内处理你的请求，最长不会超过48小时，如果事实逻辑清晰我们将及时为你删除。</div>
                    </div>
                </div>
                <!-- 模态框底部 -->
                <div class="modal-footer di text-center">
                    <button type="button" class="btn btn-success toushu" data-bs-dismiss="modal">关闭</button>
                </div>
            </div>
        </div>
    </div>
    <!-- 信息规则 模态框 -->
    <div class="modal" id="myModal3">
        <div class="modal-dialog">
            <div class="modal-content">
                <!-- 模态框头部 -->
                <div class="modal-header">
                    <h4 class="modal-title motou">信息规则</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <!-- 模态框内容 -->
                <div class="modal-body motou">
                    <div>部分信息会隐藏账号信息，需要升级VIP会员来享受无限制查看信息的权限。</div>
                </div>
                <!-- 模态框底部 -->
                <div class="modal-footer di text-center">
                    <button type="button" class="btn btn-success toushu" data-bs-dismiss="modal">关闭</button>
                </div>
            </div>
        </div>
    </div>
    <!-- 防骗 模态框 -->
    <div class="modal" id="myModal4">
        <div class="modal-dialog">
            <div class="modal-content">
                <!-- 模态框头部 -->
                <div class="modal-header">
                    <h4 class="modal-title motou">防骗指南</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <!-- 模态框内容 -->
                <div class="modal-body">
                    <p class="px-3 motou">
                        为了让广大用户避免上当受骗,大家做到以下几点,基本可以有效预防: <br>
                        1.本站只提供3年以上的账号,品质卓然,账号的绑定的邮箱、手机、违规记录、使用方向等附属信息都会提供给您；<br>
                        2.本站只在此地址提供服务，其他皆为仿冒；<br>
                        3.除vip会员费用，本站无二次收费；<br>
                        4.建议拿到账号后立即修改账号密码并换绑邮箱或者手机号，以便长期使用；
                    </p>
                </div>
                <!-- 模态框底部 -->
                <div class="modal-footer di text-center">
                    <button type="button" class="btn btn-success toushu" data-bs-dismiss="modal">关闭</button>
                </div>
            </div>
        </div>
    </div>
    <!-- 如何鉴别 模态框 -->
    <div class="modal" id="myModal5">
        <div class="modal-dialog">
            <div class="modal-content">
                <!-- 模态框头部 -->
                <div class="modal-header">
                    <h4 class="modal-title">如何鉴别</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <!-- 模态框内容 -->
                <div class="modal-body motou">
                    当您对账号的质量有所顾虑的时候，建议您先购买季度会员，找到适合您的账号并且感觉本站产品的质量可以的情况下再进行VIP升级；
                </div>
                <!-- 模态框底部 -->
                <div class="modal-footer mt-2 text-center" style="display: block;">
                    <div class="row">
                        <div class="col-6">
                            <button type="button" class="btn btn-success" data-bs-dismiss="modal">关闭</button>
                        </div>
                        <div class="col-6">
                            <router-link to="/avoidFraud" class="input-group text-decoration-none">
                                <button type="button" class="btn btn-success">新手防骗指南</button>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    // 引入相关组件和库
    import { Modal } from 'bootstrap'; // Bootstrap 提供的模态框组件
    import Viewer from 'viewerjs'; // 图片查看库 Viewer.js，用于图片放大查看功能
    import 'viewerjs/dist/viewer.css'; // Viewer.js 的样式文件
    import ZhaiHead from "../components/ZhaiHead"; // 自定义头部组件
    import ZhaiFoot from "../components/ZhaiFoot"; // 自定义底部组件
    import http from "../utils/http.js"; // 封装好的 HTTP 请求工具
    import { mapGetters, mapActions } from 'vuex'; // Vuex 的工具函数，用于获取状态和操作
    import ZhaiComment from "../components/ZhaiComment"; // 自定义评论组件
    import CaptchaComponent from "../componentspc/CaptchaComponent.vue"; // 验证码组件
    import ZhaiCard from "../components/ZhaiCard"; // 自定义卡片组件

    export default {
        name: "MDetails", // 定义组件名称
        components: {
            ZhaiHead,
            ZhaiFoot,
            ZhaiComment,
            CaptchaComponent,
            ZhaiCard
        }, // 注册组件

        // 定义组件的响应式数据
        data() {
            return {
                details: {
                    // 详情页的数据结构
                    name: '',
                    uploadDate: '',
                    collection: '',
                    province: '',
                    city: '',
                    age: '',
                    price: '',
                    level: '',
                    condition: '',
                    account: '',
                    remark:'',
                    email: '',
                    attention: '',
                    phone: '',
                    source: '',
                    img: '',
                    createTime: '',
                    history: '',
                    presets3: '',
                    presets4: '',
                    presets5: ''
                },
                imgList: [], // 图片列表，用于图片展示
                feedbackList: [], // 用户反馈列表
                feedbackInfo: '', // 反馈信息的标题
                feedbackContent: '', // 反馈内容
                isCollected: false, // 收藏状态，是否已收藏
                viewerInstance: null, // Viewer.js 实例，用于图片查看
                recommendList: [] // 推荐列表，用于显示推荐的内容
            };
        },

        // 计算属性，用于处理组件的逻辑
        computed: {
            ...mapGetters(['isLoggedIn', 'getUser']), // 从 Vuex 中获取登录状态和用户信息

            // 检查用户的 VIP 是否过期
            vipExpired() {
                if (!this.isLoggedIn) return false; // 如果用户未登录，直接返回 false
                const expireDate = this.getUser?.level_expire_date ? new Date(this.getUser.level_expire_date) : null; // 获取 VIP 过期日期
                const now = new Date(); // 当前日期
                return !expireDate || expireDate < now; // 判断是否过期
            }
        },

        // 组件创建时的生命周期钩子，用于初始化数据
        created() {
            this.checkLoginStatus(); // 检查登录状态
            this.fetchDetails(); // 获取详情数据
            this.fetchFeedbackList(); // 获取用户反馈列表
            this.checkCollectStatus(); // 检查是否已收藏
            this.fetchRecommendList(); // 获取推荐列表，传入当前详情的 ID
        },

        // 组件挂载时的生命周期钩子，用于执行 DOM 相关的操作
        mounted() {
            this.initViewer(); // 初始化图片查看器
        },

        // 监听属性变化，监听图片列表的变化
        watch: {
            imgList() {
                this.initViewer(); // 当图片列表发生变化时，重新初始化图片查看器
            },
            '$route.query.id'(newId) {
                this.fetchDetails();  // 当路由参数的 id 变化时，重新获取详情数据
                this.fetchFeedbackList();  // 获取新的用户反馈数据
                this.fetchRecommendList();  // 获取新的推荐列表
            }
        },

        // 定义组件的方法
        methods: {
            updateRoute(id) {
                this.$router.push({ query: { id: id } });
            },
            ...mapActions(['logout', 'checkLoginStatus']), // 从 Vuex 中映射出 action

            // 获取详情数据
            fetchDetails() {
                const id = this.$route.query.id; // 从查询参数中获取商品ID
                http.get(`/platform/frontEnd/details?id=${id}`) // 发送 GET 请求，获取详情数据
                    .then(response => {
                        if (response.data.status === 1) { // 如果请求成功
                            this.details = response.data.data; // 更新 details 数据
                            this.feedbackInfo = this.details.name; // 设置反馈信息标题
                            this.imgList = [
                                ...(this.details.img ? this.details.img.split(',').map(img => img.trim()) : []),
                                ...(this.details.presets3 ? this.details.presets3.split(',').map(img => img.trim()) : []),
                                ...(this.details.presets4 ? this.details.presets4.split(',').map(img => img.trim()) : []),
                                ...(this.details.presets5 ? this.details.presets5.split(',').map(img => img.trim()) : [])
                            ]; // 将图片路径转换为数组
                            this.checkCollectStatus(); // 检查是否已收藏
                        } else {
                            console.error('获取详情数据失败:', response.data.msg); // 如果请求失败，打印错误信息
                        }
                    })
                    .catch(error => {
                        console.error('获取详情数据时出错:', error); // 请求出错时，打印错误信息
                    });
            },


            // 获取推荐商品列表数据
            fetchRecommendList() {
                const id = this.details.id || this.$route.query.id; // 获取当前商品的ID
                console.log('当前ID:', id); // 调试: 打印ID以确认是否获取到
                if (!id) {
                    console.error('ID 未定义或为空');
                    return;
                }
                // 向服务器请求推荐商品数据
                http.get(`/platform/frontEnd/recommendList?id=${id}`)
                    .then(response => {
                        if (response.data.status === 1) {
                            this.recommendList = response.data.data || []; // 更新推荐商品列表
                        } else {
                            console.error('获取推荐列表失败:', response.data.msg);
                        }
                    })
                    .catch(error => {
                        console.error('获取推荐列表时出错:', error);
                    });
            },

            // 获取用户反馈列表
            fetchFeedbackList() {
                const id = this.$route.query.id; // 从查询参数中获取商品ID

                http.get(`/platform/frontEnd/feedbackAccountid?feedbackAccountid=${id}`) // 发送 GET 请求，获取用户反馈数据
                    .then(response => {
                        if (response.data.status === 1) {
                            this.feedbackList = response.data.data || []; // 更新反馈列表数据
                        } else {
                            console.error('获取用户反馈数据失败:', response.data.msg); // 如果请求失败，打印错误信息
                        }
                    })
                    .catch(error => {
                        console.error('获取用户反馈数据时出错:', error); // 请求出错时，打印错误信息
                    });
            },

            // 检查是否已收藏
            checkCollectStatus() {
                if (!this.isLoggedIn) return; // 如果用户未登录，则返回

                const accountId = this.details.id; // 获取详情的 ID
                const userId = this.getUser.id; // 获取用户 ID

                if (!userId || !accountId) return; // 如果没有用户 ID 或 详情 ID，则返回

                const postData = {
                    accountid: accountId,
                    userid: userId,
                    action: 'check' // 设置为检查动作
                };

                http.post('/platform/frontEnd/handleCollect', postData) // 发送 POST 请求，检查是否已收藏
                    .then(response => {
                        if (response.data && response.data.status === 1) {
                            this.isCollected = response.data.data.isCollected; // 更新收藏状态
                        } else {
                            console.error('检查收藏状态失败:', response.data.msg); // 如果请求失败，打印错误信息
                        }
                    })
                    .catch(error => {
                        console.error('检查收藏状态时出错:', error); // 请求出错时，打印错误信息
                    });
            },

            // 切换收藏状态
            toggleCollect() {
                if (!this.isLoggedIn) {
                    this.showLoginWarningModal(); // 如果未登录，显示登录警告模态框
                    return;
                }

                const accountId = this.details.id; // 获取详情的 ID
                const userId = this.getUser.id; // 获取用户 ID
                const action = this.isCollected ? 'cancel' : 'collect'; // 根据当前收藏状态，设置为收藏或取消收藏

                if (!userId || !accountId) return; // 如果没有用户 ID 或 详情 ID，则返回

                const postData = {
                    accountid: accountId,
                    userid: userId,
                    action: action // 设置动作为收藏或取消收藏
                };

                http.post('/platform/frontEnd/handleCollect', postData) // 发送 POST 请求，切换收藏状态
                    .then(response => {
                        if (response.data && response.data.status === 1) {
                            this.isCollected = !this.isCollected; // 更新收藏状态
                            this.showModal('successModal', this.isCollected ? '收藏成功' : '取消收藏成功', 'bi bi-emoji-smile', '#00c26f'); // 显示成功模态框
                        } else {
                            this.showModal('errorModal', action === 'collect' ? '收藏失败' : '取消收藏失败', 'bi bi-emoji-frown', '#dc3545'); // 显示失败模态框
                        }
                    })
                    .catch(error => {
                        console.error(`${action === 'collect' ? '收藏' : '取消收藏'}请求错误:`, error); // 请求出错时，打印错误信息
                        this.showModal('errorModal', '请求发生错误，请稍后再试', 'bi bi-emoji-frown', '#dc3545'); // 显示请求错误模态框
                    });
            },

            // 显示登录警告模态框
            showLoginWarningModal() {
                const warningModalElement = document.getElementById('loginWarningModal'); // 获取登录警告模态框元素
                if (!warningModalElement) {
                    console.error(`Modal element with id 'loginWarningModal' not found`); // 如果未找到模态框元素，打印错误信息
                    return;
                }
                const warningModal = new Modal(warningModalElement); // 创建模态框实例
                warningModal.show(); // 显示模态框

                warningModalElement.classList.add('shake-animation'); // 添加抖动动画
                setTimeout(() => {
                    warningModal.hide(); // 隐藏模态框
                    warningModalElement.classList.remove('shake-animation'); // 移除抖动动画
                }, 2000); // 2 秒后隐藏模态框
            },

            // 显示成功或错误模态框
            showModal(modalId, message, iconClass, textColor) {
                const modalElement = document.getElementById(modalId); // 获取模态框元素
                if (!modalElement) {
                    console.error(`Modal element with id '${modalId}' not found`); // 如果未找到模态框元素，打印错误信息
                    return;
                }
                const modalBodyP = modalElement.querySelector('.modal-body p'); // 获取模态框内容的文本元素
                const icon = modalElement.querySelector('.bi'); // 获取模态框内容的图标元素

                icon.className = iconClass; // 设置图标的样式类
                icon.style.color = textColor; // 设置图标的颜色
                modalBodyP.textContent = message; // 设置模态框的文本内容

                const modalInstance = new Modal(modalElement); // 创建模态框实例
                modalElement.classList.add('shake-animation'); // 添加抖动动画
                modalInstance.show(); // 显示模态框

                setTimeout(() => {
                    modalElement.classList.remove('shake-animation'); // 移除抖动动画
                    modalInstance.hide(); // 隐藏模态框
                }, 3000); // 3 秒后隐藏模态框
            },

            // 显示申请删除模态框
            applyDel() {
                const modalInstance = new Modal(document.getElementById('myModal2')); // 创建模态框实例
                modalInstance.show(); // 显示模态框
            },

            // 显示信息模态框
            showModalInfo(modalId) {
                const modalElement = document.getElementById(modalId); // 获取模态框元素
                if (!modalElement) {
                    console.error(`Modal element with id '${modalId}' not found`); // 如果未找到模态框元素，打印错误信息
                    return;
                }
                const modalInstance = new Modal(modalElement); // 创建模态框实例
                modalInstance.show(); // 显示模态框
            },

            // 检查登录状态后显示反馈模态框或警告模态框
            checkLoginBeforeFeedback() {
                const warningModalElement = document.getElementById('loginWarningModal'); // 获取登录警告模态框元素
                const warningModal = new Modal(warningModalElement); // 创建模态框实例

                if (this.isLoggedIn) {
                    const feedbackModal = new Modal(document.getElementById('myModal')); // 获取反馈模态框实例
                    feedbackModal.show(); // 显示反馈模态框
                } else {
                    if (warningModalElement.classList.contains('show')) {
                        warningModalElement.classList.remove('shake-animation'); // 移除抖动动画
                        void warningModalElement.offsetWidth;
                        warningModalElement.classList.add('shake-animation'); // 添加抖动动画
                    } else {
                        warningModalElement.classList.add('shake-animation'); // 添加抖动动画
                        warningModal.show(); // 显示警告模态框
                        setTimeout(() => {
                            warningModal.hide(); // 隐藏模态框
                        }, 2000); // 2 秒后隐藏模态框
                        setTimeout(() => {
                            warningModalElement.classList.remove('shake-animation'); // 移除抖动动画
                        }, 600); // 0.6 秒后移除抖动动画
                    }
                }
            },

            // 提交反馈
            submitFeedback() {
                const codeInput = document.querySelector('.captcha-input'); // 获取验证码输入框元素
                const enteredCaptcha = codeInput ? codeInput.value.trim() : ''; // 获取输入的验证码
                const actualCaptcha = this.$refs.captchaComponent.captchaText; // 获取实际的验证码

                if (!this.feedbackContent || !this.feedbackContent.trim()) {
                    this.showModal('errorModal', '必填项不可为空！', 'bi bi-emoji-frown', '#dc3545'); // 如果反馈内容为空，显示错误模态框
                    return;
                }

                if (!enteredCaptcha) {
                    this.showModal('errorModal', '必填项不可为空！', 'bi bi-emoji-frown', '#dc3545'); // 如果验证码为空，显示错误模态框
                    return;
                }

                if (enteredCaptcha !== actualCaptcha) {
                    this.showModal('errorModal', '验证码错误！', 'bi bi-emoji-frown', '#dc3545'); // 如果验证码错误，显示错误模态框
                    this.$refs.captchaComponent.generateCaptcha(); // 重新生成验证码
                    return;
                }

                const feedbackData = {
                    message: this.feedbackContent,
                    accountid: this.details.id,
                    userid: this.getUser.id,
                    username: this.getUser.username,
                    userlevel: this.getUser.level
                }; // 反馈数据结构

                http.post('/platform/frontEnd/feedback', feedbackData, false, true) // 发送 POST 请求，提交反馈数据
                    .then(response => {
                        if (response.data.status === 1) {
                            this.showModal('successModal', '反馈提交成功', 'bi bi-emoji-smile', '#00c26f'); // 如果提交成功，显示成功模态框
                            const feedbackModal = Modal.getInstance(document.getElementById('myModal'));
                            feedbackModal.hide(); // 隐藏反馈模态框
                        } else {
                            this.showModal('errorModal', `反馈提交失败: ${response.data.msg}`, 'bi bi-emoji-frown', '#dc3545'); // 如果提交失败，显示错误模态框
                        }
                    })
                    .catch(error => {
                        const errorMsg = error.response && error.response.data && error.response.data.msg ? error.response.data.msg : '请求发生错误，请稍后再试';
                        this.showModal('errorModal', `提交反馈时出错: ${errorMsg}`, 'bi bi-emoji-frown', '#dc3545'); // 请求出错时，显示错误模态框
                    });
            },

            // 验证验证码
            validateCaptcha(isValid) {
                if (!isValid) {
                    alert('图形验证码输入不正确！'); // 如果验证码不正确，弹出警告
                }
            },

            // 初始化图片查看器
            initViewer() {
                this.$nextTick(() => {
                    const gallery = this.$refs.gallery; // 获取图片画廊的引用
                    if (gallery) {
                        if (this.viewerInstance) {
                            this.viewerInstance.destroy(); // 销毁现有的 Viewer 实例
                        }
                        this.viewerInstance = new Viewer(gallery, {
                            url: 'src', // 设置图片的 url 属性
                            navbar: true, // 启用导航栏
                            toolbar: true, // 启用工具栏
                            tooltip: true, // 启用工具提示
                            title: true // 启用标题显示
                        });
                    }
                });
            }
        },

        // 组件卸载前的生命周期钩子，用于清理资源
        beforeUnmount() {
            if (this.viewerInstance) {
                this.viewerInstance.destroy(); // 销毁 Viewer 实例
            }
        }
    }
</script>


<style scoped>
    .motou{
        font-size: 1rem!important;
    }
    /* 保持边框颜色和字体颜色为#00c26f */
    .btn-outline-success {
        color: #00c26f;
        border-color: #00c26f;
        background-color: transparent; /* 确保背景色为透明 */
        padding: 0.3rem 0.7rem !important;
        font-size: 0.9rem !important;
    }

    /* 覆盖 hover 状态 */
    .btn-outline-success:hover {
        color: #00c26f;
        background-color: transparent;
        border-color: #00c26f;
        padding: 0.3rem 0.7rem !important;
        font-size: 0.85rem !important;
    }

    /* 覆盖 active 状态 */
    .btn-outline-success:active,
    .btn-outline-success.active,
    .btn-outline-success:focus,
    .btn-outline-success:not(:disabled):not(.disabled):active,
    .btn-outline-success:not(:disabled):not(.disabled).active,
    .show > .btn-outline-success.dropdown-toggle {
        color: #00c26f;
        background-color: transparent;
        border-color: #00c26f;
        box-shadow: none; /* 去掉按钮点击后的阴影效果 */
        padding: 0.3rem 0.7rem !important;
        font-size: 0.9rem !important;
    }

    /* 调整按钮样式 */
    .toushu,
    .feedback-submit-btn {
        background-color: #00c26f !important;
        border: none !important;
        padding: 0.3rem 0.7rem !important;
        font-size: 1rem !important;
    }

    /* hover、active 和 focus 状态的按钮样式 */
    .toushu:hover,
    .toushu:active,
    .toushu.active,
    .toushu:focus,
    .feedback-submit-btn:hover,
    .feedback-submit-btn:active,
    .feedback-submit-btn.active,
    .feedback-submit-btn:focus {
        padding: 0.3rem 0.7rem !important;
        font-size: 0.85rem !important;
    }

    .shou {
        font-style: normal;
    }

    .bi-telephone-fill {
        font-size: 14px;
    }

    .text-primary {
        color: #2b2b37!important;
    }

    .zhuzi {
        font-size: small;
    }

    .jiushi {
        font-size: 90%;
    }

    .indented {
        text-indent: 2em;
    }

    .fk-ad {
        text-align: center;
        padding-bottom: 2rem;
    }

    .bi-bag-heart {
        margin-right: 5px;
    }

    .bi-eye {
        margin-right: 5px;
    }

    .modal {
        margin-top: 4rem !important;
    }

    .ruhe {
        font-size: 0.8rem;
    }

    .zhu {
        margin-top: 80px;
    }

    .viewer-canvas img {
        display: block;
        max-width: 100%;
    }

    /* 登录和注册链接样式 */
    .login-register-link {
        color: #18b371 !important;
        font-size: inherit;
        text-decoration: none;
        padding: 0;
    }

    @keyframes shake {
        0%, 100% {
            transform: translateX(0);
        }
        10%, 30%, 50%, 70%, 90% {
            transform: translateX(-5px);
        }
        20%, 40%, 60%, 80% {
            transform: translateX(5px);
        }
    }

    .shake-animation {
        animation: shake 0.5s;
    }

    /* 添加自定义样式确保模态框居中 */
    #loginWarningModal .modal-dialog,
    #successModal .modal-dialog,
    #errorModal .modal-dialog {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    /* 调整模态框的样式 */
    #loginWarningModal .modal-body,
    #successModal .modal-body,
    #errorModal .modal-body {
        padding: 0.25rem 0.5rem !important; /* 调整内边距 */
    }

    #loginWarningModal .modal-body p,
    #successModal .modal-body p,
    #errorModal .modal-body p {
        font-size: 0.9rem; /* 调整字体大小 */
    }

    #loginWarningModal .modal-body i,
    #successModal .modal-body i,
    #errorModal .modal-body i {
        font-size: 1.5rem; /* 调整图标大小 */
    }
    .form-control{
        font-size: 0.9rem;
    }
    /* 调整模态框的 z-index */
    #myModal {
        z-index: 1051; /* 确保反馈模态框在最高层 */
    }

    #loginWarningModal,
    #successModal,
    #errorModal {
        z-index: 1052; /* 确保其他模态框在反馈模态框之上 */
    }
    .upgrade-vip-link {
        color: #00c26f !important;
        font-size: inherit;
        text-decoration: none;
        cursor: pointer;
    }
    .upgrade-vip-link:hover {
        text-decoration: underline;
    }
</style>

