<template>
    <!--手机端会员中心-->
    <!--导航栏-->
    <ZhaiHead></ZhaiHead>
    <!--导航栏-->
    <!--主体内容-->
    <div class="container-fluid zhu mb-2">
        <ZhaiJiuGong menu="1"></ZhaiJiuGong>
    </div>
    <div class="container-fluid shengse" v-if="getUser">
        <!--会员名字-->
        <div class="row mt-2">
            <div class="dixian px-3 py-2 mb-1  d-flex justify-content-between">
                <div class="py-1"><span>欢迎登录：</span>&nbsp;{{ getUser.username }}</div>
            </div>
        </div>
        <!--会员名字-->
        <div class="mx-2">
            <!--会员等级-->
            <div class="row  py-2 hui">
                <div class="px-3 Profile d-flex align-items-center justify-content-start">
                    <div class="La">会员等级：</div>
                    <span class="niandu changlv">
                                       <strong>
                                     <!-- 判断VIP是否已过期 -->
                                     <template v-if="getUser.level_expire_date && new Date() > new Date(getUser.level_expire_date)">
                                     （VIP已过期）
                                     </template>
                                     <template v-else>
                                     {{ getUser.level }}
                                      </template>
                                     </strong>
                                    </span>
                </div>
            </div>
            <!--会员等级-->
            <!--到期时间-->
            <div class="row huiziti py-2">
                <div class="px-3 Profile d-flex align-items-center justify-content-start">
                    <div class="La">
                        VIP有效期：
                    </div>
                    <div class="niandu">
                        <span>{{ formatExpireDate(getUser.level_expire_date) }}</span>
                    </div>
                </div>
            </div>
            <!--到期时间-->
            <!--支付记录明细-->
            <div class="huiziti py-2"  v-if="!fetchFailed && userEvents.length > 0">
                <div>
                    <div class="La px-1">
                        支付订单：
                    </div>
                    <table class="layui-table">
                        <colgroup>
                            <col width="150">
                            <col width="150">
                            <col width="200">
                            <col>
                        </colgroup>
                        <thead>
                        <tr>
                            <th>订单类型</th>
                            <th>支付金额</th>
                            <th>支付时间</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(event, index) in userEvents" :key="index">
                            <td>{{ event.event }}</td>
                            <td>{{ event.price }}元</td>
                            <td>{{ formatDate(event.create_time) }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!--支付记录明细-->
            <!--VIP明细表格-->
            <div class="huiziti pt-2">
                <table class="table table-bordered" style="margin-top:10px;">
                    <thead>
                    <tr>
                        <th scope="col">类型</th>
                        <th scope="col">价格</th>
                        <th scope="col">查看权限</th>
                        <th scope="col">有效期</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>VIP月度会员</td>
                        <td>¥90</td>
                        <td>所有内容</td>
                        <td>+1月度</td>
                    </tr>
                    <tr>
                        <td>VIP季度会员</td>
                        <td>¥130</td>
                        <td>所有内容</td>
                        <td>+1季度</td>
                    </tr>
                    <tr>
                        <td>VIP年度会员</td>
                        <td>¥300</td>
                        <td>所有内容</td>
                        <td>+1年</td>
                    </tr>
                    <tr>
                        <td>VIP永久会员</td>
                        <td>¥500</td>
                        <td>所有内容</td>
                        <td>永久</td>
                    </tr>
                    </tbody>
                </table>
                <div class="text-center my-3">
                    <router-link to="/upgradeVIP" class="btn btn-primary">购买VIP</router-link>
                </div>
            </div>
            <!--VIP明细表格-->
        </div>
    </div>
    <!--主体内容-->
    <!--公共底部-->
    <ZhaiFoot></ZhaiFoot>
    <!--公共底部-->
</template>

<script>
    import ZhaiHead from "../components/ZhaiHead";
    import ZhaiFoot from "../components/ZhaiFoot";
    import ZhaiJiuGong from "../components/ZhaiJiuGong";
    import { mapGetters } from 'vuex';
    import http from "../utils/http.js";

    export default {
        name: "MMemberCenter", // 组件名称：MHome（手机端会员中心页面）
        components:{ ZhaiHead, ZhaiFoot, ZhaiJiuGong }, // 导入组件：ZhaiHead, ZhaiFoot, ZhaiJiuGong
        data() {
            return {
                userEvents: [], // 用于存储从后端获取的用户支付记录数据
                fetchFailed: false // 用于标识是否获取用户记录失败
            };
        },
        computed: {
            ...mapGetters(['getUser']) // 计算属性：从Vuex获取用户信息
        },
        methods: {
            fetchUserEventInfo() {
                if (!this.getUser || !this.getUser.id) {
                    console.error('用户未登录');
                    return;
                }

                const userId = this.getUser.id;
                const url = `/platform/frontEnd/getUserEventInfo?userid=${userId}`;
                console.log(`Fetching user events with URL: ${url}`);

                http.get(url)
                    .then(response => {
                        console.log("API 响应数据:", response.data);
                        if (response.data && response.data.status === 1) {
                            this.userEvents = response.data.data;
                            this.fetchFailed = false; // 成功获取数据
                            console.log("用户支付记录:", this.userEvents);
                        } else {
                            this.fetchFailed = true; // 获取数据失败
                            console.error('获取用户支付记录失败:', response.data.msg);
                        }
                    })
                    .catch(error => {
                        this.fetchFailed = true; // API 请求出错
                        console.error('API 请求错误:', error);
                    });
            },
            // 格式化日期为 YYYY-MM-DD 的方法
            formatDate(date) {
                if (!date) return '';
                const d = new Date(date);
                const year = d.getFullYear();
                const month = String(d.getMonth() + 1).padStart(2, '0');
                const day = String(d.getDate()).padStart(2, '0');
                const hours = String(d.getHours()).padStart(2, '0');
                const minutes = String(d.getMinutes()).padStart(2, '0');
                const seconds = String(d.getSeconds()).padStart(2, '0');
                return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            },
            // 格式化VIP到期日期的方法，包含日期和时间
            formatExpireDate(date) {
                if (!date) {
                    return '无'; // 当日期为空时显示“无”
                }

                const now = new Date(); // 获取当前时间
                const expireDate = new Date(date);

                // 判断当前时间是否已经超过VIP有效期
                if (now > expireDate) {
                    return '无'; // 如果已过期，返回“无”
                }

                const d = new Date(date);
                const year = d.getFullYear();
                const month = String(d.getMonth() + 1).padStart(2, '0'); // 月份从0开始，所以要加1
                const day = String(d.getDate()).padStart(2, '0');
                const hours = String(d.getHours()).padStart(2, '0');
                const minutes = String(d.getMinutes()).padStart(2, '0');
                const seconds = String(d.getSeconds()).padStart(2, '0');

                return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            }
        },
        created() {
            this.fetchUserEventInfo(); // 组件创建时调用fetchUserEventInfo方法获取用户支付记录
        }
    };
</script>

<style scoped>
    /* 保持现有样式 */
    .gongxi {
        height: 90px;
    }
    .layui-table {
        width: 100%;
        background-color: #fff;
        color: #666;
        margin: 10px 0;
        border: 1px solid #ddd;
        border-collapse: collapse;
    }
    .layui-table th,
    .layui-table td {
        border: 1px solid #ddd;
        text-align: center;
        vertical-align: middle;
        line-height: 2rem;
    }
    .layui-table th {
        font-weight: normal;
        background-color: #f2f2f2;
    }
    .btn-primary {
        color: #fff;
        background-color: #00c26f;
        margin-bottom: 1rem!important;
        margin-top: 1rem!important;
        border: none !important;
    }
    .table td,
    .table th {
        text-align: center;
        vertical-align: middle;
    }
    .table th {
        background-color: #d5d5d5;
    }
    .zhu {
        margin-top: 80px;
    }
</style>
