<template>
    <!-- 回家的路 -->
    <!-- 导航栏 -->
    <ZhaiHeadPC class="zhai-head"></ZhaiHeadPC>

    <!-- 主体内容容器 -->
    <div class="container errong">
        <ZhaiJiuGongPC menu="8"></ZhaiJiuGongPC>
        <div class="container-fluid mt-2">
            <div class="row shengse pb-2">
                <div class="dixian px-3 py-2 d-flex justify-content-between">
                    <div class="lf">
                        <i class="bi bi-house-door"></i> <!-- 添加房子图标 -->
                        回家的路
                    </div>
                </div>
                <div class="p-3 gohome">
                    <h1 style="text-align: center;">！！！请将本页截图保存！！！</h1>
                    <h1>1.永久地址：快发卡.com、卡卡.com</h1>
                    <p>提供两个永久中文域名，直接从浏览器地址栏输入中文域名即可！</p>
                    <h1>2.IOS客户端（适用于苹果手机） </h1>
                    <p><a href="/ios/ios.html">IOS轻量版客户端</a></p>
                    <h1>3.导航找回</h1>
                    <p>wwwone4.com，wwwone5.com，wwwone6.com，the.rrrone.com，the.mmmone.com，学数学.com，学语文.com</p>
                    <h1>4.邮箱找回</h1>
                    <p>发送任意邮件给：404489561.com，自动回复最新地址。</p>
                </div>
            </div>

        </div>
    </div>
    <!-- 只是占据空间 -->
    <div class="gongxi2"></div>

    <!-- 公共底部 -->
    <ZhaiFootPC></ZhaiFootPC>
</template>

<script>
    import ZhaiHeadPC from "../componentspc/ZhaiHeadPC"; // 导入导航栏组件
    import ZhaiFootPC from "../componentspc/ZhaiFootPC"; // 导入底部组件

    export default {
        name: "PWayHome", // 组件名称
        components: { ZhaiHeadPC, ZhaiFootPC } // 注册组件
    }
</script>

<style scoped>
    /* 使用CSS Reset来确保不同浏览器的一致性 */
    /** {*/
        /*margin: 0;*/
        /*padding: 0;*/
        /*box-sizing: border-box;*/
    /*}*/

    body {
        font-family: Arial, sans-serif;
    }

    .gongxi2 {
        height: 20px;
    }

    /* 最大容器的左右内边距 */
    .errong {
        padding-left: 30px;
        padding-right: 30px;
    }

    /* 文本的样式 */
    .gohome h1 {
        font-size: 1.5rem;
        margin-top: 2rem;
        color: #00c26f;
        padding-left: 15px;
        padding-right: 15px;
    }

    .gohome p {
        font-size: 1rem;
        padding: 1rem 0;
        color: #000;
        line-height: 2rem;
        padding-left: 15px;
        padding-right: 15px;
    }

    @media (min-width: 768px) {
        .zhai-head {
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 1000;
        }
        .errong {
            margin-top: 90px; /* 根据导航栏高度调整 */
        }
    }
</style>
