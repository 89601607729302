<template>
    <div>
        <select
                class="form-select form-control"
                :class="{ 'is-invalid': provinceError }"
                aria-label="Default select example"
                v-model="selectedProvince"
                @change="updateCities">
            <option value="" style="color: #aeaaa7">请选择省</option>
            <option v-for="province in provinces" :key="province" :value="province">{{ province }}</option>
        </select>
        <select
                class="form-select form-control mt-2"
                :class="{ 'is-invalid': cityError }"
                aria-label="Default select example"
                v-model="selectedCity">
            <option style="font-size: 0.8rem;color: #aeaaa7" value="">请选择市</option>
            <option v-for="city in cities" :key="city" :value="city">{{ city }}</option>
        </select>
    </div>
</template>

<script>
    import { regions } from '../data/china-regions.js';  // 确保路径正确

    export default {
        props: {
            provinceError: {
                type: Boolean,
                default: false
            },
            cityError: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                provinces: Object.keys(regions),
                citiesData: regions,
                selectedProvince: '',
                selectedCity: '',
                cities: []
            };
        },
        methods: {
            updateCities() {
                this.cities = this.citiesData[this.selectedProvince] || [];
                this.selectedCity = '';
            }
        },
        watch: {
            selectedProvince(newVal) {
                this.$emit('update:province', newVal);
            },
            selectedCity(newVal) {
                this.$emit('update:city', newVal);
            }
        }
    };
</script>

<style scoped>
    .form-select {
        width: 100%;
        font-size:0.9rem !important;
    }
    .is-invalid {
        border-color: red !important;
        background-image: none !important;
        box-shadow: none !important;
    }

</style>
