<template>
    <!--三个按钮-->
    <div class="row san">
        <div class="col hui " style="text-align: right"><router-link to="wayHome" type="button" class="btn btn-dark">回家的路</router-link></div>
        <div class="col zhang"><router-link to="queryAccount" type="button" class="btn btn-success">账号查询</router-link></div>
        <div class="col fang"><router-link to="avoidFraud" type="button" class="btn btn-success">防骗指南</router-link></div>
    </div>
    <!--三个按钮-->
</template>

<script>
    export default {
        name: "ThreeTuttons"
    }
</script>

<style scoped>
    .btn-success{
        background-color: #18b371 !important;
        border: none !important;
    }
    .btn{
        line-height: 1rem;
        font-size: 0.9rem!important;
    }
    /*回家的路三个按钮与下边板块的边距*/
    .san{
        padding-bottom:10px;
    }
    /*以下是三个按钮的位置调整*/
    .hui{
        text-align: right;
        padding-right: 0 !important;
    }
    .zhang{
        text-align: center;
        padding: 0 !important;
    }
    .fang{
        text-align: left;
        padding-left: 0 !important;
    }

</style>