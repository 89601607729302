<template>
    <!--手机端注册-->
    <!--导航栏-->
    <ZhaiHead></ZhaiHead>
    <!--导航栏-->
    <!--只是占据空间-->
    <div class="gongxi1"></div>
    <!--顶部占据空间-->
    <!--主体内容-->
    <div class="container-fluid shengse">
        <div class="row mt-2">
            <div class="dixian px-4 py-2 d-flex justify-content-between">
                <div><i class="bi bi-person-add"></i>用户注册</div>
            </div>
        </div>
        <div class="px-4 zhai-s">
            <div class="mb-3">
                <input type="text" class="form-control custom-input" id="username" v-model="myform.username" placeholder="请输入用户名" :class="{ 'is-invalid': errors.username }">
            </div>
            <div class="mb-3">
                <input type="password" class="form-control custom-input" id="password" v-model="myform.password" placeholder="请输入密码" :class="{ 'is-invalid': errors.password }">
            </div>
            <div class="mb-3">
                <input type="password" class="form-control custom-input" id="password2" v-model="myform.password2" placeholder="请再次输入密码" :class="{ 'is-invalid': errors.password2 }">
            </div>
            <div class="mb-3">
                <input type="email" class="form-control custom-input" id="email" v-model="myform.email" placeholder="邮箱" :class="{ 'is-invalid': errors.email }">
            </div>

            <!-- 验证码组件 -->
            <CaptchaComponent @validate-captcha="validateCaptcha" ref="captchaComponent"></CaptchaComponent>

            <div class="col-12 mt-4 text-center">
                <button type="button" @click="reg()" class="btn btn-success w-100 z-f-sm">注册</button>
            </div>

            <div class="zhan"></div>
        </div>
    </div>
    <!--主体内容-->

    <!--只是占据空间-->
    <div class="gongxi2"></div>
    <!--顶部占据空间-->

    <!--公共底部-->
    <ZhaiFoot></ZhaiFoot>
    <!--公共底部-->
    <div class="modal fade" id="errorModal" tabindex="-1" aria-labelledby="errorModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered small-modal">
            <div class="modal-content">
                <div class="modal-body d-flex align-items-center justify-content-center small-padding">
                    <i class="bi bi-emoji-frown small-icon"></i>
                    <p class="mb-0 ms-2 small-text">{{ errorMessage }}</p>
                </div>
            </div>
        </div>
    </div>
    <!-- 成功模态框 -->
    <div class="modal fade" id="successModal" tabindex="-1" aria-labelledby="successModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered small-modal">
            <div class="modal-content">
                <div class="modal-body d-flex align-items-center justify-content-center small-padding">
                    <i class="bi bi-emoji-smile small-icon" style="color: #00c26f;"></i>
                    <p class="mb-0 ms-2 small-text" style="color: #00c26f;">注册成功</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import http from "../utils/http.js"; // 导入http请求工具
    import ZhaiHead from "../components/ZhaiHead"; // 导入头部组件
    import ZhaiFoot from "../components/ZhaiFoot"; // 导入底部组件
    import CaptchaComponent from "../componentspc/CaptchaComponent.vue"; // 导入验证码组件
    import { Modal } from 'bootstrap'; // 导入Bootstrap的Modal组件

    export default {
        name: "MRegister", // 组件名称
        components: { ZhaiHead, ZhaiFoot, CaptchaComponent }, // 注册使用的子组件
        data() {
            return {
                myform: {
                    username: "", // 用户名
                    password: "", // 密码
                    password2: "", // 确认密码
                    email: "" // 邮箱
                },
                errors: {}, // 存储各字段的错误状态
                errorMessage: "", // 错误提示信息
                captchaValid: false // 验证码是否有效
            };
        },
        methods: {
            // 验证验证码是否有效
            validateCaptcha(isValid) {
                this.captchaValid = isValid; // 更新验证码的有效状态
                this.$refs.captchaComponent.captchaError = !isValid; // 如果验证码无效，则显示错误提示
            },
            // 注册方法
            reg() {
                this.errors = {}; // 重置错误状态
                // 验证用户名
                if (!this.myform.username.trim()) {
                    this.showErrorModal("用户名不能为空", 'username'); // 用户名为空时显示错误提示
                    return;
                }
                if (this.myform.username.length < 4 || this.myform.username.length > 10 || this.myform.username.includes(" ")) {
                    this.showErrorModal("用户名必须介于4-20个字符且不能包含空格", 'username'); // 用户名长度或格式不符合要求时显示错误提示
                    return;
                }
                // 验证密码
                if (!this.myform.password.trim()) {
                    this.showErrorModal("密码不能为空", 'password'); // 密码为空时显示错误提示
                    return;
                }
                if (!/^[^\s]{6,15}$/.test(this.myform.password)) {
                    this.showErrorModal("密码应为6-15位字符且不能包含空格", 'password'); // 密码格式不符合要求时显示错误提示
                    return;
                }
                // 验证两次输入的密码是否一致
                if (this.myform.password !== this.myform.password2) {
                    this.showErrorModal("两次密码不一致", 'password2'); // 两次密码不一致时显示错误提示
                    return;
                }
                // 验证邮箱
                if (!this.myform.email.trim()) {
                    this.showErrorModal("邮箱不能为空", 'email'); // 邮箱为空时显示错误提示
                    return;
                }
                if (!this.validateEmail(this.myform.email)) {
                    this.showErrorModal("邮箱格式有误", 'email'); // 邮箱格式不正确时显示错误提示
                    return;
                }
                // 验证验证码是否为空
                if (!this.$refs.captchaComponent.enteredCaptcha.trim()) {
                    this.showErrorModal("验证码不能为空", 'captcha'); // 验证码为空时显示错误提示
                    this.$refs.captchaComponent.captchaError = true; // 显示验证码错误提示
                    return;
                }
                // 验证验证码是否正确
                this.validateCaptcha(this.$refs.captchaComponent.enteredCaptcha.trim() === this.$refs.captchaComponent.captchaText);

                if (!this.captchaValid) {
                    this.showErrorModal("图形验证码输入不正确！", 'captcha'); // 验证码错误时显示提示
                    return;
                }

                // 准备提交数据
                let url = "/platform/frontEnd/register"; // 注册接口地址
                let fm = new FormData();
                fm.append("username", this.myform.username); // 添加用户名到表单数据
                fm.append("password", this.myform.password); // 添加密码到表单数据
                fm.append("password2", this.myform.password2); // 添加确认密码到表单数据
                fm.append("email", this.myform.email); // 添加邮箱到表单数据

                // 发起注册请求
                http.post(url, fm, true).then(data => {
                    if (data.data.status === 1) {
                        this.autoLogin(); // 注册成功后自动登录
                    } else {
                        this.handleFormErrors(data.data); // 处理注册过程中的错误
                    }
                }).catch(error => {
                    console.error("注册请求失败:", error); // 打印错误信息
                    this.showErrorModal("注册请求失败，请稍后重试。"); // 注册请求失败时显示错误提示
                });
            },
            // 自动登录方法
            autoLogin() {
                let url = "/platform/frontEnd/login"; // 登录接口地址
                let fm = new FormData();
                fm.append("username", this.myform.username); // 使用注册时输入的用户名
                fm.append("password", this.myform.password); // 使用注册时输入的密码

                // 发起登录请求
                http.post(url, fm, { untoken: true }).then(response => {
                    if (response.data.status === 1 && response.data.data) {
                        const data = response.data.data;
                        const userData = {
                            user: {
                                id: data.id,
                                username: data.username,
                                email: data.email,
                                level: data.level,
                                level_expire_date: data.level_expire_date
                            },
                            token: data.token
                        };

                        if (!userData.user || !userData.token) {
                            this.showErrorModal("登录失败，用户或令牌无效"); // 如果用户数据或令牌无效，显示错误提示
                            return;
                        }

                        // 将用户数据存储到Vuex和localStorage中
                        this.$store.dispatch('login', userData);
                        localStorage.setItem('authToken', userData.token);
                        localStorage.setItem('user', JSON.stringify(userData.user));
                        this.showSuccessModal(); // 登录成功后显示成功模态框
                    } else {
                        this.showErrorModal(response.data.msg || "登录失败！"); // 登录失败时显示错误提示
                    }
                }).catch(error => {
                    this.showErrorModal("请求失败，请稍后重试。"); // 请求失败时显示错误提示
                });
            },
            // 显示成功模态框
            showSuccessModal() {
                const modalElement = document.getElementById('successModal');
                const modal = new Modal(modalElement);
                modal.show();

                setTimeout(() => {
                    modal.hide();
                    this.$router.push('/memberCenter'); // 跳转到会员中心页面
                }, 3000); // 显示模态框3秒后跳转
            },
            // 验证邮箱格式
            validateEmail(email) {
                const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return re.test(email);
            },
            // 处理表单错误
            handleFormErrors(data) {
                if (data.field && data.message) {
                    this.showErrorModal(data.message, data.field); // 显示具体字段的错误信息
                } else {
                    this.showErrorModal(data.msg || "注册失败！"); // 显示通用错误信息
                }
            },
            // 显示错误模态框
            showErrorModal(message, field) {
                this.errorMessage = message; // 设置错误提示信息
                if (field) {
                    this.errors[field] = true; // 标记具体的错误字段
                }
                const modalElement = document.getElementById('errorModal');
                const modal = new Modal(modalElement);
                modal.show();

                // 添加抖动效果
                setTimeout(() => {
                    modalElement.querySelector('.modal-content').classList.add('shake');
                    setTimeout(() => {
                        modalElement.querySelector('.modal-content').classList.remove('shake');
                    }, 1000);
                }, 0);

                setTimeout(() => {
                    modal.hide();
                }, 3000); // 显示模态框3秒后自动关闭
            }
        },
        mounted() {
            this.$refs.captchaComponent.generateCaptcha(); // 在组件挂载时生成验证码
        }
    };
</script>

<style scoped>
    /*主体与顶端的内边距*/
    .zhai-s {
        padding-top: 10px;
    }

    /*横线的元素*/
    hr {
        border: none; /* 移除默认的边框 */
        height: 1px; /* 设置横线的高度 */
        background-color: #333; /* 设置横线的颜色 */
        margin-top: 10px !important;
    }
    /* 自定义输入框样式 */
    .custom-input {
        border-radius: 2px !important;
        line-height: 1.5 !important;
    }

    /*提示文字红色*/
    .tishi {
        color: red;
        font-size: 0.9rem;
    }

    /*恭喜发财的样式*/
    .facai {
        font-size: 20px;
        color: lightslategrey;
        text-align: center;
        margin-top: 85px;
        margin-bottom: 10px;
    }

    /*随机生成文字框的样式*/
    .br5 {
        border-radius: 5px;
        border: 1px solid #f2f2f2;
    }
    .gongxi1 {
        height: 70px;
    }
    /*底部不占据空间*/
    .gongxi2 {
        height: 20px;
    }

    .zhan {
        height: 30px;
    }

    /*按钮颜色字体大小*/
    .btn-success {
        background-color: #00c26f !important;
        font-size: 0.9rem;
        border: none !important;
    }

    /*输入框内和提示文字的大小*/
    .form-control {
        font-size: 0.9rem;
    }

    /* 调整验证码图片与输入框对齐 */
    .d-flex.align-items-center {
        display: flex;
        align-items: center;
    }

    .ml-3 {
        margin-left: 1rem;
    }

    /* 错误输入框样式 */
    .is-invalid {
        border-color: #dc3545 !important;
        background-image: none !important; /* 移除叹号图标 */
    }

    /* 添加模态框抖动效果 */
    @keyframes shake {
        0%, 100% { transform: translateX(0); }
        10%, 30%, 50%, 70%, 90% { transform: translateX(-2px); }
        20%, 40%, 60%, 80% { transform: translateX(2px); }
    }
    .modal-content.shake {
        animation: shake 0.5s;
    }

    /* 调整模态框和文本的大小 */
    .small-modal .modal-content {
        width: 100%;
        max-width: 250px;
        margin: 0 auto;
    }
    .small-padding {
        padding: 0px !important;
    }
    .small-icon {
        font-size: 1.5rem;
        color: #dc3545;
    }
    .small-text {
        font-size: 0.9rem;
        color: #dc3545;
    }
</style>
