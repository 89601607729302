<template>
    <!--手机端 信息发布 -->
    <ZhaiHead></ZhaiHead>

    <!-- 九宫格 -->
    <div class="container-fluid zhu mb-2">
        <ZhaiJiuGong menu="2"></ZhaiJiuGong>
    </div>

    <!-- 主体 -->
    <div class="container-fluid py-2">
        <div class="row shengse">
            <div class="dixian px-3 py-2 d-flex justify-content-between">
                <div>信息发布</div>
            </div>
            <div class="p-3">
                <div style="padding: 1rem; color: #edff00; font-size: 1rem; background: #2b2b37; line-height: 1.6rem;">
                    <p>1.现要求发帖必须发帖者本人<strong>手持写有“快发卡.com”的纸条</strong>拍照并上传...</p>
                </div>
                <div class="py-2 huiziti">
                    <!-- 信息标题 -->
                    <div class="mb-2">
                        <label class="mb-2">信息标题</label>
                        <div class="input-group mb-3">
                            <input type="text" v-model="info.name" :class="{ 'is-invalid': errors.name }" class="form-control" placeholder="填写你的信息标题">
                        </div>
                    </div>

                    <!-- 所属地区 -->
                    <div class="mb-2">
                        <label class="mb-2">所属地区</label>
                        <ProvinceCitySelector
                                @update:province="provinceUpdated"
                                @update:city="cityUpdated"
                                :provinceError="errors.province"
                                :cityError="errors.city"
                        />
                    </div>

                    <!-- 账号 -->
                    <div class="mb-2">
                        <label class="mb-2">账号</label>
                        <div class="input-group mb-3">
                            <input type="text" v-model="info.account" :class="{ 'is-invalid': errors.account }" class="form-control" placeholder="填写账号">
                        </div>
                    </div>

                    <!-- 账号年龄 -->
                    <div class="mb-2">
                        <label class="mb-2">账号年龄</label>
                        <div class="input-group mb-3">
                            <input type="text" v-model="info.age" :class="{ 'is-invalid': errors.age }" class="form-control" placeholder="填写账号的年龄">
                        </div>
                    </div>

                    <!-- 账号价格 -->
                    <div class="mb-2">
                        <label class="mb-2">账号价格</label>
                        <div class="input-group mb-3">
                            <input type="text" v-model="info.price" :class="{ 'is-invalid': errors.price }" class="form-control" placeholder="填写账号的价格">
                        </div>
                    </div>

                    <!-- 账号特点 -->
                    <div class="mb-2">
                        <label class="mb-2" title="">账号特点:</label>
                        <div class="input-group mb-3 select-with-arrow">
                            <select v-model="info.condition" :class="{ 'is-invalid': errors.condition }" class="form-control custom-select">
                                <option value="" disabled>选择账号特点</option>
                                <option v-for="option in conditionOptions" :key="option.value" :value="option.value">
                                    {{ option.label }}
                                </option>
                            </select>
                        </div>
                    </div>

                    <!-- 详细介绍 -->
                    <div class="mb-2">
                        <label class="mb-2">详细介绍</label>
                        <div class="form-floating">
                            <textarea class="form-control" v-model="info.history" :class="{ 'is-invalid': errors.history }" style="height: 100px"></textarea>
                            <label>详情</label>
                        </div>
                    </div>

                    <!-- 账号等级 -->
                    <div class="mb-2">
                        <label class="mb-2">账号等级</label>
                        <div class="input-group mb-3">
                            <input type="text" v-model="info.level" :class="{ 'is-invalid': errors.level }" class="form-control" placeholder="填写账号等级">
                        </div>
                    </div>

                    <!-- 注意事项 -->
                    <div class="mb-2">
                        <label class="mb-2">注意事项</label>
                        <div class="input-group mb-3">
                            <input type="text" v-model="info.attention" :class="{ 'is-invalid': errors.attention }" class="form-control" placeholder="填写账号的注意事项">
                        </div>
                    </div>

                    <!-- 电话 -->
                    <div class="mb-2">
                        <label class="mb-2">电话</label>
                        <div class="input-group mb-3">
                            <input type="text" v-model="info.phone" :class="{ 'is-invalid': errors.phone }" class="form-control" placeholder="填写您的电话">
                        </div>
                    </div>

                    <!-- 邮箱 -->
                    <div class="mb-2">
                        <label class="mb-2">邮箱</label>
                        <div class="input-group mb-3">
                            <input type="text" v-model="info.email" :class="{ 'is-invalid': errors.email }" class="form-control" placeholder="填写您的邮箱">
                        </div>
                    </div>

                    <!-- 来源 -->
                    <div class="mb-2">
                        <label class="mb-2">来源</label>
                        <div class="input-group mb-3">
                            <input type="text" v-model="info.source" :class="{ 'is-invalid': errors.source }" class="form-control" placeholder="填写账号的来源">
                        </div>
                    </div>

                    <!-- 图片上传 -->
                    <div class="mt-2">
                        <div class="input-group mt-3">
                            <input type="file" ref="images" @change="previewImages" multiple style="display: none;">
                            <button type="button" class="btn btn-success custom-button-rounded" @click="triggerFileInput">上传图片</button>
                        </div>
                        <div class="form-floating mt-2 preview-container">
                            <div class="preview-label-container">
                                <div class="preview-label">预览：</div>
                            </div>
                            <div id="previewContainer" class="preview-images" ref="previewContainer">
                                <div v-for="(url, index) in previewUrls" :key="index" class="image-wrapper">
                                    <div class="image-container" @mouseover="showIcons(index)" @mouseleave="hideIcons(index)">
                                        <img :src="url" class="preview-image" alt="">
                                        <div class="icon-container" :class="{ 'icon-visible': visibleIcons === index }">
                                            <i class="bi bi-eye-fill icon" @click="viewImage(index)"></i>
                                            <i class="bi bi-trash-fill icon" @click="deleteImage(index)"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- 发布按钮 -->
                    <div class="input-group mt-3 px-3 mb-3">
                        <button type="button" class="btn btn-success mx-auto" @click="release">确认发布</button>
                    </div>

                    <!-- 发布须知 -->
                    <div style="color: red;">
                        <div class="jiacu">
                            现要求发帖必须要求发帖人<strong>手持写有“快发卡.com”的纸条</strong>拍照并上传才会过审...
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- 公共底部 -->
    <ZhaiFoot></ZhaiFoot>
    <!-- 发帖需要升级任意等级VIP 模态框 -->
    <div class="modal fade" id="myModal6" tabindex="-1" aria-labelledby="vipModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style="max-width: 1000px;">
            <div class="modal-content" style="width: 100%;">
                <div class="modal-header">
                    <h4 class="modal-title" style="font-size: 1rem!important;" id="vipModalLabel">发帖需要升级任意等级VIP</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p class="px-2" style="color: red; text-align: left; margin-top: 10px;">免费用户无发帖权限！升级任意等级VIP即可免费发帖！</p>
                    <p class="px-2" style="text-align: left; margin-top: 10px; margin-bottom: 10px;">发帖要求：需要手持本人手持“kuaifaka.com”的照片（可以不露脸，但从服装、背景等需要能看出是同一人），只要手持，发帖就能通过！</p>
                </div>
                <div class="modal-footer text-center">
                    <button type="button" class="btn btn-primary mx-auto" data-bs-dismiss="modal" @click="redirectToUpgradeVIP">确定</button>
                </div>
            </div>
        </div>
    </div>


    <!-- 发帖重要提示 模态框 -->
    <div class="modal custom-modal" id="myModal7">
        <div class="modal-dialog modal-dialog-centered" style="max-width: 1000px;">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">发帖重要提示</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div>必须手持写有“gangwanhao.top”的纸条拍照并上传...</div>
                    <div class="text-center mt-3">
                        <img src="../assets/手持.png" style="width: 80%" alt="..." />
                        <div class="text-center">(例图)</div>
                    </div>
                </div>
                <div class="modal-footer text-center">
                    <button type="button" class="btn btn-success mx-auto" data-bs-dismiss="modal" >我已知晓</button>
                </div>
            </div>
        </div>
    </div>


    <!-- 发布成功提示框 -->
    <div class="modal fade" id="successModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style="width: 300px;">
            <div class="modal-content">
                <div class="modal-body text-center d-flex align-items-center justify-content-center">
                    <i class="bi bi-emoji-smile" style="font-size: 2rem; color: #28a745;"></i>
                    <p class="mb-0 ms-2" style="font-size: 1rem; color: #28a745;">上传成功，如审核通过可在我的发布板块查看</p>
                </div>
            </div>
        </div>
    </div>

    <!-- 错误提示模态框 -->
    <div class="modal fade" id="errorModal" tabindex="-1" aria-labelledby="errorModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" style="max-width: 250px;">
            <div class="modal-content">
                <div class="modal-body d-flex align-items-center justify-content-center">
                    <i class="bi bi-emoji-frown" style="font-size: 2rem; color: #dc3545;"></i>
                    <p class="mb-0 ms-2" style="font-size: 1rem; color: #dc3545;">{{ errorMessage }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // 导入所需的组件和库
    import ZhaiHead from "../components/ZhaiHead"; // 导入头部组件
    import ZhaiFoot from "../components/ZhaiFoot"; // 导入底部组件
    import ZhaiJiuGong from "../components/ZhaiJiuGong"; // 导入九宫格导航组件
    import ProvinceCitySelector from "../components/ProvinceCitySelector"; // 导入省市选择器组件
    import { Modal } from 'bootstrap'; // 从 Bootstrap 中导入 Modal 类，用于操作模态框
    import Viewer from 'viewerjs'; // 导入 Viewer.js，用于图片预览
    import 'viewerjs/dist/viewer.css'; // 导入 Viewer.js 的样式
    import http from "../utils/http.js"; // 导入自定义的 HTTP 请求工具
    import { mapGetters } from 'vuex'; // 从 Vuex 中导入 mapGetters，用于获取 Vuex 中的状态

    export default {
        name: "MInfoPublish", // 组件名称
        components: { ZhaiHead, ZhaiFoot, ZhaiJiuGong, ProvinceCitySelector }, // 注册导入的组件
        data() {
            // 定义组件的响应式数据
            return {
                conditionOptions: [
                    { value: '安全可靠', label: '安全可靠' },
                    { value: '账号老练', label: '账号老练' },
                    { value: '活跃用户', label: '活跃用户' },
                    { value: '优质内容', label: '优质内容' }
                    // 可以根据实际需求添加更多选项
                ],
                info: {
                    name: '', // 信息标题
                    account: '', // 账号
                    age: '', // 账号年龄
                    price: '', // 账号价格
                    condition: '', // 账号特点
                    history: '', // 详细介绍
                    level: '', // 账号等级
                    attention: '', // 注意事项
                    phone: '', // 联系电话
                    email: '', // 联系邮箱
                    source: '', // 账号来源
                    province: '', // 所属省份
                    city: '' // 所属城市
                },
                errors: {
                    // 用于存储各个输入字段的错误状态
                    name: false,
                    account: false,
                    age: false,
                    price: false,
                    condition: false,
                    history: false,
                    level: false,
                    attention: false,
                    phone: false,
                    email: false,
                    source: false,
                    province: false,
                    city: false
                },
                previewUrls: [], // 存储预览图片的 URL 数组
                visibleIcons: null, // 当前显示图标的图片索引
                viewer: null, // Viewer.js 实例
                errorMessage: "", // 错误消息内容
            };
        },
        computed: {
            // 使用 Vuex 的 getters 从状态管理中获取用户登录状态和用户信息
            ...mapGetters(['isLoggedIn', 'getUser'])
        },
        methods: {
            //发帖需要升级任意等级VIP模态框确定按钮点击跳转函数
            redirectToUpgradeVIP() {
                this.$router.push('/upgradeVIP');
            },
            // 触发文件输入框，用于选择图片
            triggerFileInput() {
                this.$refs.images.click();
            },
            // 更新省份
            provinceUpdated(province) {
                this.info.province = province;
            },
            // 更新城市
            cityUpdated(city) {
                this.info.city = city;
            },
            // 预览图片
            previewImages(event) {
                const files = event.target.files;
                const maxImages = 4; // 最多上传 4 张图片
                const maxSize = 1024 * 1024 * 1; // 图片大小限制为 1MB
                const allowedTypes = ["image/png", "image/jpeg", "image/bmp", "image/webp"]; // 允许的图片格式

                // 检查图片数量是否超过限制
                if (this.previewUrls.length + files.length > maxImages) {
                    this.showErrorModal(`最多只能上传 ${maxImages} 张图片`);
                    return;
                }
                // 生成预览图片的 URL
                for (let i = 0; i < files.length; i++) {
                    const file = files[i];
                    // 检查图片大小
                    if (file.size > maxSize) {
                        this.showErrorModal(`图片 ${file.name} 大小不能超过 1MB`);
                        return;
                    }

                    // 检查图片格式
                    if (!allowedTypes.includes(file.type)) {
                        this.showErrorModal(`图片 ${file.name} 格式不支持，仅支持 PNG, JPEG, BMP 和 WEBP 格式`);
                        return;
                    }
                    this.previewUrls.push(URL.createObjectURL(file));
                }
                // 更新 Viewer.js 实例
                this.$nextTick(() => {
                    this.viewer.update();
                });
            },
            // 显示图片上的操作图标
            showIcons(index) {
                this.visibleIcons = index;
            },
            // 隐藏图片上的操作图标
            hideIcons() {
                this.visibleIcons = null;
            },
            // 查看图片
            viewImage(index) {
                this.viewer.view(index);
            },
            // 删除图片
            deleteImage(index) {
                this.previewUrls.splice(index, 1);
                this.$nextTick(() => {
                    this.viewer.update();
                });
            },
            // 发布信息
            release() {
                // 重置错误状态
                this.errors = {};

                // 验证各个必填字段是否为空
                if (!this.info.name.trim()) {
                    this.showErrorModal("信息标题不能为空", 'name');
                    return;
                }
                if (!this.info.province.trim()) {
                    this.showErrorModal("省份不能为空", 'province');
                    return;
                }
                if (!this.info.city.trim()) {
                    this.showErrorModal("城市不能为空", 'city');
                    return;
                }
                if (!this.info.account.trim()) {
                    this.showErrorModal("账号不能为空", 'account');
                    return;
                }
                if (!this.info.age.trim()) {
                    this.showErrorModal("账号年龄不能为空", 'age');
                    return;
                }
                if (!this.info.price.trim()) {
                    this.showErrorModal("账号价格不能为空", 'price');
                    return;
                }
                if (!this.info.condition.trim()) {
                    this.showErrorModal("账号特点不能为空", 'condition');
                    return;
                }
                if (!this.info.history.trim()) {
                    this.showErrorModal("详细介绍不能为空", 'history');
                    return;
                }
                if (!this.info.level.trim()) {
                    this.showErrorModal("账号等级不能为空", 'level');
                    return;
                }
                if (!this.info.attention.trim()) {
                    this.showErrorModal("注意事项不能为空", 'attention');
                    return;
                }
                if (!this.info.phone.trim()) {
                    this.showErrorModal("电话不能为空", 'phone');
                    return;
                }
                if (!this.info.email.trim()) {
                    this.showErrorModal("邮箱不能为空", 'email');
                    return;
                }
                // 验证邮箱格式
                if (!this.validateEmail(this.info.email)) {
                    this.showErrorModal("邮箱格式有误", 'email');
                    return;
                }

                // 获取当前用户 ID
                const userId = this.getUser && this.getUser.id;

                if (!userId) {
                    this.showErrorModal("用户未登录");
                    return;
                }

                // 检查是否上传了至少一张图片
                const imageInput = this.$refs.images;
                if (!imageInput || imageInput.files.length === 0) {
                    this.showErrorModal("请上传至少一张图片", 'images');
                    return;
                }

                // 创建 FormData 对象并添加表单数据
                const formData = new FormData();
                formData.append('name', this.info.name);
                formData.append('account', this.info.account);
                formData.append('age', this.info.age);
                formData.append('price', this.info.price);
                formData.append('condition', this.info.condition);
                formData.append('history', this.info.history);
                formData.append('level', this.info.level);
                formData.append('attention', this.info.attention);
                formData.append('phone', this.info.phone);
                formData.append('email', this.info.email);
                formData.append('source', this.info.source);
                formData.append('province', this.info.province);
                formData.append('city', this.info.city);
                formData.append('userid', userId);

                // 添加图片文件到 FormData
                if (imageInput && imageInput.files.length > 0) {
                    for (let i = 0; i < imageInput.files.length; i++) {
                        formData.append('images', imageInput.files[i]);
                    }
                }

                // 发送 POST 请求提交数据
                http.post('/platform/frontEnd/inforelease', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }).then(response => {
                    if (response.data.status === 1) { // 假设 status === 1 表示成功
                        this.showSuccessModal();
                    } else {
                        this.showErrorModal(response.data.msg || '发布失败，请稍后再试。');
                    }
                }).catch(error => {
                    let errorMessage = '发布失败，请稍后再试。';
                    if (error.response && error.response.data && error.response.data.msg) {
                        errorMessage = error.response.data.msg; // 使用后端返回的错误信息
                    } else if (error.message) {
                        errorMessage = error.message;
                    }
                    this.showErrorModal(errorMessage);
                });
            },
            // 显示错误模态框
            showErrorModal(message, field) {
                this.errorMessage = message;
                if (field) {
                    this.errors[field] = true;
                }
                const modalElement = document.getElementById('errorModal');
                const modal = new Modal(modalElement);

                // 添加抖动动画类
                modalElement.classList.add('shake-animation');

                modal.show();

                setTimeout(() => {
                    // 移除抖动动画类
                    modalElement.classList.remove('shake-animation');
                    modal.hide();
                }, 3000);
            },
            // 显示成功模态框
            showSuccessModal() {
                const modalElement = document.getElementById('successModal');
                const modal = new Modal(modalElement);
                modal.show();

                setTimeout(() => {
                    modal.hide();
                    this.$router.push('/'); // 成功发布后跳转到首页
                }, 3000);
            },
            // 验证邮箱格式
            validateEmail(email) {
                const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                return re.test(email);
            },
            // 检查用户是否登录
            checkLoginStatus() {
                if (!this.isLoggedIn) {
                    this.$router.push('/login');
                }
            }
        },
        created() {
            // 组件创建时检查登录状态
            this.checkLoginStatus();
        },
        watch: {
            // 监听 Vuex 中的登录状态变化
            isLoggedIn(val) {
                if (!val) {
                    this.$router.push('/login');
                }
            }
        },
        beforeRouteEnter(to, from, next) {
            // 路由进入前检查本地存储中的 token
            const token = localStorage.getItem('token');
            if (!token) {
                next('/login');
            } else {
                next();
            }
        },
        mounted() {
            // 组件挂载后初始化 Viewer.js，并显示发帖提示模态框
            this.$nextTick(() => {
                if (this.$refs.previewContainer) {
                    this.viewer = new Viewer(this.$refs.previewContainer, {
                        url: 'src'
                    });
                }

                // 获取用户的 VIP 到期时间
                const expireDate = this.getUser.level_expire_date ? new Date(this.getUser.level_expire_date) : null;
                const now = new Date();

                // 判断 VIP 是否过期或者为空
                if (!expireDate || expireDate < now) {
                    // 显示升级 VIP 的提示模态框
                    const modalElement = document.getElementById('myModal6');
                    const modal = new Modal(modalElement);
                    modal.show();
                } else {
                    // 显示发帖重要提示模态框
                    const modalElement = document.getElementById('myModal7');
                    const modal = new Modal(modalElement);
                    modal.show();
                }
            });
        }
    };
</script>


<style scoped>
    /* 顶部占据空间 */
    .gongxi {
        height: 90px;
    }

    /* 输入框里文字大小 */
    .form-control {
        font-size: 0.9rem !important;
    }

    /* 发布按钮的样式 */
    .btn-success {
        background-color: #00c26f !important;
        border: none !important;
        border-radius: 2px !important;
        padding: 5px !important;
        font-size: 0.9rem;
    }

    /* 模态框 */
    .di {
        display: block !important;
    }

    /* 模态框的大小和位置 */
    .custom-modal .modal-dialog {
        width: 95%; /* 或者使用具体的像素值 */
        margin: auto;
        margin-top: 4rem !important;
    }

    .zhu {
        margin-top: 80px;
    }

    /* 预览图片样式 */
    .preview-container {
        border: 1px solid #d3d3d3;
        padding: 10px;
        position: relative;
        min-height: 100px;
    }

    .preview-label-container {
        display: flex;
        align-items: flex-start;
    }

    .preview-label {
        font-size: 1rem;
        font-weight: normal;
        padding: 0 5px;
        margin-right: 10px;
        align-self: flex-start;
        color:#4e5b6b;
    }

    .preview-images {
        display: flex;
        flex-wrap: wrap;
    }

    .preview-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .image-wrapper {
        flex-basis: 30%; /* 增大宽度 */
        max-width: 30%;  /* 增大宽度 */
        margin-right: 10px;
        margin-bottom: 10px;
        display: flex;
        justify-content: flex-start;
        height: 170px;  /* 增加高度以适应较大的图片 */
    }

    .image-container {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .icon-container {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        gap: 10px;
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    .icon-visible {
        opacity: 1;
    }

    .icon {
        font-size: 30px;
        color: white;
        cursor: pointer;
    }

    .is-invalid {
        border-color: red !important;
        background-image: none !important;
        box-shadow: none !important;
    }

    #successModal .modal-dialog {
        margin: auto; /* 水平和垂直居中 */
    }

    #successModal .modal-content {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 1px; /* 去除内边距 */
    }


    /* 错误提示模态框样式调整 */
    #errorModal .modal-dialog {
        max-width: 400px;
        margin: auto;
    }

    #errorModal .modal-content {
        padding-top: 1px;  /* 调整上内边距 */
        padding-bottom: 1px;  /* 调整下内边距 */
        padding-left: 2px;  /* 保持左右内边距 */
        padding-right: 2px;
        font-size: 0.9rem;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    #errorModal .modal-body {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    #errorModal .bi-emoji-frown {
        font-size: 1.5rem; /* 调整图标大小 */
        color: #dc3545;
    }

    #errorModal p {
        margin-bottom: 0;
        margin-left: 8px;
        font-size: 1rem; /* 调整文字大小 */
        color: #dc3545;
    }
    @keyframes shake {
        0%, 100% {
            transform: translateX(0);
        }
        20%, 60% {
            transform: translateX(-5px);
        }
        40%, 80% {
            transform: translateX(5px);
        }
    }

    .shake-animation {
        animation: shake 0.5s;
    }
    .modal-body {
        padding: 0px !important; /* 将模态框内边距设置为0px，并使用 !important 覆盖原有样式 */
    }
    .select-with-arrow {
        position: relative;
    }

    .select-with-arrow select {
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
    }

    .select-with-arrow::after {
        content: '\25BC'; /* Unicode字符表示下箭头 */
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
        font-size: 12px;
        color: #495057;
    }
    .select-with-arrow select {
        color: #495057!important; /* 将字体颜色设置为与其他输入框相同的颜色 */
    }

</style>
