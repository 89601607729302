<template>
    <!-- PC端我的发布页面 -->
    <ZhaiHeadPC class="zhai-head"></ZhaiHeadPC>
    <div class="container errong">
        <ZhaiJiuGongPC menu="3"></ZhaiJiuGongPC>
        <div class="container-fluid mt-2">
            <div class="row shengse">
                <div class="dixian px-3 py-2 d-flex justify-content-between">
                    <div class="lf">我的发布</div>
                </div>
                <div style="font-size: 0.9rem; padding:1em; color:red;">
                    <strong>发帖权限：</strong>
                    <template v-if="getUser && getUser.level_expire_date && new Date() <= new Date(getUser.level_expire_date)">
                        正常（拒审两次将被禁止发帖）
                    </template>
                    <template v-else>
                        无权限（需开通会员）
                    </template>
                </div>
                <div style="font-size: 0.9rem; padding:0 1em 1em; color:red;">
                    <strong>付费置顶：</strong>帖子审核通过后，可以通过“付费置顶”按钮来自助置顶推广。
                </div>
            </div>
        </div>

        <!-- 卡片区域 -->

            <div class="row card-container mt-2">
                <div class="col-md-4 mb-1" v-for="post in posts" :key="post.id">
                    <a @click.prevent="handleCardClick(post)" class="card-link" v-if="post && post.id">
                        <div class="card h-100">
                            <div class="row g-0 h-100">
                                <div class="col-4 p-0 d-flex align-items-center justify-content-center">
                                    <div class="image-container">
                                        <img :src="post.img" alt="..." class="card-img">
                                    </div>
                                </div>
                                <div class="col-8 d-flex flex-column h-100">
                                    <div class="card-body p-1 d-flex flex-column h-100">
                                        <div class="header flex-shrink-0">
                                            <h5 class="card-title mt-3">{{ post.name }}</h5>
                                            <div class="ri mb-1 mt-2">
                                                <i class="bi bi-clock riqiziti" id="upload-date">
                                                    <span>&nbsp;{{ post.createTime.substr(0, 10) }}&nbsp;&nbsp;</span>
                                                </i>
                                                <i class="bi bi-star-fill riqiziti" id="collect">
                                                    <span>&nbsp;{{ post.collectCount }}{{ post.collection }}</span>
                                                </i>
                                            </div>
                                        </div>
                                        <div class="content flex-grow-1">
                                            <p class="card-text">{{ post.history }}</p>
                                        </div>
                                        <div class="footer mb-2 flex-shrink-0">
                                            <span class="bi bi-send-fill weiz changlv" id="location">
                                                &nbsp;{{ post.province }}-{{ post.city }}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
            <div class="bg-white my-2 p-3 G-Field text-center d-flex justify-content-center align-items-center">
                <a class="page-link pagination-btn" href="#" aria-label="Previous" @click.prevent="prevPage"
                   :disabled="currentPage === 1">
                    <span aria-hidden="true">«</span>
                </a>
                <span style="margin: 0 1rem;">第 {{ currentPage }} 页，共 {{ totalPages }} 页</span>
                <a class="page-link pagination-btn" href="#" aria-label="Next" @click.prevent="nextPage"
                   :disabled="currentPage === totalPages">
                    <span aria-hidden="true">»</span>
                </a>
            </div>
        </div>

    <ZhaiFootPC></ZhaiFootPC>
</template>


<script>
    import ZhaiHeadPC from "../componentspc/ZhaiHeadPC";
    import ZhaiFootPC from "../componentspc/ZhaiFootPC";
    import ZhaiJiuGongPC from "../componentspc/ZhaiJiuGongPC";
    import http from "../utils/http.js";
    import { mapGetters } from 'vuex';

    export default {
        name: "PMyRelease",
        components: { ZhaiHeadPC, ZhaiFootPC, ZhaiJiuGongPC },
        data() {
            return {
                posts: [], // 帖子数据
                currentPage: 1, // 当前页码
                pageSize: 24, // 每页大小
                totalPages: 1, // 总页数
            };
        },
        computed: {
            ...mapGetters(['getUser']), // 从 Vuex 获取用户数据
        },
        mounted() {
            this.fetchPosts(); // 组件挂载时加载帖子数据
        },
        methods: {
            fetchPosts() {
                if (!this.getUser) {
                    console.error('用户未登录');
                    return;
                }
                const userId = this.getUser.id;
                const url = `/platform/frontEnd/myRelease?userid=${userId}&pageNum=${this.currentPage}&pageSize=${this.pageSize}`;

                http.get(url)
                    .then(response => {
                        if (response.data && response.data.status === 1) {
                            this.posts = response.data.data.list;
                            this.totalPages = Math.ceil(response.data.data.total / this.pageSize);
                        } else {
                            console.error('获取发布信息失败:', response.data.msg);
                        }
                    })
                    .catch(error => {
                        console.error('API 请求错误:', error);
                    });
            },
            prevPage() {
                if (this.currentPage > 1) {
                    this.currentPage--;
                    this.fetchPosts();
                }
            },
            nextPage() {
                if (this.currentPage < this.totalPages) {
                    this.currentPage++;
                    this.fetchPosts();
                }
            },
            handleCardClick(post) {
                console.log("Card clicked!", post);
                this.$router.push({
                    name: 'detailPage',
                    query: { id: post.id }
                });
            }
        }
    };
</script>


<style scoped>
    .card {
        text-decoration: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        border: none;
        cursor: pointer !important;
    }
    .col-4 {
        width: 115px;
        height: 155px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        padding: 0;
    }
    .image-container {
        width: 99px;
        height: 132px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 4px;
    }
    .card-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .col-8 {
        max-height: 155px;
    }
    .card-body {
        display: flex;
        flex-direction: column;
        padding: 0.1rem;
        height: 100%;
    }
    .card-title {
        font-size: 1rem;
    }
    .card-text {
        margin-bottom: 0.5rem !important;
        font-size: 0.9rem !important;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .daxiao {
        font-size: 0.75rem !important;
    }
    .ri {
        display: flex;
        align-items: center;
        font-size: 0.8rem;
    }
    .riqiziti {
        font-style: normal;
        font-size: 0.8rem;
        margin-right: 4px;
    }
    .ri i + i {
        margin-left: 4px;
    }
    .changlv {
        color: #18b371 !important;
    }
    .content {
        display: flex;
        flex-direction: column;
        margin-top: 0.2rem;
    }
    .card-link {
        text-decoration: none;
        color: inherit;
    }
    .weiz {
        font-size: 0.9rem;
    }
</style>
