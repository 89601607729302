import { createStore } from 'vuex';  // 从 vuex 引入 createStore 方法，用于创建一个 Vuex store 实例
import http from "../utils/http.js";  // 引入自定义的 http 模块，可能用于处理 HTTP 请求

export default createStore({
    state: {
        isLoggedIn: false,   // 登录状态，初始值为 false，表示用户未登录
        user: null,          // 用户信息，初始值为 null
        loginExpired: false  // 新增的状态，用于标记登录是否过期，初始值为 false
    },
    getters: {
        // 获取登录状态
        isLoggedIn: state => state.isLoggedIn,

        // 获取用户信息
        getUser: state => state.user,

        // 获取登录是否过期的状态
        loginExpired: state => state.loginExpired
    },
    mutations: {
        // 设置登录状态
        setLoginStatus(state, status) {
            state.isLoggedIn = status;  // 将 state 中的 isLoggedIn 属性设置为传入的 status 参数
        },

        // 设置用户信息
        setUser(state, user) {
            state.user = user;  // 将 state 中的 user 属性设置为传入的 user 参数
        },

        // 设置登录是否过期的状态
        setLoginExpired(state, expired) {
            state.loginExpired = expired;  // 将 state 中的 loginExpired 属性设置为传入的 expired 参数
        }
    },
    actions: {
        // 检查登录状态
        checkLoginStatus({ commit }) {
            return new Promise((resolve) => {
                const token = localStorage.getItem('token');  // 从 localStorage 中获取 token
                const user = localStorage.getItem('user');    // 从 localStorage 中获取用户信息
                const isLoggedIn = !!token;  // 判断 token 是否存在，存在则说明用户已登录

                // 如果 token 不存在，说明未登录
                if (!token) {
                    commit('setLoginStatus', false);  // 设置登录状态为 false
                    commit('setUser', null);          // 设置用户信息为 null
                    commit('setLoginExpired', true);  // 设置登录过期状态为 true
                    resolve(false);  // 返回一个 resolved 的 Promise，值为 false
                    return;
                }

                let fm = new FormData();  // 创建一个新的 FormData 对象
                // 发送一个 GET 请求以检查登录状态
                http.get('/api/checkLoginStatus', fm, true).then(response => {
                    console.log('response', response);  // 请求成功后打印响应结果
                }).catch(error => {
                    console.error('API request error:', error);  // 请求失败时打印错误信息
                    resolve(false);  // 返回一个 resolved 的 Promise，值为 false
                    return;
                });

                commit('setLoginStatus', isLoggedIn);  // 设置登录状态为 isLoggedIn 的值
                if (isLoggedIn && user) {
                    try {
                        const parsedUser = JSON.parse(user);  // 尝试解析用户信息
                        commit('setUser', parsedUser);        // 设置用户信息
                    } catch (e) {
                        console.error('Failed to parse user data:', e);  // 解析失败时打印错误
                        commit('setUser', null);  // 将用户信息设置为 null
                    }
                } else {
                    commit('setUser', null);  // 如果未登录或用户信息不存在，将用户信息设置为 null
                }
                resolve(isLoggedIn);  // 返回一个 resolved 的 Promise，值为 isLoggedIn
            });
        },

        // 注销
        logout({ commit }) {
            localStorage.removeItem('token');  // 移除 localStorage 中的 token
            localStorage.removeItem('user');   // 移除 localStorage 中的用户信息
            commit('setLoginStatus', false);   // 设置登录状态为 false
            commit('setUser', null);           // 设置用户信息为 null
        },

        // 登录
        login({ commit }, userData) {
            localStorage.setItem('token', userData.token);  // 将 token 存储到 localStorage 中
            localStorage.setItem('user', JSON.stringify(userData.user));  // 将用户信息存储到 localStorage 中
            commit('setLoginStatus', true);  // 设置登录状态为 true
            commit('setUser', userData.user);  // 设置用户信息
        }
    }
});
