// 导入封装好的 axios 实例
import request from '@/utils/request';

const http = {
    /**
     * methods: 请求方法
     * @param url 请求地址
     * @param params 请求参数
     * @param untoken 是否不需要 token（默认为 false，即需要 token）
     */

    // GET 请求
    get(url, params, untoken = false) {
        const config = {
            method: 'get',  // HTTP 方法：GET
            url: url,       // 请求地址
            "untoken": untoken  // 是否需要 token 的标志，默认为 false，传入的 untoken 参数决定
        };
        if (params) config.params = params;  // 如果有传入的参数，则将其添加到请求配置的 params 中
        return request(config);  // 调用封装的 axios 实例 `request` 发起请求，并返回请求结果
    },

    // POST 请求
    post(url, params, untoken = false) {
        const config = {
            method: 'post',  // HTTP 方法：POST
            url: url,        // 请求地址
            "untoken": untoken  // 是否需要 token 的标志
        };
        if (params) config.data = params;  // 如果有传入的参数，将其添加到请求配置的 data 中
        return request(config);  // 调用封装的 axios 实例 `request` 发起请求
    },

    // POST 请求，用于文件上传
    postFile(url, formData, untoken = false) {
        const config = {
            method: 'post',  // HTTP 方法：POST
            url: url,        // 请求地址
            headers: {
                "Content-Type": "multipart/form-data"  // 设置请求头，指定内容类型为 `multipart/form-data`，用于文件上传
            },
            "untoken": untoken  // 是否需要 token 的标志
        };
        if (formData) config.data = formData;  // 如果有传入的 `formData`，则将其添加到请求配置的 data 中
        return request(config);  // 调用封装的 axios 实例 `request` 发起请求
    },

    // POST 请求，发送原始 JSON 数据
    postRaw(url, params) {
        const config = {
            method: 'post',  // HTTP 方法：POST
            url: url,        // 请求地址
            headers: {
                "Content-Type": 'application/json',  // 设置请求头，指定内容类型为 `application/json`
            }
        };
        if (params) config.data = JSON.stringify(params);  // 如果有传入的参数，将其转换为 JSON 字符串并添加到请求配置的 data 中
        return request(config);  // 调用封装的 axios 实例 `request` 发起请求
    },

    // PUT 请求
    put(url, params) {
        const config = {
            method: 'put',  // HTTP 方法：PUT
            url: url,       // 请求地址
            data: params    // 将参数添加到请求配置的 data 中
        };
        return request(config);  // 调用封装的 axios 实例 `request` 发起请求
    },

    // DELETE 请求
    delete(url, params) {
        const config = {
            method: 'delete',  // HTTP 方法：DELETE
            url: url           // 请求地址
        };
        if (params) config.params = params;  // 如果有传入的参数，则将其添加到请求配置的 params 中
        return request(config);  // 调用封装的 axios 实例 `request` 发起请求
    },

    // PATCH 请求
    patch(url, params) {
        const config = {
            method: 'patch',  // HTTP 方法：PATCH
            url: url,         // 请求地址
            data: params      // 将参数添加到请求配置的 data 中
        };
        return request(config);  // 调用封装的 axios 实例 `request` 发起请求
    }
};

// 导出 http 对象，以便在其他模块中导入和使用这些封装好的 HTTP 请求方法
export default http;
