<template>
    <!-- 底部展示 -->
    <div class="container-fluid dibu mt-1">
        <div class="text-align-centerb lvtiao z-f-sm text-center">
            <div>
                共计用户：4578人； 统计时间：<span>{{ currentTime }}</span>
            </div>
            <div class="text-white">联系Email：404489561@qq.com</div>
            <a role="button" class="text-white z-f-sm">Copyright © 2024 gangwanhao.top All rights reserved.</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ZhaiFoot",
        data() {
            return {
                currentTime: "拉取中...", // 初始化为 "拉取中..."
            };
        },
        methods: {
            updateTime() {
                const time = new Date();
                // 获取时间并格式化为 YYYY年MM月DD日 HH:MM:SS
                const timeInfo = `${time.getFullYear()}年${time.getMonth() + 1}月${time.getDate()}日 ${time
                    .getHours()
                    .toString()
                    .padStart(2, "0")}:${time.getMinutes().toString().padStart(2, "0")}:${time
                    .getSeconds()
                    .toString()
                    .padStart(2, "0")}`;
                this.currentTime = timeInfo; // 更新当前时间
            },
        },
        mounted() {
            this.updateTime(); // 页面加载时，立即更新时间
            setInterval(this.updateTime, 1000); // 每秒更新一次时间
        },
    };
</script>

<style scoped>
    /* 样式代码 */
</style>
