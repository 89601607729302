<template>
    <a @click.prevent="handleClick" class="card-link" v-if="data && data.id">
        <div class="card h-100">
            <div class="row g-0 h-100">
                <div class="col-4 p-0 d-flex align-items-center justify-content-center">
                    <div class="image-container">
                        <img :src="data.img" alt="..." class="card-img">
                    </div>
                </div>
                <div class="col-8 d-flex flex-column h-100">
                    <div class="card-body p-1 d-flex flex-column h-100">
                        <div class="header flex-shrink-0">
                            <h5 class="card-title mt-3">{{ data.name }}</h5>
                            <div class="ri mb-1 mt-2">
                                <i class="bi bi-clock riqiziti" id="upload-date"><span>&nbsp;{{ data.createTime.substr(0, 10) }}&nbsp;&nbsp;</span></i>
                                <i class="bi bi-star-fill riqiziti" id="collect"><span>&nbsp;{{ data.collectCount }}{{ data.collection }}</span></i>
                            </div>
                        </div>
                        <div class="content flex-grow-1">
                            <p class="card-text">{{ data.history }}</p>
                        </div>
                        <div class="footer mb-2 flex-shrink-0">
                            <span class="bi bi-send-fill weiz changlv" id="location">&nbsp;{{ data.province }}-{{ data.city }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </a>
</template>

<script>
    export default {
        name: "ZhaiCard",
        props: {
            data: {
                type: Object,
                required: true
            }
        },
        methods: {
            handleClick() {
                // 触发父组件监听的事件
                this.$emit('click');
                // 跳转到详情页面，并通过查询参数传递 id
                this.$router.push({
                    name: 'detailPage',   // 命名路由
                    query: { id: this.data.id }  // 通过查询参数传递 id
                });
            }
        }
    };
</script>

<style scoped>
    body, .card {
        font-size: 16px;
    }
    .card {
        text-decoration: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        border: none;
    }
    .col-4 {
        width: 115px;
        height: 155px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        padding: 0;
    }
    .image-container {
        width: 99px;
        height: 132px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        border-radius: 4px;
    }
    .card-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .col-8 {
        max-height: 155px;
    }
    .card-body {
        display: flex;
        flex-direction: column;
        padding: 0.1rem;
        height: 100%;
    }
    .card-title {
        font-size: 1rem;
    }
    .card-text {
        margin-bottom: 0.5rem !important;
        font-size: 0.9rem !important;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    .daxiao {
        font-size: 0.75rem !important;
    }
    .ri {
        display: flex;
        align-items: center;
        font-size: 0.8rem;
    }
    .riqiziti {
        font-style: normal;
        font-size: 0.8rem;
        margin-right: 4px;
    }
    .ri i + i {
        margin-left: 4px;
    }
    .changlv {
        color: #18b371 !important;
    }
    .content {
        display: flex;
        flex-direction: column;
        margin-top: 0.2rem;
    }
    .card-link {
        text-decoration: none;
        color: inherit;
    }
    .weiz {
        font-size: 0.9rem;
    }
</style>
