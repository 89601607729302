<template>
    <!--PC注册-->
    <div>
        <ZhaiHeadPC class="zhai-head"></ZhaiHeadPC>
        <div class="container-fluid shengse px-0 deng">
            <div class="dixian px-4 py-2 d-flex justify-content-between">
                <div><i class="bi bi-person-fill-add lf"></i>&nbsp;用户注册</div>
            </div>
            <div class="px-5 zhai-s">
                <div class="mb-3">
                    <input
                            type="text"
                            class="form-control"
                            :class="{ 'is-invalid': errors.username }"
                            id="username"
                            v-model="myform.username"
                            placeholder="请输入用户名"
                            @keyup.enter="reg"
                    >
                </div>
                <div class="mb-3">
                    <input
                            type="password"
                            class="form-control"
                            :class="{ 'is-invalid': errors.password }"
                            id="password"
                            v-model="myform.password"
                            placeholder="请输入密码"
                            @keyup.enter="reg"
                    >
                </div>
                <div class="mb-3">
                    <input
                            type="password"
                            class="form-control"
                            :class="{ 'is-invalid': errors.password2 }"
                            id="password2"
                            v-model="myform.password2"
                            placeholder="请再次确认密码"
                            @keyup.enter="reg"
                    >
                </div>
                <div class="mb-3">
                    <input
                            type="email"
                            class="form-control"
                            :class="{ 'is-invalid': errors.email }"
                            id="exampleFormControlInput4"
                            v-model="myform.email"
                            placeholder="邮箱"
                            @keyup.enter="reg"
                    >
                </div>
                <CaptchaComponent
                        @validate-captcha="validateCaptcha"
                        ref="captchaComponent"
                ></CaptchaComponent>
                <div class="mt-1">
                    <p class="qing">请记住用户名，并填写真实邮箱，否则无法找回密码</p>
                </div>
                <div class="col-12 zhucce text-center">
                    <button type="button" @click="reg" class="btn btn-success w-100 anniu">注册</button>
                </div>

                <div class="zhan"></div>
            </div>
        </div>
        <ZhaiFootPC></ZhaiFootPC>

        <div class="modal fade" id="errorModal" tabindex="-1" aria-labelledby="errorModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-shake" style="max-width: 300px;">
                <div class="modal-content shake">
                    <div class="modal-body d-flex align-items-center justify-content-center">
                        <i class="bi bi-emoji-frown" style="font-size: 2rem; color: #dc3545;"></i>
                        <p class="mb-0 ms-2" style="font-size: 1rem; color: #dc3545;">{{ errorMessage }}</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- 成功模态框 -->
        <div class="modal fade" id="successModal" tabindex="-1" aria-labelledby="successModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" style="max-width: 200px;">
                <div class="modal-content">
                    <div class="modal-body d-flex align-items-center justify-content-center">
                        <i class="bi bi-emoji-smile" style="font-size: 2rem; color: #00c26f;"></i>
                        <p class="mb-0 ms-2" style="font-size: 1rem; color: #00c26f;">注册成功</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import http from "../utils/http.js"; // 导入自定义的http请求工具，用于与后端通信
    import ZhaiHeadPC from "../componentspc/ZhaiHeadPC"; // 导入PC端头部组件
    import ZhaiFootPC from "../componentspc/ZhaiFootPC"; // 导入PC端底部组件
    import { Modal } from 'bootstrap'; // 导入Bootstrap的Modal组件，用于模态框
    import CaptchaComponent from "../componentspc/CaptchaComponent.vue"; // 引入验证码组件

    export default {
        name: "PRegister", // 组件名称
        components: { ZhaiHeadPC, ZhaiFootPC, CaptchaComponent }, // 注册使用的子组件
        data() {
            return {
                myform: {
                    username: "", // 用户名
                    password: "", // 密码
                    password2: "", // 确认密码
                    email: "" // 邮箱
                },
                errors: {}, // 存储各字段的错误状态，错误字段会标记为true
                errorMessage: "", // 错误提示信息
                captchaValid: false // 验证码是否有效，初始值为false
            };
        },
        methods: {
            // 验证验证码是否有效
            validateCaptcha(isValid) {
                this.captchaValid = isValid; // 更新验证码的有效状态
                this.$refs.captchaComponent.captchaError = !isValid; // 如果验证码无效，则显示错误提示
            },
            // 注册方法
            reg() {
                this.errors = {}; // 重置错误状态
                // 验证用户名
                if (!this.myform.username.trim()) {
                    this.showErrorModal("用户名不能为空", 'username'); // 用户名为空时显示错误提示
                    return;
                }
                if (this.myform.username.length < 4 || this.myform.username.length > 20 || this.myform.username.includes(" ")) {
                    this.showErrorModal("用户名必须介于4-10个字符且不能包含空格", 'username'); // 用户名长度或格式不符合要求时显示错误提示
                    return;
                }
                // 验证密码
                if (!this.myform.password.trim()) {
                    this.showErrorModal("密码不能为空", 'password'); // 密码为空时显示错误提示
                    return;
                }
                if (!/^[^\s]{6,15}$/.test(this.myform.password)) {
                    this.showErrorModal("密码应为6-15位字符且不能包含空格", 'password'); // 密码格式不符合要求时显示错误提示
                    return;
                }
                // 验证两次输入的密码是否一致
                if (this.myform.password !== this.myform.password2) {
                    this.showErrorModal("两次密码不一致", 'password2'); // 两次密码不一致时显示错误提示
                    return;
                }
                // 验证邮箱
                if (!this.myform.email.trim()) {
                    this.showErrorModal("邮箱不能为空", 'email'); // 邮箱为空时显示错误提示
                    return;
                }
                if (!this.validateEmail(this.myform.email)) {
                    this.showErrorModal("邮箱格式有误", 'email'); // 邮箱格式不正确时显示错误提示
                    return;
                }
                // 验证验证码是否为空
                if (!this.$refs.captchaComponent.enteredCaptcha.trim()) {
                    this.showErrorModal("验证码不能为空", 'captcha'); // 验证码为空时显示错误提示
                    this.$refs.captchaComponent.captchaError = true; // 显示验证码错误提示
                    return;
                }
                // 验证验证码是否正确
                this.validateCaptcha(this.$refs.captchaComponent.enteredCaptcha.trim() === this.$refs.captchaComponent.captchaText);

                if (!this.captchaValid) {
                    this.showErrorModal("图形验证码输入不正确！", 'captcha'); // 验证码错误时显示提示
                    return;
                }

                // 准备提交数据
                let url = "/platform/frontEnd/register"; // 注册接口地址
                let fm = new FormData();
                fm.append("username", this.myform.username); // 添加用户名到表单数据
                fm.append("password", this.myform.password); // 添加密码到表单数据
                fm.append("password2", this.myform.password2); // 添加确认密码到表单数据
                fm.append("email", this.myform.email); // 添加邮箱到表单数据

                // 发起注册请求
                http.post(url, fm, true).then(data => {
                    if (data.data.status === 1) {
                        this.autoLogin(); // 注册成功后自动登录
                    } else {
                        this.handleFormErrors(data.data); // 处理注册过程中的错误
                    }
                }).catch(error => {
                    console.error("注册请求失败:", error); // 打印错误信息到控制台
                    this.showErrorModal("注册请求失败，请稍后重试。"); // 注册请求失败时显示错误提示
                });
            },
            // 自动登录方法
            autoLogin() {
                let url = "/platform/frontEnd/login"; // 登录接口地址
                let fm = new FormData();
                fm.append("username", this.myform.username); // 使用注册时输入的用户名
                fm.append("password", this.myform.password); // 使用注册时输入的密码

                // 发起登录请求
                http.post(url, fm, { untoken: true }).then(response => {
                    if (response.data.status === 1 && response.data.data) {
                        const data = response.data.data;
                        const userData = {
                            user: {
                                id: data.id,
                                username: data.username,
                                email: data.email,
                                level: data.level,
                                level_expire_date: data.level_expire_date
                            },
                            token: data.token
                        };

                        if (!userData.user || !userData.token) {
                            this.showErrorModal("登录失败，用户或令牌无效"); // 如果用户数据或令牌无效，显示错误提示
                            return;
                        }

                        // 将用户数据存储到Vuex和localStorage中
                        this.$store.dispatch('login', userData); // 将用户数据传递到Vuex状态管理中
                        localStorage.setItem('authToken', userData.token); // 将令牌存储到localStorage
                        localStorage.setItem('user', JSON.stringify(userData.user)); // 将用户信息存储到localStorage
                        this.showSuccessModal(); // 登录成功后显示成功模态框
                    } else {
                        this.showErrorModal(response.data.msg || "登录失败！"); // 登录失败时显示错误提示
                    }
                }).catch(error => {
                    this.showErrorModal("请求失败，请稍后重试。"); // 请求失败时显示错误提示
                });
            },
            // 显示成功模态框
            showSuccessModal() {
                const modalElement = document.getElementById('successModal');
                const modal = new Modal(modalElement);
                modal.show();

                setTimeout(() => {
                    modal.hide();
                    this.$router.push('/memberCenter'); // 跳转到会员中心页面
                }, 3000); // 模态框显示3秒后自动关闭并跳转页面
            },
            // 验证邮箱格式
            validateEmail(email) {
                const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // 正则表达式验证邮箱格式
                return re.test(email);
            },
            // 处理表单错误
            handleFormErrors(data) {
                if (data.field && data.message) {
                    this.showErrorModal(data.message, data.field); // 如果有具体字段的错误信息，则显示
                } else {
                    this.showErrorModal(data.msg || "注册失败！"); // 否则显示通用错误信息
                }
            },
            // 显示错误模态框
            showErrorModal(message, field) {
                this.errorMessage = message; // 设置错误提示信息
                if (field) {
                    this.errors[field] = true; // 标记具体的错误字段
                }
                const modalElement = document.getElementById('errorModal');
                const modal = new Modal(modalElement);
                modal.show();

                // 添加抖动效果
                setTimeout(() => {
                    modalElement.querySelector('.modal-content').classList.add('shake');
                    setTimeout(() => {
                        modalElement.querySelector('.modal-content').classList.remove('shake');
                    }, 1000);
                }, 0);

                setTimeout(() => {
                    modal.hide();
                }, 3000); // 模态框显示3秒后自动关闭
            }
        },
        mounted() {
            this.$refs.captchaComponent.generateCaptcha(); // 组件挂载时生成验证码
        }
    };
</script>

<style scoped>
    .zhai-s {
        padding-top: 20px;
    }
    .br5 {
        border-radius: 1px;
    }
    .zhan {
        height: 30px;
    }
    .btn-success {
        background-color: #00c26f !important;
        font-size: 0.9rem;
        border: none !important;
    }
    .deng {
        max-width: 35%;
        margin: 0 auto;
    }
    .form-control {
        font-size: 0.85rem;
        padding: 0.5rem 0.6rem; /* 增加输入框的行高 */
        border-radius: 4px; /* 调整四个圆角的大小 */
    }
    .zhucce {
        margin-top: 15px;
    }
    @media (min-width: 768px) {
        .zhai-head {
            position: fixed;
            top: 0;
            width: 100%;
            z-index: 1000;
        }
        .container-fluid {
            margin-top: 100px !important;
        }
    }
    @keyframes shake {
        0%, 100% { transform: translateX(0); }
        10%, 30%, 50%, 70%, 90% { transform: translateX(-2px); }
        20%, 40%, 60%, 80% { transform: translateX(2px); }
    }
    .modal-content.shake {
        animation: shake 0.5s;
    }
    .qing {
        font-size: 0.85rem;
        color: red;
    }
    .is-invalid {
        border-color: #dc3545 !important;
        background-image: none !important; /* 移除叹号图标 */
    }
    .modal-body {
        padding: 0px !important; /* 将内边距设置为0px，并使用 !important 覆盖原有样式 */
    }
</style>
